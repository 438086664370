// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  CardHeader, 
  Col, 
  Container, 
  FormGroup,
  Input, 
  Label, 
  Row, 
  Table 
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";

// Components
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";
import { Loading } from "components/Loading";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Helpers
import { formatMoney } from "../../helpers/Functions";

// Services
import { searchClienteService } from '../../services/ClienteService';
import { searchProdutoServicoService } from "../../services/ProdutoServicoService";
import { searchClienteRecorrenciaService } from '../../services/ClienteRecorrenciaService';

class ClienteRecorrenciaList extends Component {
  constructor(props) {
    super(props)
    this.state = {
            
      ftr_cliente: '',
      ftr_produtoServico: '',
      ftr_dia_vencimento: '',

      clientes: [], // armazena pesquisa do select2 
      produtoServicos: [] ,// armazena pesquisa do select2 

      clienteRecorrencias: [], // armazena a consulta
      consultaRealizada: false,

      // Select2 selecionado
      selectCliente: '',
      isLoading: false,

      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }

  // Executa após carregar a tela
  componentDidMount() {
    this.listProdutoServicos();
  }

  // Busca a listagem de produto / serviço
  listProdutoServicos = async () => {
    const response = await searchProdutoServicoService();
    if(response.errors.length == 0)
      this.setState({produtoServicos: response.produtoServicos});
    else
      this.setState({produtoServicos: []});
  }

  // Faz a consulta do cliente pela descricao
  searchClientes = async (ftr_cliente) => {
    const clientes = await searchClienteService({ftr_nome: ftr_cliente});
    return clientes;
  }

  // Faz o filtro da consulta no select 2 do clinte
  handleSelectCliente = (ftr_cliente) => {
    if(ftr_cliente.length > 2) {

      this.searchClientes(ftr_cliente).then(response => {
        const clientes = response.clientes.map(cliente => {
          return {
            label: `${cliente.nome}`, value: cliente.id 
          }
        })
        this.setState({clientes})
      })
    }
  }

  // Altera o estados das variáveis para o select do cliente
  handleChangeCliente = (event) => {
    if(event != null) {
      this.setState({
        ftr_cliente: event.value,
        selectCliente: event // Mostra o select pressionado
      });
    } else {
      this.setState({
        ftr_cliente: null,
        selectCliente: event
      });
    }
  }
  
  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    let field = event.target.name;
    this.setState({[field]: event.target.value});
  }
   
  // Faz o submit no form 
  handleSubmit = async (event) => {     
    this.setState({isLoading: true});

    // Variáveis
      const {
        ftr_cliente,
        ftr_produtoServico,
        ftr_dia_vencimento
      } = this.state;
    //---

    let errors = [];
    const searchParams = {
      ftr_cliente, 
      ftr_produtoServico, 
      ftr_dia_vencimento
    }

    const response = await searchClienteRecorrenciaService(searchParams);

    // Pega o erro que vem da consulta da API
    if(response.errors.length == 0) {
      this.setState({
        clienteRecorrencias: response.clienteRecorrencias,
        consultaRealizada: true,
        isLoading: false
      });
    } else {
      response.errors.forEach(msg => {
        errors.push(msg.message)
      });
      this.setState({
        msgBox: {color: 'danger', display: true, msg: errors },
        isLoading: false
      }) 
    }
  
    event.preventDefault();
  }
  
  render() {
    // Variáveis
      const {
        clientes,
        clienteRecorrencias, 
        consultaRealizada, 
        isLoading,
        ftr_produtoServico,
        msgBox,
        produtoServicos,
        selectCliente
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <Loading isShow={isLoading} />
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cliente Recorrência" breadcrumbItem="Cliente Recorrência" />
            <Row>
              <Col xl={12}>
                <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardHeader style={{backgroundColor: "#fff"}}>
                    <LinkPattern 
                      to={`/clienteRecorrencia/create`} 
                      className="btn btn-primary" 
                      style={{float: 'right'}}
                      action="cadastra"
                    >
                      Cadastrar
                    </LinkPattern>
                  </CardHeader>
                  <CardBody>
                    <h4 className="card-title">Filtros de clientes recorrência</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div className="mb-3 select2-container">
                            <Label>Clientes</Label>
                            <Select
                              placeholder="Mínimo 3 caracteres para consulta"
                              classNamePrefix="select2-selection"
                              onInputChange={this.handleSelectCliente}
                              onChange={this.handleChangeCliente}
                              options={clientes}
                              value={selectCliente}
                              isClearable={true}
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_produtoServico">Produto / Serviço</Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="ftr_produtoServico" 
                              id="ftr_produtoServico" 
                              onChange={this.handleChange}
                              value={ftr_produtoServico}
                            >
                              <option value="">--- Selecione ---</option>
                              {produtoServicos.map(produtoServico => {
                                return <option value={produtoServico.id} key={produtoServico.id}>{produtoServico.nome}</option>
                              })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_dia_vencimento">Dia vencimento</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="ftr_dia_vencimento"
                              name="ftr_dia_vencimento"
                              maxLength={2}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <ButtonPattern 
                            className="btn btn-primary"
                            type="submit" 
                            style={{marginTop: "15px"}}
                            action="pesquisa"
                          >
                            Pesquisar
                          </ButtonPattern>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row> 

            <Row style={consultaRealizada ? {display: "block"} : {display: "none"}}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Listagem de clientes </h4>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Cliente</th>
                            <th>Produto/Serviço</th>
                            <th style={{textAlign: 'center'}}>Dia vencimento</th>
                            <th style={{textAlign: 'right'}}>Valor mensalidade</th>
                            <th style={{textAlign: 'right'}}>Valor transação</th>
                            <th>Processa</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (clienteRecorrencias.length > 0 ?
                              clienteRecorrencias.map(clienteRecorrencia => {
                                return (
                                  <tr key={clienteRecorrencia.id}>
                                    <th scope="row">
                                      <LinkPattern 
                                        to={`/clienteRecorrencia/${clienteRecorrencia.id}/edit`} 
                                        style={{fontSize: "20px"}}
                                        action="atualiza"
                                      >
                                        <i className="bx bx-edit" />
                                      </LinkPattern>
                                    </th>
                                    <td>{clienteRecorrencia.cliente.nome}</td>
                                    <td>{clienteRecorrencia.produtoServico.nome}</td>
                                    <td style={{textAlign: 'center'}}>{clienteRecorrencia.dia_vencimento}</td>
                                    <td style={{textAlign: 'right'}}>R$ {formatMoney(clienteRecorrencia.valor_mensalidade)}</td>
                                    <td style={{textAlign: 'right'}}>R$ {formatMoney(clienteRecorrencia.valor_transacao)}</td>
                                    <td>
                                      <span className={`font-size-12 badge rounded-pill ${clienteRecorrencia.transacao_processa ? 'bg-success': 'bg-danger'}`}>
                                        {clienteRecorrencia.transacao_processa ? 'SIM': 'NÃO'}
                                      </span>
                                      </td>
                                  </tr>
                                )
                              })
                            :
                              <tr style={{textAlign: "center"}}>
                                <td colSpan={4}>Nenhum registro encontrado!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteRecorrenciaList;
