import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os Municipios
const searchMunicipioService = async (ftr_municipio) => {
  const _token = accessToken();

  let params = '';
  
  // Monta os parâmetros de pesquisa
    if(ftr_municipio)
      params += `municipio=${ftr_municipio}&`;
  //---

  const municipioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/municipio?${params}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  });

  return municipioResponse;
}

// Função Responsável por consultar os Municipios pelo ID
const searchMunicipioByIDService = async (municipioID) => {
  const _token = accessToken();
  const municipioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/municipio/${municipioID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  });

  return municipioResponse;
  
}

export {
  searchMunicipioService,
  searchMunicipioByIDService
}