// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  Col, 
  Container,
  FormGroup, 
  Input, 
  Label, 
  Row
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";

// Components
import { ButtonPattern } from "components/ButtonPattern";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// helpers
import { 
  formatCnpj,
  formatPhone,
  formatZipCode
} from "../../helpers/Functions";

// Services
import { searchCep } from "../../services/CepService";
import { 
  searchMunicipioService, 
  searchMunicipioByIDService 
} from "../../services/MunicipioService";
import { 
  getEmpresaByUsuarioLogadoService, 
  updateEmpresaService 
} from "../../services/EmpresaService";

class ClienteList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      empresaID: '',
      cnpj: '',
      razao_social: '',
      nome_fantasia: '',
      ativa: 'A',
      fone1: '',
      fone2: '',
      email: '',
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      complemento: '',

      // Carregamento de Select
      municipios: [],

      // Select2 selecionado
      selectMunicipio: '',

      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    const response = await getEmpresaByUsuarioLogadoService();

    if(response.errors.length == 0) {
      const { 
          empresa, 
          empresa: { 
            municipio 
          }
        } = response;

      this.setState({
        empresaID: empresa.id || '',
        cnpj: formatCnpj(empresa.cnpj) || '',
        razao_social: empresa.razao_social || '',
        nome_fantasia: empresa.nome_fantasia || '',
        ativa: empresa.ativa ? 'A' : 'I',
        fone1: formatPhone(empresa.fone1) || '',
        fone2: formatPhone(empresa.fone2) || '',
        email: empresa.email || '',
        cep: formatZipCode(empresa.cep) || '',
        logradouro: empresa.logradouro || '',
        numero: empresa.numero || '',
        bairro: empresa.bairro || '',
        complemento: empresa.complemento || '',
        selectMunicipio: {
          label: `${municipio.id} - ${municipio.municipio}/${municipio.uf}`, 
          value: municipio.id 
        }
      });
    } else {
      let errors = [];
      response.errors.forEach(msg => {
        errors.push(msg.message)
      });

      this.setState({
        msgBox: {
          color: 'danger',
          display: true,
          msg: errors
        }
      });
    }
  }

  // Faz a consulta do município pelo ID
  searchMunicipioByID = async (municipioID) => {
    const municipio = await searchMunicipioByIDService(municipioID);
    return municipio;
  }

  // Faz a consulta do município pela descricao
  searchMunicipios = async (ftr_municipio) => {
    const municipios = await searchMunicipioService(ftr_municipio);
    return municipios;
  }

  // Faz a consulta do município pela descricao
  searchMunicipios = async (ftr_municipio) => {
    const municipios = await searchMunicipioService(ftr_municipio);
    return municipios;
  }
  
  // Preenche o select 2
  fillSelectMunicipio = async (municipioID) => {
    const response = await this.searchMunicipioByID(municipioID);
    
    if(response.errors.length == 0) {
      const municipio = response.municipio;
      const selectMunicipio = {
        label: `${municipio.id} - ${municipio.municipio}/${municipio.uf}`, 
        value: municipio.id 
      }
      this.setState({selectMunicipio});
    }
  }

  // Faz o filtro da consulta no select 2 do município
  handleSelectMunicipio = (ftr_municipio) => {
    if(ftr_municipio.length > 2) {

      this.searchMunicipios(ftr_municipio).then(response => {
        const municipios = response.municipios.map(municipio => {
          return {
            label: `${municipio.id} - ${municipio.municipio}/${municipio.uf}`, value: municipio.id 
          }
        })
        this.setState({municipios})
      })
    }
  }

  // Altera o estados das variáveis para o select do município
  handleChangeMunicipio = (event) => {
    this.setState({
      end_municipio: event.value,
      selectMunicipio: event // Mostra o select pressionado
    });
  }

  // Busca o CEP
  searchCep = async (event) => {
    const cep = event.target.value.replace(/\D/gim, '');
    
    if(cep.length < 8) {
      this.setState({
        end_cep: '',
        msgBox: {color: 'danger', display: true, msg: ['Cep não inválido'] }
      });
      return true;
    }

    const endereco = await searchCep(cep);
    
    if(endereco.retorno == 0)
      this.setState({
        cep: '',
        msgBox: {color: 'danger', display: true, msg: ['Cep não encontrado']}
      })
    else {
      this.setState({
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        municipio: endereco.localidade_codsiafi
      });

      this.fillSelectMunicipio(endereco.localidade_codsiafi); // chama para buscar o municipio pelo ID e atualziar o select 2
    }
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    let field = event.target.name

    switch (field) {
      case 'cnpj':
        this.setState({[field]: formatCnpj(event.target.value)})
        break;
      case 'fone1':
      case 'fone2':
        this.setState({[field]: formatPhone(event.target.value)})
        break;
      case 'cep':
        this.setState({[field]: formatZipCode(event.target.value)})
        break;
      default:
        this.setState({[field]: event.target.value})
        break;
    }
  }

  // Faz o submit no form 
  handleSubmit = async (event) => { 

    // variables
      const { 
        ativa,
        bairro,
        cep,
        complemento,
        cnpj,
        email,
        empresaID,
        fone1,
        fone2,
        logradouro,
        nome_fantasia,
        numero,
        razao_social,
        selectMunicipio
      } = this.state;
      let errors = [];
      let validationsError = [];
      let response;
    //---

    // validations
      if(!cnpj) {
        validationsError.push('CNPJ é de preenchimento Obrigatório')
      }

      if(!razao_social) {
        validationsError.push('Razão Social é de preenchimento Obrigatório')
      }

      if(!nome_fantasia) {
        validationsError.push('Nome Fansasia é de preenchimento Obrigatório')
      }

      if(!fone1) {
        validationsError.push('Telefone 1 é de preenchimento Obrigatório')
      }

      if(!ativa) {
        validationsError.push('Status é de preenchimento Obrigatório')
      }

      if(!email) {
        validationsError.push('E-mail é de preenchimento Obrigatório')
      }

      if(!cep) {
        validationsError.push('CEP é de preenchimento Obrigatório')
      }

      if(!logradouro) {
        validationsError.push('Logradouro é de preenchimento Obrigatório')
      }

      if(!numero) {
        validationsError.push('Número é de preenchimento Obrigatório')
      }

      if(!bairro) {
        validationsError.push('Bairro é de preenchimento Obrigatório')
      }
      
      if(!selectMunicipio.value) {
        validationsError.push('Município é de preenchimento Obrigatório')
      }
    //---

    if(validationsError.length > 0) {
      this.setState({msgBox: {color: 'danger', display: true, msg: validationsError }});
      event.preventDefault();
    } else {

      const empresaParams = {
        'cnpj': cnpj.replace(/\D/gim, ''),
        razao_social,
        nome_fantasia,
        'ativa': ativa == 'A' ? true : false,
        'fone1': fone1.replace(/\D/gim, ''),
        'fone2': fone2.replace(/\D/gim, ''),
        email,
        'cep': cep.replace(/\D/gim, ''),
        logradouro,
        numero,
        bairro,
        complemento,
        'municipio_id': selectMunicipio.value,
      };
   
      response = await updateEmpresaService(empresaID, empresaParams);

      // Pega o erro que vem da consulta da API
      if(response.errors.length == 0) {
        this.setState({msgBox: {color: 'success', display: true, msg: ['Dados da empresa atualizados com sucesso'] }})  
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }}) 
      }
    }
    
    event.preventDefault();
  }
  
  render() {
    // variables
      const { 
        ativa,
        bairro,
        cep,
        complemento,
        cnpj,
        email,
        fone1,
        fone2,
        logradouro,
        msgBox,
        municipios,
        nome_fantasia,
        numero,
        razao_social,
        selectMunicipio
      } = this.state;
    //---
   
    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Empresa" breadcrumbItem="Empresa" />
            <Row>
              <Col xl={12}>
                <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Dados da empresa</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="cnpj">CNPJ<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="cnpj"
                              name="cnpj"
                              onChange={this.handleChange}
                              value={cnpj}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="razao_social">Razão social<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="razao_social"
                              name="razao_social"
                              onChange={this.handleChange}
                              value={razao_social}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="nome_fantasia">Nome fantasia<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="nome_fantasia"
                              name="nome_fantasia"
                              onChange={this.handleChange}
                              value={nome_fantasia}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{paddingTop: '15px'}}>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="fone1">Telefone 1<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="fone1"
                              name="fone1"
                              onChange={this.handleChange}
                              value={fone1}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="fone2">Telefone 2</Label>
                            <Input
                              type="text"
                              className="form-control celPhone"
                              id="fone2"
                              name="fone2"
                              onChange={this.handleChange}
                              value={fone2}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ativa">Status <span className="text-danger" >*</span></Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="ativa" 
                              id="ativa" 
                              onChange={this.handleChange}
                              value={ativa}
                            >
                              <option value="A">Ativo</option>
                              <option value="I">Inativo</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    
                      <Row style={{paddingTop: '15px'}}>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="cep">Cep<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control cep"
                              id="cep"
                              name="cep"
                              onChange={this.handleChange}
                              onBlur={this.searchCep}
                              value={formatZipCode(cep)}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormGroup>
                            <Label htmlFor="logradouro">Logradouro<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="logradouro"
                              name="logradouro"
                              onChange={this.handleChange}
                              value={logradouro}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="numero">Número<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="numero"
                              name="numero"
                              onChange={this.handleChange}
                              value={numero}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                        
                      <Row style={{paddingTop: '15px'}}>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="bairro">Bairro<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="bairro"
                              name="bairro"
                              onChange={this.handleChange}
                              value={bairro}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="complemento">Complemento</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="complemento"
                              name="complemento"
                              onChange={this.handleChange}
                              value={complemento}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div className="mb-3 select2-container">
                            <Label>Município<span className="text-danger" >*</span></Label>
                            <Select
                              placeholder="Mínimo 3 caracteres para consulta"
                              classNamePrefix="select2-selection"
                              onInputChange={this.handleSelectMunicipio}
                              onChange={this.handleChangeMunicipio}
                              options={municipios}
                              value={selectMunicipio}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>                    
                        <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                          <ButtonPattern 
                            type="submit" 
                            style={{ marginRight: "10px" }}
                            className={'btn btn-success'}
                            action="atualiza"
                          >
                            Atualizar
                          </ButtonPattern>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteList;
