import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os parceiros
const searchParceiroService = async () => {
  const _token = accessToken();
  const parceiroResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/parceiro`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  

  return parceiroResponse;
}

export {
  searchParceiroService
}