// Recupera o token do localStorage
export default function routePermissionAccess(path) {
  const permission = ( JSON.parse(localStorage.getItem("userPermission")) != null ? JSON.parse(localStorage.getItem("userPermission")) : null);
  const [, pathName] = path.split("/");
  let routePermission = [];

  // Buscar permissões na rota acessada
  for (let i in permission) {
    if (permission[i].path == pathName) {
      routePermission.push(permission[i]);
    }
  }

  // Limpa o storage e cria as novas permissões da rota atual
  localStorage.removeItem("userRoutePermission");
  localStorage.setItem("userRoutePermission", JSON.stringify(routePermission));

  return routePermission;
}

