import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os usuários
const searchUsuarioService = async () => {
  const _token = accessToken();
  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/usuario`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return usuarioResponse;
}

// Função Responsável por consultar os usuários
const getUsuarioByIDService = async (usuarioID) => {
  const _token = accessToken();
  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/usuario/${usuarioID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return usuarioResponse;
}

// Função Responsável cadastrar novos usuários
const createUsuarioService = async (usuario) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (usuario) deve ser um objeto
   * Ex:
   * 	{
        "grupo_id": 1,
        "nome": "Joao dos testes",
        "email": "joao@tdex.com.br",
        "senha": "Tdex@dev1",
        "confirma_senha": "Tdex@dev1",
        "ativo": true
      }
   */
  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/usuario`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(usuario),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  });

  return usuarioResponse;
}

// Função Responsável atualizar os usuários
const updateUsuarioService = async (usuario, usuarioID) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (usuario) deve ser um objeto
   * Ex:
   * 	{
  	    "grupo_id": 2,
        "nome": "Caio Costa",
        "email": "caio@tdex.com.br",
        "ativo": true
      }
   */
  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/usuario/${usuarioID}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(usuario),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  return usuarioResponse;
}

// Função Responsável deletar os usuários
const deleteUsuarioService = async (usuarioID) => {
  const _token = accessToken();
  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/usuario/${usuarioID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      })
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })

  return usuarioResponse;
}

// Funçaõ Responsável por atualizar a senha do usuário
const updateSenhaUsuarioService = async (usuario) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (usuario) deve ser um objeto
   * Ex:
   * 	{
	      "senha_atual": "Tdex@dev1",
	      "nova_senha": "Tdex@dev1",
	      "confirma_senha": "Tdex@dev1"
      }
   */

  const usuarioResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/senha`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(usuario),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  return usuarioResponse;
}

export {
  searchUsuarioService,
  getUsuarioByIDService,
  createUsuarioService,
  updateUsuarioService,
  updateSenhaUsuarioService,
  deleteUsuarioService
};