import accessToken from '../helpers/JWT/accessToken';

/* types interface fron request
  interface ISearchCliente {
    ftr_nome: string,
    ftr_cpfCnpj: string, 
    ftr_tipo: string, 
    ftr_status: string
  }
*/

/**
 * 
 * @param {} ISearchCliente 
 * @returns 
 */
const searchClienteService = async ({ftr_nome, ftr_cpfCnpj, ftr_status}) => {
  const _token = accessToken();

  let params = '';

  // Monta os parâmetros de pesquisa
    if(ftr_nome)
      params += `nome=${ftr_nome}&`;

    if(ftr_cpfCnpj)
      params += `cpf_cnpj=${ftr_cpfCnpj}&`;

    if(ftr_status) {
      if(ftr_status != 'T')
        params += `ativo=${ftr_status == 'A' ? 'true': 'false'}&`;
    }
  //---

  const clienteResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente?${params}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })


  return clienteResponse;
}

// Função Responsável por cadastrar o cliente
const createClienteService = async ({nome, cpf_cnpj, razao_social, parceiro_id, ativo, cliente_tipo_id}) => {
  const _token = accessToken();

  // Dados cliente 
  const cliente = {
    nome,
    cpf_cnpj,
    razao_social,
    ativo,
    parceiro_id,
    cliente_tipo_id,
  }

  // Faz o cadastro do cliente
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(cliente),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

// Função para buscar o cliente pelo ID
const getClienteByIDService = async (clienteID) => {
  const _token = accessToken();
  const clienteResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return clienteResponse;
}

// Função para atualizar o cliente
const updateClienteService = async (clienteID, {nome, cpf_cnpj, razao_social, parceiro_id, ativo, cliente_tipo_id}) => {
  const _token = accessToken();

  // Dados cliente 
  const cliente = {
    nome,
    cpf_cnpj,
    razao_social,
    ativo,
    parceiro_id,
    cliente_tipo_id,
  };

  // Faz o cadastro do cliente
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(cliente),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}


export {
  searchClienteService,
  createClienteService,
  getClienteByIDService,
  updateClienteService
}