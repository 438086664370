import accessToken from '../helpers/JWT/accessToken';

/* types interface fron request
  interface ISearchContasReceber {
   ftr_cliente: number,
   ftr_produtoServico: number,
   ftr_banco: number,
   ftr_numeroNFE: string, 
   ftr_dataVencimento: string
  }
*/

/**
 * 
 * @param {} ISearchContasReceber 
 * @returns 
 */
const searchContasReceberService = async ({ftr_cliente, ftr_produtoServico, ftr_banco, ftr_numeroNFE, ftr_dataVencimento}) => {
  const _token = accessToken();

  let params = '';

  // Monta os parâmetros de pesquisa
    if(ftr_cliente)
      params += `cliente_id=${ftr_cliente}&`;

    if(ftr_produtoServico)
      params += `produto_servico_id=${ftr_produtoServico}&`;

    if(ftr_banco)
      params += `banco_id=${ftr_banco}&`;

    if(ftr_numeroNFE)
      params += `nfe_numero=${ftr_numeroNFE}&`;

    if(ftr_dataVencimento)
      params += `data_vencimento=${ftr_dataVencimento}&`;
  //---

  const contasReceberResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/contaReceber?${params}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return contasReceberResponse;
}

export {
  searchContasReceberService,
}