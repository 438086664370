import accessToken from '../helpers/JWT/accessToken';

/* types interface fron request
  interface IUpdateEmpresa {
     cnpj: string,
    razao_social: string,
    nome_fantasia: string,
    ativa: string,
    fone1: string,
    fone2: string,
    email: string,
    cep: string,
    logradouro: string,
    numero: string,
    bairro: string,
    complemento: string,
    municipio_id: number
  }
*/


// Função Responsável por consultar a empresa do usuário logado
const getEmpresaByUsuarioLogadoService = async () => {
  const _token = accessToken();
  const empresaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/empresa/usuario-logado`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return empresaResponse;
}

/**
 * 
 * @param empresaID: number
 * @param empresa: IUpdateEmpresa 
 * @returns 
 */
const updateEmpresaService = async (empresaID, empresa) => {
  const _token = accessToken();

  // Faz o cadastro do cliente
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/empresa/${empresaID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(empresa),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}


export {
  getEmpresaByUsuarioLogadoService,
  updateEmpresaService
};