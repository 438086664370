/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * locale => localidade. ('pr-BR', 'en-US')
 * currencySign => retorna valor com cifra da moeda. (R$ 120,00)
 * @by Caio Costa 09/11/2021
 */
 const formatMoney = (value, locale = 'pt-BR', currencySign = false) => {
  const currentValue = value.replace(/\D/gim, '');
  let options = {};
  let currency = '';
  let style = 'currency';

  // Get currency with locale
  switch (locale) {
    case 'en-US':
      currency = 'USD';
      break;
    default:
      currency = 'BRL';
      break;
  }

  // Verify with currencySign
  if(!currencySign)
    options = {minimumFractionDigits: 2};
  else
    options = {style, currency};
      
  if(currentValue.length >= 3){
    const position = parseInt(currentValue.length) - 2;
    const formattedValue = `${currentValue.substr(0,position)}.${currentValue.substr(-2)}`;
    return parseFloat(formattedValue).toLocaleString(locale, options);
  } else {
    return currentValue;
  }
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 16/12/2022
 */
 const formatCpfCnpj = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');


  if([0, 1, 2, 3].includes(currentValue.length))
    return currentValue;
  else if([4, 5, 6].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}`;
  else if([7, 8, 9].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}.${currentValue.substring(6, 9)}`;
  else if([10, 11].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}.${currentValue.substring(6, 9)}-${currentValue.substring(9, 11)}`;
  else if([12].includes(currentValue.length))
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}/${currentValue.substring(8, 12)}`;
  else if([13, 14].includes(currentValue.length))
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}/${currentValue.substring(8, 12)}-${currentValue.substring(12, 14)}`;
  else
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}/${currentValue.substring(8, 12)}-${currentValue.substring(12, 14)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
 const formatCpf = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0, 1, 2, 3].includes(currentValue.length))
    return currentValue;
  else if([4, 5, 6].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}`;
  else if([7, 8, 9].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}.${currentValue.substring(6, 9)}`;
  else if([10, 11].includes(currentValue.length))
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}.${currentValue.substring(6, 9)}-${currentValue.substring(9, 11)}`;
  else
    return `${currentValue.substring(0, 3)}.${currentValue.substring(3, 6)}.${currentValue.substring(6, 9)}-${currentValue.substring(9, 11)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
 const formatCnpj = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0, 1, 2].includes(currentValue.length))
    return currentValue;
  else if([3, 4, 5].includes(currentValue.length))
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}`;
  else if([6, 7, 8].includes(currentValue.length))
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}`;
  else if([9, 10, 11, 12].includes(currentValue.length))
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}/${currentValue.substring(8, 12)}`;
  else
    return `${currentValue.substring(0, 2)}.${currentValue.substring(2, 5)}.${currentValue.substring(5, 8)}/${currentValue.substring(8, 12)}-${currentValue.substring(12, 14)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
 const formatPhone = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0].includes(currentValue.length))
    return currentValue;
  else if([1, 2].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}`;
  else if([3, 4, 5, 6].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 6)}`;
  else if([7, 8, 9, 10].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 6)}-${currentValue.substring(6, 10)}`;
  else
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 7)}-${currentValue.substring(7, 11)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
const formatCell = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0].includes(currentValue.length))
    return currentValue;
  else if([1, 2].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}`;
  else if([3, 4, 5, 6, 7].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 7)}`;
  else
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 7)}-${currentValue.substring(7, 11)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
 const formatTelephone = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0].includes(currentValue.length))
    return currentValue;
  else if([1, 2].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}`;
  else if([3, 4, 5, 6].includes(currentValue.length))
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 6)}`;
  else
    return `(${currentValue.substring(0, 2)}) ${currentValue.substring(2, 6)}-${currentValue.substring(6, 10)}`;
}

/**
 * Devolve o valor formatado
 * value => valor que deseja formatar.
 * @by Caio Costa 17/02/2022
 */
 const formatZipCode = (value = '') => {
  const currentValue = value.replace(/\D/gim, '');

  if([0, 1, 2, 3, 4, 5].includes(currentValue.length))
    return currentValue;
  else
    return `${currentValue.substring(0, 5)}-${currentValue.substring(5, 8)}`;
}

/**
 * Devolve um boolean(true / false) validando o valor informado
 * value => valor que deseja validar.
 * @by Caio Costa 21/02/2022
 */
const validateCpfCnpj = (value = '') => {
  const cpfCnpj = value.replace(/\D/gim, '');

  if(cpfCnpj.length == 11) {
    if(cpfCnpj == "00000000000" || cpfCnpj == "11111111111" || cpfCnpj == "22222222222" || cpfCnpj == "33333333333" || cpfCnpj == "44444444444" || 
        cpfCnpj == "55555555555" || cpfCnpj == "66666666666" || cpfCnpj == "77777777777" || cpfCnpj == "88888888888" || cpfCnpj == "99999999999" )
        return false;

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++) 
        sum = sum + parseInt(cpfCnpj.substring(i-1, i)) * (11 - i)

    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11))  
      rest = 0
    
    if (rest != parseInt(cpfCnpj.substring(9, 10)) ) 
      return false;

    sum = 0
    
    for (var i = 1; i <= 10; i++) 
      sum = sum + parseInt(cpfCnpj.substring(i-1, i)) * (12 - i)

    rest = (sum * 10) % 11
    if ((rest == 10) || (rest == 11))  
      rest = 0
    
    if (rest != parseInt(cpfCnpj.substring(10, 11) ) ) 
      return false;

    return true;
  }
  else if(cpfCnpj.length == 14){
    if (cpfCnpj == "00000000000000" || cpfCnpj == "11111111111111" || cpfCnpj == "22222222222222" || cpfCnpj == "33333333333333" || cpfCnpj == "44444444444444" || 
        cpfCnpj == "55555555555555" || cpfCnpj == "66666666666666" || cpfCnpj == "77777777777777" || cpfCnpj == "88888888888888" || cpfCnpj == "99999999999999")
      return false;

    let size = cpfCnpj.length - 2;
    let numbers = cpfCnpj.substring(0,size);
    let digits = cpfCnpj.substring(size);
    let sum = 0;
    let pos = size - 7

    for (var i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      
      if (pos < 2) 
        pos = 9
    }

    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;

    if (result != digits.charAt(0)) 
      return false;

    size = size + 1;
    numbers = cpfCnpj.substring(0,size);
    sum = 0;
    pos = size - 7;

    for (var i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;

      if (pos < 2) 
        pos = 9
    }
    
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    
    if (result != digits.charAt(1)) 
      return false

    return true;
  }
  else {
    return false;
  }
}

/**
 * Devolve um boolean(true / false) validando o valor informado
 * value => valor que deseja validar.
 * regex => expressão regular que deseja utilizar
 * @by Caio Costa 21/02/2022
 */
const validateRegex = (value = '', regex = '') => {
  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
}

export {
  formatMoney,
  formatCpfCnpj,
  formatCpf,
  formatCnpj,
  formatPhone,
  formatCell,
  formatTelephone,
  formatZipCode,
  validateCpfCnpj,
  validateRegex
}