// Services
import { listPermissoesService } from "../../services/GrupoPermissoesService";

// Busca as permissoes para um novo grupo
async function listPemissoesGrupo() {
  const response = await listPermissoesService();
  
  if(response.errors.length == 0)
    return response.permissoes;
  else
    return [];
}

// Função recursiva para ordenação/classificação doe principal/pai/níveis
const buildTree = async (indice = null, permissoes = [], arrNew = [], arrFilhoDoFilho = []) => {
  if (typeof permissoes[indice] == 'undefined') {
    return arrNew;
  }

  // Verifica se a permissão é pai
  if (!permissoes[indice].menu_pai) {
    arrNew.push({
      ...permissoes[indice],
      pai: true,
      nivel: 0,
      principal: true
    });
  } else {
  
    // Procura o menu pai e insere logo após
    for (let i in arrNew) {
      // Verifica se o menu tem um pai
      if (permissoes[indice].menu_pai == arrNew[i].menu_id) {
        let posicao = null;

        // Atualizao menu para pai
        arrNew[i].pai = true;
        
        // Verificar se o pai tem mais algum filho
        for (let j in arrNew) {
          // Se o pai é o mesmo / posição do proximo filho
          if (arrNew[j].menu_pai == permissoes[indice].menu_pai && permissoes[indice].ordem_filho > arrNew[j].ordem_filho) {
          // if (arrNew[j].menu_id == permissoes[indice].menu_pai) {
            posicao = parseInt(j)+1;
          }
        }

        // Se não encontrar a posição é porque ainda não tem nenhum filho
        if (!posicao) {
          posicao = parseInt(i)+1;
        }
      
        // primeiro parâmetro é a posição / segundo a quantidade que será retirado / terceira o que será inserido
        arrNew.splice(posicao, 0, {
          ...permissoes[indice],
          pai: false,
          nivel: parseInt(arrNew[i].nivel)+1,
          principal: false
        });
      } 

      // Verifica se tem algum registro arrFilhoDoFilho/ se houver, procurar no arrNew se já tem pai para algum deles e incrementar
      else if(arrFilhoDoFilho.length > 0) {
        // console.log(arrFilhoDoFilho);
        let posicao = null;

        // Verificar se esse filho já tem o pai adicionado 
        for (let i in arrFilhoDoFilho) {

          for (let j in arrNew) {

            // Encontrou o array pai
            if(arrFilhoDoFilho[i].menu_pai == arrNew[j].menu_id) {

              // Atualizao menu para pai
              arrNew[j].pai = true;

              // Verifica a posição do ultimo filho desse pai e insere posteriormente
              for(let x in arrNew) {
                if (arrNew[x].menu_pai == arrFilhoDoFilho[i].menu_pai && arrFilhoDoFilho[i].ordem_filho > arrNew[x].ordem_filho)
                  posicao = parseInt(x)+1;
              }

              // Se não encontrar a posição é porque ainda não tem nenhum filho
              if (!posicao) {
                posicao = parseInt(j)+1;
              }

              // primeiro parâmetro é a posição / segundo a quantidade que será retirado / terceira o que será inserido
              arrNew.splice(posicao, 0, {
                ...arrFilhoDoFilho[i],
                pai: false,
                nivel: parseInt(arrNew[j].nivel)+1,
                principal: false
              });

              // Exclui o filho adicionado do array de filhos
              arrFilhoDoFilho.splice(i, 1);
            }  
          }
        }
      }
    }

    // Verifica se o menu autal foi adicionado ao arrNew / se não foi adicionar no arrFihoDoFilho
    let achouMenu = false;
    for (let i in arrNew) {
      if(permissoes[indice].menu_id == arrNew[i].menu_id)
        achouMenu = true;
    }

    if(!achouMenu) {
      arrFilhoDoFilho.push(permissoes[indice]);
    }

  }

  // Chama a função novamente
  return await buildTree(indice + 1, permissoes, arrNew, arrFilhoDoFilho);
}

// Exporta as permissões já ordenadas e com os níveis de permissões
export default async function accessUserPermission() {
  const fullPermission = await listPemissoesGrupo();
  
  // Ordena as permissões
  if(fullPermission.length > 0) {
    const buildTreeFormated = await buildTree(0, fullPermission); 
    localStorage.removeItem("userPermission");
    localStorage.setItem("userPermission", JSON.stringify(buildTreeFormated));
    return true;
  }

  return false;
}