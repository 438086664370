// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Col, 
  Card, 
  CardTitle, 
  CardBody, 
  Container, 
  CardHeader, 
  FormGroup,
  Input,
  Label ,
  Modal, 
  Row
} from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

// Components
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, 
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "../../assets/scss/datatables.scss";

// helpers
import { formatMoney } from "../../helpers/Functions";

// Services
import { 
  searchProdutoServicoService,
  getProdutoServicoByIDService,
  createProdutoServicoService, 
  updateProdutoServicoService,
  deleteProdutoServicoService
} from "../../services/ProdutoServicoService";

class ProdutoServico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dados datatable
      page: 1,
      sizePerPage: 10,
      productData: [],

      // Dados produto
      produtoServicoID: '',
      nome: '',
      descricao: '',
      valor: '',
      gerarNfAntesPagamento: false,


      // Controle modal
      modalCreateUpdate: false,
      titleModal: '',
      submitType: '',

      // Controle de tela
      msgBox: {
        color: 'success',
        display: false,
        msg: []
      },
      msgBoxModal: {
        color: 'success',
        display: false,
        msg: []
      },
    }
  }

  // Executa após carregar a tela
  componentDidMount() {
    this.listProdutoServicos();
  }

  // Busca a listagem de produto / serviço
  listProdutoServicos = async () => {
    const response = await searchProdutoServicoService();
    if(response.errors.length == 0)
      this.setState({productData: response.produtoServicos});
    else
      this.setState({productData: []});
  }

  // Abre e fecha o modal create / update
  togModalCreateUpdate = async (event) => {
    const btn = event.target.getAttribute("name");
    let errors = [];

    if(btn == 'btn-create' || btn == 'btn-close') {
      this.setState(prevState => ({
        modalCreateUpdate: !prevState.modalCreateUpdate,
        produtoServicoID: '',
        nome: '',
        descricao: '',
        valor: '',
        titleModal: 'Cadastrar Produto/Serviço',
        msgBox: {color: 'success', display: false, msg: []},
        msgBoxModal: {color: 'success', display: false, msg: []}
      }));
      this.removeBodyCss();

    } else if(btn == 'btn-edit'){
      const produtoServicoID = event.target.getAttribute("data-id");
      const response = await getProdutoServicoByIDService(produtoServicoID);

      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          produtoServicoID: produtoServicoID,
          nome: response.produtoServico.nome,
          descricao: response.produtoServico.descricao,
          valor: formatMoney(response.produtoServico.valor_padrao),
          gerarNfAntesPagamento: response.produtoServico.gerar_nf_antes_pagamento,
          modalCreateUpdate: true,
          titleModal: 'Atualizar/Excluir Produto/Serviço',
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        });
        this.removeBodyCss();
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }}) 
      }
    }
  }

  removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  // Altera o estado do grupo ativo
  togleGerarNfAntesPagamento = (check) => {
    const isChecked = check.target.checked;
    this.setState({gerarNfAntesPagamento: isChecked});
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {    
    const field = event.target.name

    switch (field) {
      case 'valor':
        this.setState({[field]: formatMoney(event.target.value)});
        break;
      default:
        this.setState({[field]: event.target.value})
        break;
    }
  }

  // Faz o submit no form 
  handleSubmit = async (event) => { 
    // variables
      const {
        descricao,
        msgBoxModal,
        nome,
        produtoServicoID,
        submitType,
        valor,
        gerarNfAntesPagamento
      } = this.state;
      let errors = [];
      let validationsError = [];
      let response;
    //---
    
   
    // validations
      if(!nome) {
        validationsError.push('Nome é de preenchimento Obrigatório')
      }

      if(!descricao) {
        validationsError.push('Descrição é de preenchimento Obrigatório')
      }

      if(!valor) {
        validationsError.push('Valor é de preenchimento Obrigatório')
      }
    //---


    if(validationsError.length > 0) {
      this.setState({
        msgBoxModal: {
          color: 'danger', 
          display: true, 
          msg: validationsError 
        }
      });
      event.preventDefault();
    } else {

      const produtoServico = {
        nome,
        descricao,
        'valor': valor.replace('.', '').replace(',','.'),
        gerarNfAntesPagamento
      };
      

      // Cria novo serviço
      if(produtoServicoID == '')
        response = await createProdutoServicoService(produtoServico);
      else if(submitType == 'save')
        response = await updateProdutoServicoService(produtoServico, produtoServicoID);
      else if(submitType == 'delete')
        response = await deleteProdutoServicoService(produtoServicoID);
      else {
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: ['Não foi possível realizar a ação']}})
        event.preventDefault();
      }

        
      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          produtoServicoID: '',
          nome: '',
          descricao: '',
          valor: '',
          submitType: '',
          modalCreateUpdate: false,
          msgBox: {
            color: 'success', 
            display: true, 
            msg: [`Produto/Serviço ${produtoServicoID == '' ? 'cadastrado': (submitType == 'delete'? 'excluído': 'atualizado') } com sucesso`]
          },
        });
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: errors }})
      }
    }
    
    this.listProdutoServicos();
    event.preventDefault();
    
  }

  render() {
    // Variáveis 
      const { 
        descricao, 
        msgBox,
        msgBoxModal,
        nome, 
        productData, 
        produtoServicoID,
        titleModal,
        valor,
        gerarNfAntesPagamento
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        })
      }
    //---

    // Configurações datatables
      const columns = [{
        dataField: 'id',
        text: '#',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        isDummyField: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <LinkPattern
            to={"#/"}
            style={{fontSize: "20px"}} 
            onClick={this.togModalCreateUpdate}
            name={"btn-edit"}
            data-id={row.id}
            action="atualiza"
          >
            <i 
              className="bx bx-edit" 
              name={"btn-edit"}
              data-id={row.id}
            />
          </LinkPattern>
        )
      }, {
        dataField: 'nome',
        text: 'Nome',
        sort: true
      }, {
        dataField: 'descricao',
        text: 'Descrição',
        sort: true
      }, {
        dataField: 'valor_padrao',
        text: 'Valor',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        formatter: (valor) => {
          return formatMoney(valor, 'pt-BR', true);
        }
      }, {
        dataField: 'created_at',
        text: 'Data Cadastro',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (data) => {
          let dataArray = data.split('T')
          let dia = dataArray[0].split('-').reverse().join('/');
          let hora = dataArray[1].split('.')[0];
          return `${dia} ${hora}`;
        }
      }];

      const defaultSorted = [{
        dataField: 'created_at',
        order: 'asc'
      }];

      const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '30', value: 30 },
        { text: 'All', value: productData.length }
      ];

      const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
        sizePerPageList
      };

      const { SearchBar } = Search;
    //---

    return(
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Produto serviço" breadcrumbItem="List" />

            <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardHeader style={{backgroundColor: "#fff"}}>
                <ButtonPattern
                  name={"btn-create"}
                  className={'btn btn-primary'}
                  style={{float: 'right'}} 
                  onClick={this.togModalCreateUpdate}
                  action="cadastra"
                >
                  Cadastrar
                </ButtonPattern>
              </CardHeader>
              <CardBody>
                <CardTitle className="h4">Listagem de Produto/ Serviço</CardTitle>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row className="mb-2" style={{marginTop: "25px"}}>
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />

                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps }
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }
                </PaginationProvider>
              </CardBody>    
            </Card>

            <Modal
              size="lg"
              isOpen={this.state.modalCreateUpdate}
              toggle={this.togModalCreateUpdate}
            >
              <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    {titleModal}
                  </h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ modalCreateUpdate: false })
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                    <input type="hidden" name="produtoServicoID" value={produtoServicoID}/>
                    <Alert color={msgBoxModal.color} isOpen={msgBoxModal.display} toggle={msgBoxDismiss} fade={false}>
                      <ul>
                        { msgBoxModal.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                      </ul>
                    </Alert>

                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="nome">Nome<span className="text-danger" >*</span></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="nome"
                            name="nome"
                            onChange={this.handleChange}
                            value={nome}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="descricao">Descrição<span className="text-danger" >*</span></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="descricao"
                            name="descricao"
                            onChange={this.handleChange}
                            value={descricao}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="valor">Valor<span className="text-danger" >*</span> </Label>
                          <Input                         
                            type="text"
                            className="form-control"
                            id="valor"
                            name="valor"
                            onChange={this.handleChange}
                            value={valor}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row style={{marginTop: '15px'}}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={gerarNfAntesPagamento}
                            onChange={this.togleGerarNfAntesPagamento}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Gera Nota Fiscal Antes do Pagamento
                          </label>
                        </div>
                      </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <ButtonPattern
                    type="submit"
                    name={"save-update"}
                    className={'btn btn-success'}
                    onClick={() => this.setState({submitType: 'save'})}
                    action={produtoServicoID == '' ? 'cadastra': 'atualiza'}
                  >
                    {produtoServicoID == '' ? 'Salvar': 'Atualizar'}
                  </ButtonPattern>

                  <ButtonPattern
                    type="submit"
                    name={"delete"}
                    className={'btn btn-danger'}
                    style={{ display: (produtoServicoID == '' ? 'none': '') }}
                    onClick={() => this.setState({submitType: 'delete'})}
                    action="exclui"
                  >
                    Deletar
                  </ButtonPattern>

                  <ButtonPattern
                    name={"btn-close"}
                    className={'btn btn-secondary'}
                    onClick={this.togModalCreateUpdate} 
                    data-dismiss="modal"
                    action="cancela"
                  >
                    Cancelar
                  </ButtonPattern>
                </div>
              </AvForm>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ProdutoServico;