// Função Responsável por cadastrar o cliente
import accessToken from '../helpers/JWT/accessToken';

/* types interface fron request
  interface ISearchClienteRecorrencia {
    ftr_cliente: string,
    ftr_produtoServico: string, 
    ftr_dia_vencimento: number
  }

  interface ICreateClienteRecorrencia {
    cliente_id: number,
    produto_servico_id: number,
    dia_vencimento: number,
    transacao_processa: boolean,
    valor_mensalidade: number,
    valor_transacao: number
  }

  interface IUpdateClienteRecorrencia {
    cliente_id: number,
    produto_servico_id: number,
    dia_vencimento: number,
    transacao_processa: boolean,
    valor_mensalidade: number,
    valor_transacao: number
  }
*/

/**
 * 
 * @param {} ISearchClienteRecorrencia 
 * @returns 
 */
const searchClienteRecorrenciaService = async ({ftr_cliente, ftr_produtoServico, ftr_dia_vencimento}) => {
    const _token = accessToken();
  
    let params = '';
  
    // Monta os parâmetros de pesquisa
      if(ftr_cliente)
        params += `cliente_id=${ftr_cliente}&`;
  
      if(ftr_produtoServico)
        params += `produto_servico_id=${ftr_produtoServico}&`;

      if(ftr_dia_vencimento)
        params += `dia_vencimento=${ftr_dia_vencimento}&`;
    //---

  const clienteResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteRecorrencia?${params}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })


  return clienteResponse;
}

const getClienteRecorrenciaByIDService = async (clienteRecorrenciaID) => {
  const _token = accessToken();
  const clienteRecorrenciaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteRecorrencia/${clienteRecorrenciaID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return clienteRecorrenciaResponse;
}

/**
 * 
 * @param {} ICreateClienteRecorrencia 
 * @returns 
 */
const createClienteRecorrenciaService = async (clienteRecorrencia) => {
  const _token = accessToken();

  // Faz o cadastro do cliente recorrência
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteRecorrencia`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(clienteRecorrencia),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

/**
 * @param clienteRecorrenciaID  number
 * @param {} IUpdateClienteRecorrencia 
 * @returns 
 */
const updateClienteRecorrenciaService = async (clienteRecorrenciaID, clienteRecorrencia) => {
  const _token = accessToken();
  
  const clienteRecorrenciaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteRecorrencia/${clienteRecorrenciaID}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(clienteRecorrencia),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  return clienteRecorrenciaResponse;
}

export {
  searchClienteRecorrenciaService,
  getClienteRecorrenciaByIDService,
  createClienteRecorrenciaService,
  updateClienteRecorrenciaService
}