import accessToken from '../helpers/JWT/accessToken';


const createClienteProdutoServicoService = async ({cliente_id, sistema_id, produto_servico_id}) => {
  const _token = accessToken();

  // Dados 
  const clienteProdutoServicoParams = {
    cliente_id, 
    sistema_id, 
    produto_servico_id
  }

  // Faz o cadastro do cliente
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteProdutoServico`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(clienteProdutoServicoParams),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

const updateClienteProdutoServicoService = async (clienteProdutoServicoID, {cliente_id, sistema_id, produto_servico_id}) => {
  const _token = accessToken();

  // Dados 
  const clienteProdutoServicoParams = {
    cliente_id, 
    sistema_id, 
    produto_servico_id
  }

  // Faz o cadastro do cliente
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteProdutoServico/${clienteProdutoServicoID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(clienteProdutoServicoParams),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

const deleteClienteProdutoServicoService = async (clienteProdutoServicoID) => {
  const _token = accessToken();

  const clienteProdutoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/clienteProdutoServico/${clienteProdutoServicoID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      })
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })

  return clienteProdutoServicoResponse;
}

export {
  createClienteProdutoServicoService,
  updateClienteProdutoServicoService,
  deleteClienteProdutoServicoService
}