// React
import React, { Component } from "react";
import * as moment from 'moment';

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  Col, 
  Container, 
  FormGroup,
  Input, 
  InputGroup, 
  Label, 
  Row, 
  Table 
} from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import Flatpickr from "react-flatpickr";  

// Componets
import { ButtonPattern } from "components/ButtonPattern";
import { Loading } from "components/Loading";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "flatpickr/dist/themes/material_blue.css"

// Services
import { searchClienteService } from '../../services/ClienteService';
import { searchProdutoServicoService } from "../../services/ProdutoServicoService";
import { searchBancoService } from "../../services/BancoService";
import { searchContasReceberService } from "../../services/ContasReceberService";


class RelatorioContasReceber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Filtro
      ftr_cliente: '',
      ftr_produtoServico: '',
      ftr_banco: '',
      ftr_numeroNFE: '',
      ftr_dataVencimento: '',

      // Resposta da consulta
      contasReceber: [],
      consultaRealizada: false,

      // Carregamento de Select
      clientes: [],
      produtoServicos:[],
      bancos: [],

      // Select2 selecionado
      selectCliente: '',

      isLoading: false,

      msgBox: {
        color: 'sucess',
        display: false,
        msg: []
      }
    }
  }

  // Executa após carregar a tela
  componentDidMount() {
    this.listProdutoServicos();
    this.listBanco();
  }

  // Busca a listagem de produto / serviço
  listProdutoServicos = async () => {
    const response = await searchProdutoServicoService();
    if(response.errors.length == 0)
      this.setState({produtoServicos: response.produtoServicos});
    else
      this.setState({produtoServicos: []});
  }

  // Busca a listagem de Banco
  listBanco = async () => {
    const response = await searchBancoService();
    if(response.errors.length == 0)
      this.setState({bancos: response.bancos});
    else
      this.setState({bancos: []});
  }

  // Faz a consulta do cliente pela descricao
  searchClientes = async (ftr_cliente) => {
    const clientes = await searchClienteService({ftr_nome: ftr_cliente});
    return clientes;
  }

  // Faz o filtro da consulta no select 2 do clinte
  handleSelectCliente = (ftr_cliente) => {
    if(ftr_cliente.length > 2) {

      this.searchClientes(ftr_cliente).then(response => {
        const clientes = response.clientes.map(cliente => {
          return {
            label: `${cliente.nome}`, value: cliente.id 
          }
        })
        this.setState({clientes})
      })
    }
  }

  // Altera o estados das variáveis para o select do cliente
  handleChangeCliente = (event) => {
    if(event != null) {
      this.setState({
        ftr_cliente: event.value,
        selectCliente: event // Mostra o select pressionado
      });
    } else {
      this.setState({
        ftr_cliente: null,
        selectCliente: event
      });
    }
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    let field = event.target.name
    this.setState({[field]: event.target.value})
  }
   
  // Faz o submit no form 
  handleSubmit = async (event) => { 
    this.setState({isLoading: true});
            
    // Variáveis
      const { 
        ftr_cliente,
        ftr_produtoServico,
        ftr_banco,
        ftr_numeroNFE,
        ftr_dataVencimento
      } = this.state;
      let errors = [];
    //---

    const searchParams = {
      ftr_cliente,
      ftr_produtoServico,
      ftr_banco,
      ftr_numeroNFE,
      ftr_dataVencimento
      }

    const response = await searchContasReceberService(searchParams);

    // Pega o erro que vem da consulta da API
    if(response.errors.length == 0) {
      this.setState({
        contasReceber: response.contasReceber,
        consultaRealizada: true,
        isLoading: false
      });
      event.preventDefault();
    } else {
      response.errors.forEach(msg => {
        errors.push(msg.message)
      });
      this.setState({
        msgBox: {color: 'danger', display: true, msg: errors },
        isLoading: false
      });
      event.preventDefault();
    }
  }
  
  render() {
    // Variáveis
      const {
        clientes,
        contasReceber, 
        produtoServicos, 
        bancos, 
        consultaRealizada, 
        ftr_produtoServico,
        ftr_banco,
        isLoading,
        msgBox,
        selectCliente
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <Loading isShow={isLoading} />
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Contas Receber" breadcrumbItem="Contas Receber" />
            <Row>
              <Col xl={12}>
                <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Filtros de contas a receber</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div className="mb-3 select2-container">
                            <Label>Clientes</Label>
                            <Select
                              placeholder="Mínimo 3 caracteres para consulta"
                              classNamePrefix="select2-selection"
                              isClearable={true}
                              onInputChange={this.handleSelectCliente}
                              onChange={this.handleChangeCliente}
                              options={clientes}
                              value={selectCliente}
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_produtoServico">Produto / Serviço</Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="ftr_produtoServico" 
                              id="ftr_produtoServico" 
                              onChange={this.handleChange}
                              value={ftr_produtoServico}
                            >
                              <option value="">--- Selecione ---</option>
                              {produtoServicos.map(produtoServico => {
                                return <option value={produtoServico.id} key={produtoServico.id}>{produtoServico.nome}</option>
                              })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_banco">Bancos</Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="ftr_banco" 
                              id="ftr_banco" 
                              onChange={this.handleChange}
                              value={ftr_banco}
                            >
                              <option value="">--- Selecione ---</option>
                              {bancos.map(banco => {
                                return <option value={banco.id} key={banco.id}>{banco.descricao}</option>
                              })}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_numeroNFE">Número da NFE</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="ftr_numeroNFE"
                              name="ftr_numeroNFE"
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <AvGroup>
                            <Label>Data Vencimento</Label>
                            <InputGroup>
                              <Flatpickr
                                id="ftr_dataVencimento"
                                name="ftr_dataVencimento"
                                className="form-control"
                                placeholder="dd/mm/yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d"
                                }}
                                onChange={(date, dateStr) => {
                                  this.setState({ftr_dataVencimento: dateStr});
                                }}
                              />
                            </InputGroup>
                          </AvGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <ButtonPattern 
                            name="btn-pesquisar"
                            type="submit" 
                            className="btn btn-primary"
                            style={{marginTop: "15px"}}
                            action="pesquisa"
                          >
                            Pesquisar
                          </ButtonPattern>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row> 

            <Row style={consultaRealizada ? {display: "block"} : {display: "none"}}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Listagem de contas a receber </h4>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Cliente</th>
                            <th>Produto</th>
                            <th>Referente</th>
                            <th>Valor</th>
                            <th>Data vencimento</th>
                            <th>Valor pago</th>
                            <th>Data pagamento</th>
                            <th>Número NFE</th>
                            <th>Nosso Número</th>
                            <th>Banco</th>
                            <th>Boleto</th>
                            <th>Nota Fiscal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (contasReceber.length > 0 ?
                              contasReceber.map(cr => {
                                return (
                                  
                                  <tr key={cr.id}>
                                    <td>{ cr.id }</td>
                                    <td>{ cr.cliente.nome }</td>
                                    <td>{ cr.produtoServico.nome }</td>
                                    <td>{ cr.referente }</td>
                                    <td>{ (cr.valor && parseFloat(cr.valor).toLocaleString('pt-br', {minimumFractionDigits: 2})) }</td>
                                    <td>{ (cr.data_vencimento &&  moment(cr.data_vencimento).format('D/M/Y HH:mm:ss')) }</td>
                                    <td>{ (cr.valor_pago && parseFloat(cr.valor_pago).toLocaleString('pt-br', {minimumFractionDigits: 2})) }</td>
                                    <td>{ (cr.data_pagamento &&  moment(cr.data_pagamento).format('D/M/Y HH:mm:ss')) }</td>
                                    <td>{ cr.nfe_numero }</td>
                                    <td>{ cr.nosso_numero }</td>
                                    <td>{ cr.banco.descricao }</td>
                                    <td>
                                      {!cr.data_pagamento &&
                                        <a href={`${process.env.REACT_APP_URL_BASE_API}/sicred/impressaoBoleto/${cr.uuid}`} target="_blank" className="social-list-item bg-primary text-white border-primary" rel="noreferrer">
                                          <i className="fas fa-print" />
                                        </a>
                                      }
                                    </td>
                                    <td>
                                      {cr.nfe_link &&
                                        <a href={`${cr.nfe_link}`} target="_blank" className="social-list-item bg-primary text-white border-primary" rel="noreferrer">
                                          <i className="fas fa-print" />
                                        </a>
                                      }
                                    </td>
                                </tr>
                                )
                              })
                            :
                              <tr style={{textAlign: "center"}}>
                                <td colSpan={7}>Nenhum registro encontrado!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RelatorioContasReceber;
