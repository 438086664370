const login = async (data) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
  };

  return fetch(`${process.env.REACT_APP_URL_BASE_API}/login`, requestOptions)
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })  
}

export {
  login
}
