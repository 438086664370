// React
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import classnames from "classnames";

// Validate
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

// Elements
import { Alert, Row, Col, Card, CardBody, CardTitle, Container, CardHeader, Modal, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";

// Form Mask
import InputMask from "react-input-mask";
import { maskBuild } from "../../helpers/Masks/masks";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "../../assets/scss/datatables.scss";

// Services
import { 
  searchUsuarioService,
  getUsuarioByIDService,
  createUsuarioService,
  updateUsuarioService,
  deleteUsuarioService
} from "../../services/UsuarioService";
import { searchGrupoService } from "../../services/GrupoService";


class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dados datatable
      page: 1,
      sizePerPage: 10,
      productData: [],

      // Dados usuário
      usuarioID: '',
      grupoID: '',
      nome: '',
      email: '',
      senha: '',
      confirmaSenha: '',
      ativo: 'S',
      
      // Select
      grupos: [],

      // Controle modal
      modalCreateUpdate: false,
      titleModal: '',
      submitType: '',

      // Controle de tela
      msgBox: {
        color: 'success',
        display: false,
        msg: []
      },
      msgBoxModal: {
        color: 'success',
        display: false,
        msg: []
      },
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    await this.listGrupo();
    await this.listUsuario();
  }

  // Busca a listagem de Grupo
  listGrupo = async () => {
    const response = await searchGrupoService();
    if(response.errors.length == 0)
      this.setState({grupos: response.grupos});
    else
      this.setState({grupos: []});
  }

  // Busca a listagem de Banco Conta
  listUsuario = async () => {
    const response = await searchUsuarioService();
    if(response.errors.length == 0)
      this.setState({productData: response.usuarios});
    else
      this.setState({productData: []});
  }

  // Abre e fecha o modal create/update
  togModalCreateUpdate = async (event) => {
    const btn = event.target.getAttribute("name");
    let errors = [];

    if(btn == 'btn-create' || btn == 'btn-close') {
      this.setState(prevState => ({
        modalCreateUpdate: !prevState.modalCreateUpdate,
        usuarioID: '',
        grupoID: '',
        nome: '',
        email: '',
        senha: '',
        confirmaSenha: '',
        ativo: 'S',
        titleModal: 'Cadastrar Usuário'
      }));
      this.removeBodyCss();

    } else if(btn == 'btn-edit'){
      const usuarioID = event.target.getAttribute("data-id");
      const response = await getUsuarioByIDService(usuarioID);

      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          usuarioID: usuarioID,
          grupoID: response.usuario.grupo_id,
          nome: response.usuario.nome,
          email: response.usuario.email,
          ativo: (response.usuario.ativo ? 'S' : 'N'),
          modalCreateUpdate: true,
        titleModal: 'Atualizar/Inativar Usuário'
        });
        this.removeBodyCss();
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }}) 
      }
    }
  }

  removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {    
    let classes = event.target.className
    let field = event.target.name

    // Atualiza Valores do state
    this.setState({[field]: event.target.value});
    // this.masks(classes, event.target.value);
  }

  // Faz o submit no form 
  handleSubmit = async (event, errors, values) => { 
    // Atualiza o estado dos select
    this.setState({
      grupoID: document.getElementById("grupoID").value
    });

    // Pega o erro que vem do form
    if(errors.length == 0) {    
      let errors = [];
      let response;
      const usuario = {
        grupo_id: this.state.grupoID,
        nome: this.state.nome,
        email: this.state.email,
        senha: this.state.senha,
        confirma_senha: this.state.confirmaSenha,
        ativo: (this.state.ativo == 'S' ? true : false)
      };
      const usuarioID = this.state.usuarioID;
      const submitType = this.state.submitType;
      

      // Cria novo serviço
      if(usuarioID == '')
        response = await createUsuarioService(usuario);
      else if(submitType == 'save')
        response = await updateUsuarioService(usuario, usuarioID);
      else {
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: ['Não foi possível realizar a ação']}})
        return true;
      }

        
      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          usuarioID: '',
          grupoID: '',
          nome: '',
          email: '',
          senha: '',
          confirmaSenha: '',
          ativo: 'S',
          submitType: '',
          modalCreateUpdate: false,
          msgBox: {
            color: 'success', 
            display: true, 
            msg: [`Usuário ${usuarioID == '' ? 'cadastrado': (submitType == 'delete'? 'inativado': 'atualizado') } com sucesso`]
          },
        });
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: errors }})
      }
      
    }

    this.listUsuario();
    event.preventDefault();
  }

  render() {
    // Variáveis 
      const { productData, grupos, grupoID, usuarioID, nome, email, senha, confirmaSenha, ativo } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        })
      }
    //---

    // Configurações datatables
      const columns = [{
        dataField: 'id',
        text: '#',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        isDummyField: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <LinkPattern 
            to={"#/"}
            style={{fontSize: "20px"}} 
            onClick={this.togModalCreateUpdate}
            name={"btn-edit"}
            data-id={row.id}
            action="atualiza"
          >
            <i 
              className="bx bx-edit" 
              name={"btn-edit"}
              data-id={row.id}
            />
          </LinkPattern>
        )
      }, {
        dataField: 'nome',
        text: 'nome',
        sort: true
      }, {
        dataField: 'email',
        text: 'E-mail',
        sort: true
      }, {
        dataField: 'grupo.descricao',
        text: 'Grupo',
        sort: true
      }, {
        dataField: 'ativo',
        text: 'Ativo',
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <span className={`font-size-12 badge rounded-pill ${row.ativo ? 'bg-success': 'bg-danger'}`}>
            {row.ativo ? 'SIM': 'NÃO'}
          </span>
        )
      }, {
        dataField: 'created_at',
        text: 'Data Cadastro',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (data) => {
          let dataArray = data.split('T')
          let dia = dataArray[0].split('-').reverse().join('/');
          let hora = dataArray[1].split('.')[0];
          return `${dia} ${hora}`;
        }
      }];

      const defaultSorted = [{
        dataField: 'created_at',
        order: 'asc'
      }];

      const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '30', value: 30 },
        { text: 'All', value: productData.length }
      ];

      const pageOptions = {
        sizePerPage: 10,
        totalSize: this.state.productData.length, // replace later with size(customers),
        custom: true,
        sizePerPageList
      };

      const { SearchBar } = Search;
    //---

    return(
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Usuário" breadcrumbItem="List" />

            <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardHeader style={{backgroundColor: "#fff"}}>
                <ButtonPattern
                  style={{float: 'right'}} 
                  type="button"
                  onClick={this.togModalCreateUpdate}
                  className="btn btn-primary"
                  name={"btn-create"}
                  action="cadastra"
                >
                  Cadastrar
                </ButtonPattern>
              </CardHeader>
              <CardBody>
                <CardTitle className="h4">Listagem de Usuário</CardTitle>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row className="mb-2" style={{marginTop: "25px"}}>
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />

                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps }
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }
                </PaginationProvider>
              </CardBody>    
            </Card>

            <Modal
              size="lg"
              isOpen={this.state.modalCreateUpdate}
              toggle={this.togModalCreateUpdate}
            >
              <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    {this.state.titleModal}
                  </h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ modalCreateUpdate: false })
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                    <input type="hidden" name="usuarioID" value={usuarioID}/>
                    <Alert color={this.state.msgBoxModal.color} isOpen={this.state.msgBoxModal.display} toggle={msgBoxDismiss} fade={false}>
                      <ul>
                        { this.state.msgBoxModal.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                      </ul>
                    </Alert>

                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AvGroup>
                          <Label htmlFor="grupoID">Grupo<span className="text-danger" >*</span></Label>
                          <AvField 
                            type="select"
                            className="form-select" 
                            name="grupoID" 
                            id="grupoID" 
                            onChange={this.handleChange}
                            value={grupoID}
                          >
                            {grupos.map(grupo => { 
                              return(
                                <option key={grupo.id} value={grupo.id}>{grupo.descricao}</option>
                              )
                            })}
                          </AvField>
                        </AvGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AvGroup>
                          <Label htmlFor="nome">Nome<span className="text-danger" >*</span></Label>
                          <AvField
                            type="text"
                            className="form-control"
                            id="nome"
                            name="nome"
                            onChange={this.handleChange}
                            value={nome}
                            validate={{
                              required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                            }}
                          />
                        </AvGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AvGroup>
                          <Label htmlFor="email">E-mail<span className="text-danger" >*</span></Label>
                          <AvField
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                            value={email}
                            validate={{
                              required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                            }}
                          />
                        </AvGroup>
                      </Col>
                    </Row>
                    
                    <Row >
                      { usuarioID == '' ? 
                        <>
                          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <AvGroup>
                                <Label htmlFor="senha">Senha<span className="text-danger" >*</span></Label>
                                <AvField
                                  type="password"
                                  className="form-control"
                                  id="senha"
                                  name="senha"
                                  onChange={this.handleChange}
                                  value={senha}
                                  validate={{
                                    required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                                  }}
                                />
                              </AvGroup>
                          </Col>
                        
                          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <AvGroup>
                              <Label htmlFor="confirmaSenha">Confirmação da senha<span className="text-danger" >*</span></Label>
                              <AvField
                                type="password"
                                className="form-control"
                                id="confirmaSenha"
                                name="confirmaSenha"
                                onChange={this.handleChange}
                                value={confirmaSenha}
                                validate={{
                                  required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </>
                      : '' }

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AvGroup><Label htmlFor="ativo">Ativo<span className="text-danger" >*</span></Label>
                          <AvField
                            type="select"
                            className="form-select" 
                            name="ativo" 
                            id="ativo"
                            onChange={this.handleChange}
                            value={ativo}
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </AvField>
                        </AvGroup>
                      </Col>
                    </Row>
                    
                </div>
                <div className="modal-footer">
                  <ButtonPattern
                    type="submit"
                    className="btn btn-success"
                    onClick={() => this.setState({submitType: 'save'})}
                    action={usuarioID == '' ? 'cadastra': 'atualiza'}
                  >
                    {usuarioID == '' ? 'Salvar': 'Atualizar'}
                  </ButtonPattern>
                  <ButtonPattern
                    type="button"
                    onClick={this.togModalCreateUpdate}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    name={"btn-close"}
                    action="cancela"
                  >
                    Cancelar
                  </ButtonPattern>
                </div>
              </AvForm>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Usuario;