import React from "react"
import { Redirect } from "react-router-dom"


// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Financeiro
import RelatorioContasReceber from '../pages/ContasReceber';

// Cadastro
import ClienteList from '../pages/Cliente';
import ClienteForm from '../pages/Cliente/form';
import ProdutoServico  from '../pages/ProdutoServico';
import BancoConta from '../pages/BancoConta';
import ClienteRecorrenciaList from '../pages/ClienteRecorrencia';
import ClienteRecorrenciaForm from '../pages/ClienteRecorrencia/form';
import FaturamentoWebrenaveList from '../pages/FaturamentoWebrenave';
import FaturamentoWebrenaveForm from '../pages/FaturamentoWebrenave/form';


// Configurações
import GrupoPermissaoList from '../pages/GrupoPermissao';
import GrupoPermissaoForm from '../pages/GrupoPermissao/form';
import Usuario from '../pages/Usuario';
import Senha from '../pages/Senha';
import Empresa from '../pages/Empresa';

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPwd2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

//Crypto
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"


const authProtectedRoutes = [

  // Dashboard
  { path: "/dashboard", component: Dashboard },

  // Financeiro
  { path: "/contaReceber", component: RelatorioContasReceber },

  //Cadastros
  { path: "/cliente", component: ClienteList },
  { path: "/cliente/create", component: ClienteForm },
  { path: "/cliente/:clienteID/edit", component: ClienteForm },
  { path: "/produtoServico", component: ProdutoServico },
  { path: "/bancoConta", component: BancoConta },
  { path: "/empresa", component: Empresa },
  { path: "/clienteRecorrencia", component: ClienteRecorrenciaList },
  { path: "/clienteRecorrencia/create", component: ClienteRecorrenciaForm },
  { path: "/clienteRecorrencia/:clienteRecorrenciaID/edit", component: ClienteRecorrenciaForm },
  { path: "/grupoWebrenave", component: FaturamentoWebrenaveList },
  { path: "/grupoWebrenave/create", component: FaturamentoWebrenaveForm },
  { path: "/grupoWebrenave/:grupoCobrancaID/edit", component: FaturamentoWebrenaveForm },

  //Configurações
  { path: "/grupoPermissao", component: GrupoPermissaoList },
  { path: "/grupoPermissao/create", component: GrupoPermissaoForm },
  { path: "/grupoPermissao/:grupoID/edit", component: GrupoPermissaoForm },
  { path: "/usuario", component: Usuario },
  { path: "/senha", component: Senha },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },

  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },

  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/pages-recoverpw-2", component: Recoverpw2 },

  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/pages-forgot-pwd-2", component: ForgetPwd2 },

  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
