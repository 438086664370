import accessToken from '../helpers/JWT/accessToken';


/* types interface fron request
  interface IListaCobranca {
   	webrenave_empresa_id: number;
		webrenave_empresa_nome: string;
		webrenave_empresa_cnpj: string;
  }

  interface ICreateListaCobrancaWebrenave {
    lista_cobrancas: IListaCobranca[];
  }
*/

/**
 * 
 * @param grupoCobrancaID: number;
 * @param listaCobranca: ICreateListaCobrancaWebrenave; 
 * @returns 
 */
const createListaCobrancaWebrenaveService = async (grupoCobrancaID, listaCobranca) => {
  const _token = accessToken();
  
  // Faz o cadastro do cliente contato
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}/listaCobranca`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(listaCobranca),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

/**
 * 
 * @param grupoCobrancaID: number;
 * @param listaCobrancaID: number ;
 * @param listaCobranca: IListaCobranca;
 * @returns 
 */
const updateListaCobrancaWebrenaveService = async (grupoCobrancaID, listaCobrancaID, listaCobranca) => {
  const _token = accessToken();

  // Faz atualização do cliente contato
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}/listaCobranca/${listaCobrancaID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(listaCobranca),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

/**
 * 
 * @param grupoCobrancaID: number;
 * @param listaCobrancaID: number ;
 * @returns 
 */
 const deleteListaCobrancaWebrenaveService = async (grupoCobrancaID, listaCobrancaID) => {
  const _token = accessToken();

  // Faz atualização do cliente contato
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}/listaCobranca/${listaCobrancaID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      })
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

export {
  createListaCobrancaWebrenaveService,
  updateListaCobrancaWebrenaveService,
  deleteListaCobrancaWebrenaveService
}