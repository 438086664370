import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os produtos/serviços
const searchProdutoServicoService = async () => {
  const _token = accessToken();
  const produtoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/produtoServico`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return produtoServicoResponse;
}

// Função Responsável por consultar os produtos/serviços
const getProdutoServicoByIDService = async (produtoServicoID) => {
  const _token = accessToken();
  const produtoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/produtoServico/${produtoServicoID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return produtoServicoResponse;
}

// Função Responsável cadastrar novos produtos/serviços
const createProdutoServicoService = async (produtoServico) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (produtoServico) deve ser um objeto
   * Ex:
   * 	{
  	    "nome": "PRODUTO TESTE",
	      "descricao": "FAZENDO TESTE",
	      "valor": 120.33,
        "gerarNfAntesPagamento": false
      }
   */
  const produtoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/produtoServico`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(produtoServico),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  });

  return produtoServicoResponse;
}

// Função Responsável atualizar os produtos/serviços
const updateProdutoServicoService = async (produtoServico, produtoServicoID) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (produtoServco) deve ser um objeto
   * Ex:
   * 	{
  	    "nome": "PRODUTO TESTE",
	      "descricao": "FAZENDO TESTE",
	      "valor": 120.33,
        "gerarNfAntesPagamento": false
      }
   */
  const produtoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/produtoServico/${produtoServicoID}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(produtoServico),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  return produtoServicoResponse;
}

// Função Responsável deletar os produtos/serviços
const deleteProdutoServicoService = async (produtoServicoID) => {
  const _token = accessToken();
  const produtoServicoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/produtoServico/${produtoServicoID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      })
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })

  return produtoServicoResponse;
}

export {
  searchProdutoServicoService,
  getProdutoServicoByIDService,
  createProdutoServicoService,
  updateProdutoServicoService,
  deleteProdutoServicoService
};