import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os grupos
const searchGrupoService = async () => {
  const _token = accessToken();
  const grupoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupo`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoResponse;
}

// Função Responsável por consultar os grupos
const getGrupoByIDService = async (grupoID) => {
  const _token = accessToken();
  const grupoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupo/${grupoID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoResponse;
}

// Função Responsável cadastrar o grupo
const createGrupoService = async ({descricao, ativo}) => {
  const _token = accessToken();

  // Dados grupo 
  const grupo = {
    descricao,
    ativo,
  }

  // Faz o cadastro do grupo
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupo`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(grupo),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

// Função Responsável cadastrar o grupo
const updateGrupoService = async (grupoID, {descricao, ativo}) => {
  const _token = accessToken();

  // Dados grupo 
  const grupo = {
    descricao,
    ativo,
  }

  // Faz o cadastro do grupo
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupo/${grupoID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(grupo),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

export {
  searchGrupoService,
  getGrupoByIDService,
  createGrupoService,
  updateGrupoService
};