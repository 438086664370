
// Retorna um boolean se o token é valido ou não
export default function validateToken() {
  const expiresIn = ( JSON.parse(localStorage.getItem("authUser")) != null ? JSON.parse(localStorage.getItem("authUser")).expiresIn : null);
  var currentDate = Date.now()
  
  
  if(!expiresIn){
    localStorage.removeItem("authUser")
    return false;
  }

  if(currentDate > expiresIn) {
    localStorage.removeItem("authUser")
    return false;
  }
  
  return true;
}