import { Link } from "react-router-dom";
import P from 'prop-types';


export const LinkPattern = ({
  to,
  children,  
  // permissions,
  action,
  ...props
}) => {
  let actionRender = true;
  let permission = ( JSON.parse(localStorage.getItem("userRoutePermission")) != null ? JSON.parse(localStorage.getItem("userRoutePermission"))?.[0] : []);

  switch (action) {
    case 'cadastra': 
      actionRender = permission.cadastra == 'S' ? true: false;
      break;
    case 'atualiza': 
      actionRender = permission.atualiza == 'S' ? true: false;
      break;
    case 'exclui': 
      actionRender = permission.exclui == 'S' ? true: false;
      break;
    case 'pesquisa': 
      actionRender = permission.pesquisa == 'S' ? true: false;
      break;
    default: 
      actionRender = true;
      break;
  }

    return actionRender ? (
      <Link 
        to={to}
        {...props}
      >
        {children}
      </Link>
    ) : (<></>);

};



LinkPattern.propTypes = {
  to: P.string.isRequired,
  children: P.node.isRequired,
  // permissions: P.object.isRequired,
  action: P.string
};