import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os banco conta
const searchBancoContaService = async () => {
  const _token = accessToken();
  const bancoContaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/bancoConta`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return bancoContaResponse;
}

// Função Responsável por consultar os banco conta
const getBancoContaByIDService = async (bancoContaID) => {
  const _token = accessToken();
  const bancoContaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/bancoConta/${bancoContaID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return bancoContaResponse;
}

// Função Responsável cadastrar novos banco conta
const createBancoContaService = async (bancoConta) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (bancoConta) deve ser um objeto
   * Ex:
   * 	{
  		  "banco_id": 1,
	      "agencia": "66052",
	      "conta": "12345"
      }
   */
  const bancoContaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/bancoConta`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(bancoConta),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  });

  return bancoContaResponse;
}

// Função Responsável atualizar os banco conta
const updateBancoContaService = async (bancoConta, bancoContaID) => {
  const _token = accessToken();

  /**
   * O formato do argumento passado (bancoConta) deve ser um objeto
   * Ex:
   * 	{
  	   	"banco_id": 1,
	      "agencia": "66052",
	      "conta": "12345"
      }
   */
  const bancoContaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/bancoConta/${bancoContaID}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(bancoConta),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  return bancoContaResponse;
}

// Função Responsável deletar os banco conta
const deleteBancoContaService = async (bancoContaID) => {
  const _token = accessToken();

  const bancoContaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/bancoConta/${bancoContaID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      })
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })

  return bancoContaResponse;
}

export {
  searchBancoContaService,
  getBancoContaByIDService,
  createBancoContaService,
  updateBancoContaService,
  deleteBancoContaService
};