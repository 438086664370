import P from 'prop-types';

export const ButtonPattern = ({
  children, 
  action,
  ...props
}) => {
  let actionRender = true;
  let permission = ( JSON.parse(localStorage.getItem("userRoutePermission")) != null ? JSON.parse(localStorage.getItem("userRoutePermission"))?.[0] : []);
  

  switch (action) {
    case 'cadastra': 
      actionRender = permission.cadastra == 'S' ? true: false;
      break;
    case 'atualiza': 
      actionRender = permission.atualiza == 'S' ? true: false;
      break;
    case 'exclui': 
      actionRender = permission.exclui == 'S' ? true: false;
      break;
    case 'pesquisa': 
      actionRender = permission.pesquisa == 'S' ? true: false;
      break;
    default: 
      actionRender = true;
      break;
  }

    return actionRender ? (
      <button {...props}>
        {children} 
      </button>
    ) : (<></>);

};


ButtonPattern.propTypes = {
  children: P.node.isRequired,
  action: P.string
};


