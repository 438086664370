// React
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

// Elements
import { Alert, Row, Col, Card, CardBody, CardTitle, Container, CardHeader, Modal, Label, Input } from "reactstrap";
import { LinkPattern } from "components/LinkPattern";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "../../assets/scss/datatables.scss";

// Services
import { searchGrupoService } from "../../services/GrupoService";

class GrupoPermissaoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dados datatable
      page: 1,
      sizePerPage: 10,
      productData: [],

      // Controle de tela
      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }
  // Executa após carregar a tela
  async componentDidMount() {
    await this.listGrupo();
  }

  // Busca a listagem de Grupo
  listGrupo = async () => {
    const response = await searchGrupoService();
    if(response.errors.length == 0)
      this.setState({productData: response.grupos});
    else
      this.setState({productData: []});
  }

  render() {
    // Variáveis 
      const { productData } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        })
      }
    //---


    // Configurações datatables
      const columns = [{
        dataField: 'id',
        text: '#',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        isDummyField: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <LinkPattern 
            to={`/grupoPermissao/${row.id}/edit`}
            style={{fontSize: "20px"}} 
            name={"btn-edit"}
            data-id={row.id}
            action="atualiza"
          >
            <i 
              className="bx bx-edit" 
              name={"btn-edit"}
              data-id={row.id}
            />
          </LinkPattern>
        )
      }, {
        dataField: 'descricao',
        text: 'Grupo',
        sort: true
      }, {
        dataField: 'ativo',
        text: 'Ativo',
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <span className={`font-size-12 badge rounded-pill ${row.ativo ? 'bg-success': 'bg-danger'}`}>
            {row.ativo ? 'SIM': 'NÃO'}
          </span>
        )
      }];

      const defaultSorted = [{
        dataField: 'grupo',
        order: 'asc'
      }];

      const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '30', value: 30 },
        { text: 'All', value: productData.length }
      ];

      const pageOptions = {
        sizePerPage: 10,
        totalSize: this.state.productData.length, // replace later with size(customers),
        custom: true,
        sizePerPageList
      };

      const { SearchBar } = Search;
    //---

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Grupo Permissão" breadcrumbItem="Grupo Permissão" />

            <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardHeader style={{backgroundColor: "#fff"}}>
                <LinkPattern 
                  to={`/grupoPermissao/create`} 
                  className="btn btn-primary" 
                  style={{float: 'right'}}
                  action="cadastra"
                >
                  Cadastrar
                </LinkPattern>
              </CardHeader>
              <CardBody>
                <CardTitle className="h4">Listagem de Grupos</CardTitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row className="mb-2" style={{marginTop: "25px"}}>
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />

                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps }
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }
                </PaginationProvider>
              </CardBody>    
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )

  }
}

export default GrupoPermissaoList;
