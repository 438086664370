import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por cadastrar o endereço
const createClienteContatoService = async (contatos, clienteID) => {
  const _token = accessToken();

  // O formato do argumento passado (contatos deve ser) um array de objetos
  /**
   * Ex:
   *  "contatos": [
        {
          "ativo": true,
          "principal": true,
          "tipo": "E",
          "valor": "jose@teste.com"
        },
        {
          "ativo": true,
          "principal": true,
          "tipo": "E",
          "valor": "josesecundario@teste.com"
        },
        {
          "ativo": true,
          "principal": true,
          "tipo": "T",
          "valor": "14996569899"
        }
      ]
   */


  // Faz o cadastro do cliente contato
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}/contatos`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify({contatos}),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

// Função Responsável por atualiza o endereço
const updateClienteContatoService = async (contato, contatoID, clienteID) => {
  const _token = accessToken();

  // O formato do argumento passado (contatos deve ser) um objeto
  /**
   * Ex:
   *  
    {
      "ativo": true,
      "principal": true,
      "tipo": "E",
      "valor": "jose@teste.com"
    } 
   */

  // Faz atualização do cliente contato
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}/contatos/${contatoID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(contato),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}


export {
  createClienteContatoService,
  updateClienteContatoService
}