import P from 'prop-types';
import './style.css';

export const Loading = ({ 
  isShow = false,
}) => {
  return isShow ? (
    <div className="wrapper-loading">
      <div className="loading">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <span>Carregando</span>
      </div>
    </div>
  ) : (<></>);
};

Loading.propTypes = {
  isShow: P.bool
};


