// React
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';


// Validate
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

// Form Mask
import InputMask from "react-input-mask";
import { maskBuild } from "../../helpers/Masks/masks";

// Elements
import { Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Table } from "reactstrap";
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Services
import { searchClienteService } from '../../services/ClienteService';


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authUser: JSON.parse(localStorage.getItem("authUser")),
      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }
  
  
  render() {
    const { user } = this.state.authUser;
    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            <Row>
              <Col xl={12}>
                <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardBody>
                    <div style={{textAlign: 'center'}}>
                      <h1>Seja bem vindo ao ERP</h1>
                      <h3>{user}</h3>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
