// Faz a consulta do cep no site do ViaCep
/*
  const searchCep = async (cep) => {
    // Faz a consulta do cep
    return fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
  }
*/

// Faz a consulta na api do .15
const searchCep = async (cep) => {
  // Faz a consulta do cep
  return fetch(`${process.env.REACT_APP_URL_BASE_CEP}?cep=${cep}&chave=${process.env.REACT_APP_CHAVE_CEP}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
//---
}

export {
  searchCep
}