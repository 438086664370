import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por consultar os bancos
const searchBancoService = async () => {
  const _token = accessToken();
  const bancoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/banco`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return bancoResponse;
}

export {
  searchBancoService,
};