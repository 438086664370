import accessToken from '../helpers/JWT/accessToken';

/* types interface fron request
  interface ISearchGrupoCobranca {
    cliente_id?: string,
    cpf_cnpj?: string, 
  }

  interface ICreateGrupoCobrancaWebrenave {
    cliente_id: number;
	  dia_vencimento: string;
	  descricao: string;
	  valor: number
  }
*/

/**
 * 
 * @param {} ISearchGrupoCobranca 
 * @returns 
 */
const searchGrupoCobrancaWebrenaveService = async ({cliente_id, cpf_cnpj}) => {
  const _token = accessToken();

  let params = '';

  // Monta os parâmetros de pesquisa
    if(cliente_id)
      params += `cliente_id=${cliente_id}&`;

    if(cpf_cnpj)
      params += `cpf_cnpj=${cpf_cnpj}&`;
  //---

  const clienteResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave?${params}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })


  return clienteResponse;
}

/**
 * 
 * @param grupoCobranca: ICreateGrupoCobrancaWebrenave 
 * @returns 
 */
const createGrupoCobrancaWebrenaveService = async (grupoCobranca) => {
  const _token = accessToken();
  
  return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(grupoCobranca),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
}

/**
 * 
 * @param grupoCobrancaID: number 
 * @param grupoCobranca: ICreateGrupoCobrancaWebrenave 
 * @returns 
 */
const updateGrupoCobrancaService = async (grupoCobrancaID, grupoCobranca) => {
  const _token = accessToken();

  return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
    body: JSON.stringify(grupoCobranca),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  
}

/**
 * 
 * @param grupoCobrancaID: number 
 * @returns 
 */
 const deleteGrupoCobrancaService = async (grupoCobrancaID) => {
  const _token = accessToken();

  return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    })
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })
  
}

/**
 * 
 * @param grupoCobrancaID: number
 * @returns 
 */
const getGrupoCobrancaWebrenavByIDService = async (grupoCobrancaID) => {
  const _token = accessToken();
  const grupoCobrancaResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoWebrenave/${grupoCobrancaID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoCobrancaResponse;
}

export {
  searchGrupoCobrancaWebrenaveService,
  createGrupoCobrancaWebrenaveService,
  updateGrupoCobrancaService,
  deleteGrupoCobrancaService,
  getGrupoCobrancaWebrenavByIDService
}