import accessToken from '../helpers/JWT/accessToken';

// Função Responsável por listar as permissões do usuário logado
const listPermissoesService = async () => {
  const _token = accessToken();
  const grupoPermissaoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoPermissao`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoPermissaoResponse;
}

// Função Responsável por consultar as permissões do grupo
const getGrupoPermissaoByIDService = async (grupoID) => {
  const _token = accessToken();
  const grupoPermissaoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoPermissao/grupo/${grupoID}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoPermissaoResponse;
}

// Função Responsável por listar as permissões do usuário logado
const listPermissoesNewGrupoService = async () => {
  const _token = accessToken();
  const grupoPermissaoResponse = await fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoPermissao/new`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+_token,
    }),
  })
  .then((response) => {
    return response.json()
  })
  .catch((err) => {
    return err
  })

  return grupoPermissaoResponse;
}

// Função Responsável cadastrar o grupo
const createGrupoPermissaoService = async ({grupo_id, permissoes}) => {
  const _token = accessToken();

  // Dados grupo 
  const grupoPermissao = {
    grupo_id,
    permissoes,
  }

  // Faz o cadastro do grupo
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/grupoPermissao`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(grupoPermissao),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}


export {
  listPermissoesService,
  getGrupoPermissaoByIDService,
  listPermissoesNewGrupoService,
  createGrupoPermissaoService
};