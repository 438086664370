// React
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import classnames from "classnames";

// Validate
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

// Elements
import { Alert, Row, Col, Card, CardBody, CardTitle, Container, CardHeader, Modal, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";

// Form Mask
import InputMask from "react-input-mask";
import { maskBuild } from "../../helpers/Masks/masks";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "../../assets/scss/datatables.scss";

// Services
import { updateSenhaUsuarioService } from "../../services/UsuarioService";


class BancoConta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dados datatable
      page: 1,
      sizePerPage: 10,
      productData: [],

      // Dados perfil
      senhaAtual: '',
      senhaNova: '',
      confirmaSenha: '',

      // Controle de tela
      msgBox: {
        color: 'success',
        display: false,
        msg: []
      },
    }
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {    
    let classes = event.target.className
    let field = event.target.name

    // Atualiza Valores do state
    this.setState({[field]: event.target.value});
    // this.masks(classes, event.target.value);
  }

  // Faz o submit no form 
  handleSubmit = async (event, errors, values) => { 
    // Pega o erro que vem do form
    if(errors.length == 0) {    
      let errors = [];
      let response;
      const updateSenhaUsuario = {
        senha_atual: this.state.senhaAtual,
        nova_senha: this.state.senhaNova,
        confirma_senha: this.state.confirmaSenha
      };

      response = await updateSenhaUsuarioService(updateSenhaUsuario);
      
      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          senhaAtual: '',
          senhaNova: '',
          confirmaSenha: '',
          msgBox: {
            color: 'success', 
            display: true, 
            msg: [`Senha atualizada com sucesso`]
          },
        });
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }})
      }
    }
    // event.preventDefault();
    
  }

  render() {
    // Variáveis 
      const { senhaAtual, senhaNova, confirmaSenha } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return(
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Senha" breadcrumbItem="update" />

            <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardBody>
                <CardTitle className="h4">Alterar Senha</CardTitle>
                <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <AvGroup>
                        <Label htmlFor="senhaAtual">Senha atual<span className="text-danger" >*</span></Label>
                        <AvField
                          type="password"
                          className="form-control"
                          id="senhaAtual"
                          name="senhaAtual"
                          onChange={this.handleChange}
                          value={senhaAtual}
                          validate={{
                            required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <AvGroup>
                        <Label htmlFor="senhaNova">Nova senha<span className="text-danger" >*</span></Label>
                        <AvField
                          type="password"
                          className="form-control"
                          id="senhaNova"
                          name="senhaNova"
                          onChange={this.handleChange}
                          value={senhaNova}
                          validate={{
                            required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                          }}
                        />
                      </AvGroup>
                    </Col>

                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <AvGroup>
                        <Label htmlFor="confirmaSenha">Confirmar senha<span className="text-danger" >*</span></Label>
                        <AvField
                          type="password"
                          className="form-control"
                          id="confirmaSenha"
                          name="confirmaSenha"
                          onChange={this.handleChange}
                          value={confirmaSenha}
                          validate={{
                            required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>

                  <Row>                    
                    <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                      <Button color="success" type="submit" style={{ marginRight: "10px" }}>
                        Salvar
                      </Button>
                      <Link to={`/`} className="btn btn-secondary">
                        Cancelar
                      </Link>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>    
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default BancoConta;