// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  CardHeader, 
  Col, 
  Container, 
  FormGroup,
  Input, 
  Label, 
  Row, 
  Table 
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

// Componets
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";
import { Loading } from "components/Loading";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// helpers
import { formatCpfCnpj } from '../../helpers/Functions/index';

// Services
import { searchClienteService } from '../../services/ClienteService';


class ClienteList extends Component {
  constructor(props) {
    super(props)
    this.state = {
            
      ftr_nome: '',
      ftr_cpfCnpj: '',
      ftr_status: 'T',
      clientes: [],
      consultaRealizada: false,
      isLoading: false,
      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      }
    }
  }
  

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    const field = event.target.name;

    switch (field) {
      case 'ftr_cpfCnpj':
        this.setState({[field]: formatCpfCnpj(event.target.value)});
        break;
      default:
        this.setState({[field]: event.target.value});
        break;
    }
  }
   
  // Faz o submit no form 
  handleSubmit = async (event) => { 
    this.setState({isLoading: true});

    // variables
      const {
        ftr_nome,
        ftr_cpfCnpj,
        ftr_status
      } = this.state;
      let errors = [];
    //---

    const searchParams = {
      ftr_nome,
      'ftr_cpfCnpj': ftr_cpfCnpj.replace(/\D/gim, ''),
      ftr_status
    }

    const response = await searchClienteService(searchParams);

    // Pega o erro que vem da consulta da API
    if(response.errors.length == 0) {
      this.setState({
        clientes: response.clientes,
        consultaRealizada: true,
        isLoading: false
      });
    } else {
      response.errors.forEach(msg => {
        errors.push(msg.message)
      });
      this.setState({
        msgBox: {color: 'danger', display: true, msg: errors },
        isLoading: false
      }) 
    }
    
    event.preventDefault();
  }
  
  render() {
    // Variáveis
      const {
        clientes, 
        consultaRealizada, 
        ftr_cpfCnpj,
        isLoading 
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <Loading isShow={isLoading} />
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cliente" breadcrumbItem="Cliente" />
            <Row>
              <Col xl={12}>
                <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardHeader style={{backgroundColor: "#fff"}}>
                    <LinkPattern 
                      to={`/cliente/create`} 
                      className="btn btn-primary" 
                      style={{float: 'right'}}
                      action="cadastra"
                    >
                      Cadastrar
                    </LinkPattern>
                  </CardHeader>
                  <CardBody>
                    <h4 className="card-title">Filtros de clientes</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_nome">Nome</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="ftr_nome"
                              name="ftr_nome"
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="ftr_cpfCnpj">CPF/CNPJ</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="ftr_cpfCnpj"
                              name="ftr_cpfCnpj"
                              onChange={this.handleChange}
                              value={ftr_cpfCnpj}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                            <Label htmlFor="ftr_status">Status</Label>
                            <select 
                              className="form-select" 
                              name="ftr_status" 
                              id="ftr_status" 
                              defaultValue="T"
                              onChange={this.handleChange}
                            >
                              <option value="T">Todos</option>
                              <option value="A">Ativo</option>
                              <option value="I">Inativo</option>
                            </select>
                          </FormGroup>
                        </Col>

                      </Row>

                      <Row>
                        <Col md={3}>
                          <ButtonPattern 
                            className="btn btn-primary"
                            type="submit" 
                            style={{marginTop: "15px"}}
                            action="pesquisa"
                          >
                            Pesquisar
                          </ButtonPattern>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row> 

            <Row style={consultaRealizada ? {display: "block"} : {display: "none"}}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Listagem de clientes </h4>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th>Parceiro</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (clientes.length > 0 ?
                              clientes.map(cliente => {
                                return (
                                  <tr key={cliente.id}>
                                    <th scope="row">
                                      <LinkPattern 
                                        to={`/cliente/${cliente.id}/edit`} 
                                        style={{fontSize: "20px"}}
                                        action="atualiza"
                                      >
                                        <i className="bx bx-edit" />
                                      </LinkPattern>
                                    </th>
                                    <td>{cliente.nome}</td>
                                    <td>{cliente.parceiro.descricao}</td>
                                    <td>
                                      <span className={`font-size-12 badge rounded-pill ${cliente.ativo ? 'bg-success': 'bg-danger'}`}>
                                        {cliente.ativo ? 'SIM': 'NÃO'}
                                      </span>
                                      </td>
                                  </tr>
                                )
                              })
                            :
                              <tr style={{textAlign: "center"}}>
                                <td colSpan={4}>Nenhum registro encontrado!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteList;
