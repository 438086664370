import PropTypes from "prop-types"
import React, { Component } from "react"
import classname from "classnames"


//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// Services
import { listPermissoesNewGrupoService } from "../../services/GrupoPermissoesService";

// Components
import { FragmentMap } from '../../components/FragmentMap';


class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
    this.state = {
      permissoes: JSON.parse(localStorage.getItem("userPermission")) != null ? JSON.parse(localStorage.getItem("userPermission")) : []
    }
  }

  async componentDidMount() {
    await this.initMenu(); 
  }

  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      await this.initMenu()
    }
  }


  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  buildTree = (permissoes = [], menuPai = null, indiceStop = 0) => {
   
    if(indiceStop == permissoes.length)
      return;

    if(menuPai != null) {
      return permissoes.map(menu => {
        // Percorre apenas os menus que são filhos 
        if (menu.menu_pai == menuPai) {

          // Menu pai
          if (menu.pai) {
            return (
              <li key={menu.menu_id}>
                <Link to="" className="has-arrow">
                  <i className={menu.icone} />
                  <span>{this.props.t(menu.descricao)}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {this.buildTree(permissoes, menu.menu_id, indiceStop + 1)}
                </ul>
              </li>
            )
          } else {
            return(
              <li key={menu.menu_id}>
                <Link to={`/${menu.path}`} className="">
                  <i className={menu.icone} />
                  <span>{this.props.t(menu.descricao)}</span>
                </Link>
              </li>
            )
          }

        } 
      })

    } else {
      return permissoes.map(menu => {
        
        // Menu pai e principal
        if(menu.menu_pai == null && menu.principal && menu.mostra_menu == 'S' && menu.acessa == 'S') {
          return (
            <FragmentMap key={menu.menu_id}>
              <li className="menu-title">{this.props.t(menu.descricao)}</li>
              {permissoes.map(menuFilho => {

                // Verifica se o menu percorrido é filho
                if (menu.menu_id == menuFilho.menu_pai && menuFilho.mostra_menu == 'S' && menuFilho.acessa == 'S') {

                  // Menu filho tambem é pai
                  if (menuFilho.pai) {
                    return(
                      <li key={menuFilho.menu_id}>
                        <Link to="" className="has-arrow">
                          <i className={menuFilho.icone} />
                          <span>{this.props.t(menuFilho.descricao)}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                          {this.buildTree(permissoes, menuFilho.menu_id, indiceStop + 1)}
                        </ul>
                      </li>
                    )
                  } 

                  // Menu é filho
                  else {
                    return(
                      <li key={menuFilho.menu_id}>
                        <Link to={`/${menuFilho.path}`} className="">
                          <i className={menuFilho.icone} />
                          <span>{this.props.t(menuFilho.descricao)}</span>
                        </Link>
                      </li>
                    )
                  }
                }
              })}
            </FragmentMap>
          )
        }
      })
    }
  }

  render() {
    const { permissoes } = this.state;
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {this.buildTree(permissoes)}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}


export default withRouter(withTranslation()(SidebarContent))
