export const FragmentMap = ({ 
  children,
  ...props
}) => {
  return(
    <>
      {children}
    </>
  );
};

