// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  CardTitle,
  Col, 
  Container, 
  FormGroup,
  Input, 
  Label, 
  Row, 
  Table 
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Select from "react-select";


// Componets
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// helpers
import { 
  formatCpfCnpj,
  formatMoney,
  validateCpfCnpj,
  validateRegex
} from '../../../helpers/Functions/index';

// Services
import { searchClienteService } from '../../../services/ClienteService';

import { 
  createGrupoCobrancaWebrenaveService,
  getGrupoCobrancaWebrenavByIDService,
  updateGrupoCobrancaService,
  deleteGrupoCobrancaService
} from '../../../services/GrupoCobrancaWebrenaveService';

import { 
  createListaCobrancaWebrenaveService,
  updateListaCobrancaWebrenaveService,
  deleteListaCobrancaWebrenaveService
} from '../../../services/ListaCobrancaWebrenaveService';


class FaturamentoWebrenaveCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Variável para verificar se é create ou update
      grupoCobrancaID: this.props.match.params.grupoCobrancaID || '',
            
      selectClienteID: '',
      selectCliente: '',
      diaVencimento: '',
      valorSaida: '',
      valorUsado: '',
      idSistemaWebrenave: '',
      nomeSistemaWebrenave: '',
      cpfCnpjSistemaWebrenave: '',


      // Controle de Arrays
      listCobranca: [],
      

      // Indices edição / exclusão
      editandoCobrancaList: false,
      indiceEdicaoCobrancaList: null,
     
      clientes: [],

      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      }
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    // variáveis
      const { grupoCobrancaID } = this.state;
    //---

    // Se for update, carregar os dados dos grupo cobrança
    if(grupoCobrancaID != '') {
      const response = await getGrupoCobrancaWebrenavByIDService(grupoCobrancaID);
      
      if(response.errors.length == 0) {
        const { 
          grupoWebrenave: {
            cliente,
            dia_vencimento,
            valor,
            valorUsado,
            listaCobrancaWebrenave
         }
        } = response;

        // Formata array de lista de cobranca
          const arrListaCobranca = listaCobrancaWebrenave.map(lista => {
            return {
              id: lista.id,
              acao: 'U', // C - create, U - update, D - delete
              webrenave_empresa_id: lista.webrenave_empresa_id, 
              webrenave_empresa_nome: lista.webrenave_empresa_nome,
              webrenave_empresa_cnpj: lista.webrenave_empresa_cnpj
            }
          });
        //---

        // Deixa o cliente selecionado no select 2
          const selectCliente = {
            label: `${cliente.nome}`, 
            value: cliente.id 
          }
        //---

        this.setState({
          selectClienteID: cliente.id,
          selectCliente,
          diaVencimento: dia_vencimento,
          valorSaida: formatMoney(valor),
          valorUsado: formatMoney(valorUsado),
          listCobranca: arrListaCobranca,
        });
      } else {
        let errors = [];
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });

        this.props.history.push({
          pathname: '/grupoWebrenave',
          msgBox: { 
            color: 'danger',
            display: true,
            msg: errors
          }
        });
      }
    }
  }

  // Faz a consulta do município pelo ID
  searchMunicipioByID = async (municipioID) => {
    const municipio = await searchMunicipioByIDService(municipioID);
    return municipio;
  }

  // Faz a consulta do município pela descricao
  searchClientes = async (ftr_cliente) => {
    const clientes = await searchClienteService({'ftr_nome':ftr_cliente});
    return clientes;
  }

  // Preenche o select 2
  fillSelectCliente = async (clienteID) => {
    const response = await this.getClienteByIDService(clienteID);
    
    if(response.errors.length == 0) {
      const cliente = response.cliente;
      const selectCliente = {
        label: `${cliente.nome}`, 
        value: cliente.id 
      }
      this.setState({selectCliente});
    }
  }
  
  // Faz o filtro da consulta no select 2 do cliente
  handleSelectCliente = (ftr_cliente) => {
    if(ftr_cliente.length > 2) {

      this.searchClientes(ftr_cliente).then(response => {
        const clientes = response.clientes.map(cliente => {
          return {
            label: `${cliente.nome}`, value: cliente.id 
          }
        })
        this.setState({clientes})
      })
    }
  }

  // Altera o estados das variáveis para o select do cliente
  handleChangeCliente = (event) => {
    if(event != null) {
      this.setState({
        selectClienteID: event.value,
        selectCliente: event // Mostra o select pressionado
      });
    } else {
      this.setState({
        selectClienteID: '',
        selectCliente: event
      });
    }
  }

  // Adiciona novo contato na listagem
  addCobrancaList = (event) => {
    // variáveis
      const { 
        idSistemaWebrenave, 
        nomeSistemaWebrenave,
        cpfCnpjSistemaWebrenave,
        listCobranca
      } = this.state;
      let errors = [];
    //---


    // Verifica se todas as variáveis do estado estão preenchidas
      if(idSistemaWebrenave == '') {
        errors.push(`O campo 'ID sistema webrenave' deve ser preenchido.`);
      } 

      if(nomeSistemaWebrenave == '') {
        errors.push(`O campo 'Nome' deve ser preenchido.`);
      } 

      if(cpfCnpjSistemaWebrenave == '') {
        errors.push(`O campo 'CPF/CNPJ' deve ser preenchido.`);
      } 

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        event.preventDefault();
        return false;
      }
    //---

    // Verifica se já não contem o ID/ CPF/CNPJ para o lançamento
      for(let i in listCobranca) {
        if(listCobranca[i].idSistemaWebrenave == idSistemaWebrenave) {
          errors.push(`ID sistema webrenave já cadastrado na listagem`);
          break;
        } 

        if(listCobranca[i].cpfCnpjSistemaWebrenave == cpfCnpjSistemaWebrenave.replace(/\D/gim, '')){
          errors.push(`CPF/CNPJ já cadastrado na listagem`);
          break;
        }
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        event.preventDefault();
        return false;
      }
    //---
      
      // Adiciona o novo endereço
      listCobranca.push({
        acao: 'C', // C - create, U - update, D - delete
        webrenave_empresa_id: idSistemaWebrenave, 
        webrenave_empresa_nome: nomeSistemaWebrenave,
        webrenave_empresa_cnpj: cpfCnpjSistemaWebrenave.replace(/\D/gim, '')
      });

      // Adiciona os novos estados
      this.setState({
        idSistemaWebrenave: '',
        nomeSistemaWebrenave: '',
        cpfCnpjSistemaWebrenave: '',
        listCobranca
      });
    //---
    console.log(listCobranca);
    return true;
  }

  // Exclui o contato
  excluiCobrancaList = (indice) => {
    const { listCobranca } = this.state;

    let cobrancas = [...listCobranca];
    const cobrancaExclusao = cobrancas[indice];


    if(typeof cobrancaExclusao != 'undefined') {
      if(confirm(`Deseja excluir a cobranca: \n 
        ID sistema webrenave: ${cobrancaExclusao.webrenave_empresa_id} \n
        CPF/CNPJ: ${formatCpfCnpj(cobrancaExclusao.webrenave_empresa_cnpj)}`)
      ) {

        // Verificar se existe ID, apenas mudar status para false
        if(typeof cobrancaExclusao.id != 'undefined')
          cobrancas[indice].acao = 'D';
        else
          cobrancas.splice(indice, 1);

        const cobrancaNew = cobrancas.map(cobranca => cobranca);
        this.setState({listCobranca: cobrancaNew})
      }
    }
  }

  // Edita o contato
  editaCobrancaList = (indice) => {
    const { listCobranca } = this.state;

    let cobrancas = [...listCobranca];
    const cobrancaEdicao = cobrancas[indice];

    this.setState({
      idSistemaWebrenave: cobrancaEdicao.webrenave_empresa_id,
      nomeSistemaWebrenave: cobrancaEdicao.webrenave_empresa_nome,
      cpfCnpjSistemaWebrenave: formatCpfCnpj(cobrancaEdicao.webrenave_empresa_cnpj),
      editandoCobrancaList: true,
      indiceEdicaoCobrancaList: indice
    });
  }

  // Salva a edição do contato
  salvaEdicaoCobrancaList = (event) => {
    // variáveis
      const { 
        idSistemaWebrenave, 
        nomeSistemaWebrenave,
        cpfCnpjSistemaWebrenave,
        indiceEdicaoCobrancaList,
        listCobranca
      } = this.state;
      const indice = indiceEdicaoCobrancaList;
      let errors = [];
    //---


    // Verifica se todas as variáveis do estado estão preenchidas
      if(idSistemaWebrenave == '') {
        errors.push(`O campo 'ID sistema webrenave' deve ser preenchido.`);
      } 

      if(nomeSistemaWebrenave == '') {
        errors.push(`O campo 'Nome' deve ser preenchido.`);
      } 

      if(cpfCnpjSistemaWebrenave == '') {
        errors.push(`O campo 'CPF/CNPJ' deve ser preenchido.`);
      } 

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        event.preventDefault();
        return false;
      }
    //---

    // Edita o array de contatos
      let cobrancas = [ ...listCobranca ];

      // Verifica se já não contem o ID/ CPF/CNPJ para o lançamento
      for(let i in listCobranca) {
        if(listCobranca[i].idSistemaWebrenave == idSistemaWebrenave && i != indice) {
          errors.push(`ID sistema webrenave já cadastrado na listagem`);
          break;
        } 

        if(listCobranca[i].cpfCnpjSistemaWebrenave == cpfCnpjSistemaWebrenave.replace(/\D/gim, '') && i != indice){
          errors.push(`CPF/CNPJ já cadastrado na listagem`);
          break;
        }
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        event.preventDefault();
        return false;
      }
    //---
    
      // Altera os dados da cobranca
      cobrancas[indice].acao = (typeof cobrancas[indice].id != 'undefined' ? 'U' : 'C');
			cobrancas[indice].webrenave_empresa_id = idSistemaWebrenave;
			cobrancas[indice].webrenave_empresa_nome = nomeSistemaWebrenave;
			cobrancas[indice].webrenave_empresa_cnpj = cpfCnpjSistemaWebrenave.replace(/\D/gim, '');

      // Adiciona os novos estados
      this.setState({
        idSistemaWebrenave: '',
        nomeSistemaWebrenave: '',
        cpfCnpjSistemaWebrenave: '',
        editandoCobrancaList: false,
        indiceEdicaoCobrancaList: null
      });
    //---
    return true;
  }

  // Cancela edição contato
  cancelaEdicaoCobrancaList = (event) => {
    this.setState({
      idSistemaWebrenave: '',
      nomeSistemaWebrenave: '',
      cpfCnpjSistemaWebrenave: '',
      editandoCobrancaList: false,
      indiceEdicaoCobrancaList: null
    });
  }

  // Exclui o grupo cobrança e as listagens
  deleteGrupoCobranca = async (event) => {
    const { grupoCobrancaID, selectCliente } = this.state;
    let errors = [];

    if(confirm(`Deseja realmente excluir o grupo cobrança ?`)) {
      const response = await deleteGrupoCobrancaService(grupoCobrancaID);

      if(response.errors.length > 0) {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });

        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: errors 
          }
        });
        event.preventDefault();

      } else {
        this.props.history.push({
          pathname: '/grupoWebrenave',
          msgBox: { 
            color: 'success',
            display: true,
            msg: [`O grupo ${selectCliente.label} foi excluído com sucesso`]
          }
        });
      }
    }
  };

  // validate fiels on blur
  handleBlur = (event) => { 
    // variables
      let field = event.target.name;
      const { 
        nomeSistemaWebrenave,
        cpfCnpjSistemaWebrenave 
      } = this.state;
    //---
    
    switch(field) {
      case 'cpfCnpjSistemaWebrenave':
        if(!validateCpfCnpj(cpfCnpjSistemaWebrenave)) {
          this.setState({
            msgBox: {
              color: 'danger', 
              display: true, 
              msg: ['O CPF/CNPJ digitado é inválido']
            },
            cpfCnpjSistemaWebrenave: ''
          });
        }
        break;
      case 'nomeSistemaWebrenave':
        if(!validateRegex(nomeSistemaWebrenave, /[a-z]* [a-z]*/)) {
          this.setState({
            msgBox: {
              color: 'danger', 
              display: true, 
              msg: ['O nome deve ser composto. Ex: Demo Webrenave']
            },
            nomeSistemaWebrenave: ''
          });
        }
        break;
    }
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    const field = event.target.name;

    switch (field) {
      case 'cpfCnpjSistemaWebrenave':
        this.setState({[field]: formatCpfCnpj(event.target.value)});
        break;
      case 'valorSaida':
      case 'valorUsado':
        this.setState({[field]: formatMoney(event.target.value)});
        break;
      default:
        this.setState({[field]: event.target.value});
        break;
    }
  }
   
  // Faz o submit no form 
  handleSubmit = async (event) => { 
    this.setState({isLoading: true});

    // variables
      const {
        grupoCobrancaID,
        selectClienteID,
        selectCliente,
        diaVencimento,
        valorSaida,
        valorUsado,
        listCobranca,        
      } = this.state;
      let errors = [];
      let validationsError = [];
      let responseGrupoCobranca;
      let responseCreateListaCobranca;
      let responseUpdateListaCobranca;
      let responseDeleteListaCobranca;
    //---

    // validations
      if(!selectClienteID) {
        validationsError.push('Cliente é de preenchimento Obrigatório');
      }

      if(!diaVencimento) {
        validationsError.push('Dia vencimento é de preenchimento Obrigatório');
      }

      if(!valorSaida) {
        validationsError.push('Valor 0km é de preenchimento Obrigatório');
      }

      if(!valorUsado) {
        validationsError.push('Valor Usado é de preenchimento Obrigatório');
      }

      if(listCobranca.length <= 0) {
        validationsError.push('É obrigatório o vinculo com ao menos um cliente webrenave');
      } else {
        let verifyListError = true;
        listCobranca.forEach(lista => {
          if(lista.acao == 'C' || lista.acao == 'U') {
            verifyListError = false;
          }
        });

        if(verifyListError) {
          validationsError.push('É obrigatório o vinculo com ao menos um cliente webrenave');
        }
      }
    //---

    if(validationsError.length > 0) {
      this.setState({
        msgBox: {
          color: 'danger', 
          display: true, 
          msg: validationsError 
        },
        isLoading: false
      });
      event.preventDefault();
    } else {

      // Dados grupo cobrança 
      const grupoCobrancaParams = {
        cliente_id: selectClienteID,
        dia_vencimento: diaVencimento,
        descricao: selectCliente.label,
        valor: valorSaida.replace('.', '').replace(',','.'),
        valorUsado: valorUsado.replace('.', '').replace(',','.')
      };
      
      if(grupoCobrancaID != '')
        responseGrupoCobranca = await updateGrupoCobrancaService(grupoCobrancaID, grupoCobrancaParams);
      else
        responseGrupoCobranca = await createGrupoCobrancaWebrenaveService(grupoCobrancaParams);

      // Verifica se ocorreu erro no cadastro do responseCliente
      if(responseGrupoCobranca.errors.length == 0) { 
        let arrCreateListaCobranca = [];
        let arrUpdateListaCobranca = [];
        let arrDeleteListaCobranca = [];
        const grupoCobrancaIDCurrent = grupoCobrancaID != '' ? grupoCobrancaID : responseGrupoCobranca.grupoWebrenave.id;


        listCobranca.forEach(lista => {
          if(lista.acao == "C") {
            arrCreateListaCobranca.push(lista);
          } else if(lista.acao == "U") {
            arrUpdateListaCobranca.push(lista);
          } else {
            arrDeleteListaCobranca.push(lista);
          }
        });

        // Cadastra lista de cobraça
          responseCreateListaCobranca = await createListaCobrancaWebrenaveService(grupoCobrancaIDCurrent, { lista_cobrancas: arrCreateListaCobranca });
          if(responseCreateListaCobranca.errors.length > 0) {
            responseCreateListaCobranca.errors.forEach(msg => {
              errors.push(msg.message)
            });
          }
        //---

        // Atualiza a lsita de cobraça
          // Cria função async para pegar os erros caso existir
          async function updateListaCobranca(grupoCobrancaIDCurrent, listaCobrancaID, listaCobranca){
            responseUpdateListaCobranca = await updateListaCobrancaWebrenaveService(grupoCobrancaIDCurrent, listaCobrancaID, listaCobranca);          
            return await responseUpdateListaCobranca;
          }

          // Espera todas as atualizações ficarem prontas
          const updListaCobranca = await Promise.all(
            arrUpdateListaCobranca.map(listaCobranca => updateListaCobranca(grupoCobrancaIDCurrent, listaCobranca.id, listaCobranca))
          );

          // Percorre a atualização dos contatos para verificar se tem errors
          updListaCobranca.forEach(listaCobrancaWebrenave => {
            if(listaCobrancaWebrenave.errors.length > 0) {
              listaCobrancaWebrenave.errors.forEach(msg => {
                errors.push(msg.message)
              });
            }
          });
        //---

        // Deleta a lsita de cobraça
          // Cria função async para pegar os erros caso existir
          async function deleteListaCobranca(grupoCobrancaIDCurrent, listaCobrancaID){
            responseDeleteListaCobranca = await deleteListaCobrancaWebrenaveService(grupoCobrancaIDCurrent, listaCobrancaID);          
            return await responseDeleteListaCobranca;
          }

          // Espera todas as atualizações ficarem prontas
            const delListaCobranca = await Promise.all(
              arrDeleteListaCobranca.map(listaCobranca => deleteListaCobranca(grupoCobrancaIDCurrent, listaCobranca.id))
          );

          // Percorre a atualização dos contatos para verificar se tem errors
            delListaCobranca.forEach(listaCobrancaWebrenave => {
            if(listaCobrancaWebrenave.errors.length > 0) {
              listaCobrancaWebrenave.errors.forEach(msg => {
                errors.push(msg.message)
              });
            }
          });
        //---

      } else {
        responseGrupoCobranca.errors.forEach(msg => {
          errors.push(msg.message)
        });
      }


      //Verifica se ocorreu algum erro
      if(errors.length > 0) {
        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: errors 
          },
          isLoading: false
        });
        event.preventDefault();
      } else {
        const msgSuccess = grupoCobrancaID != '' ? 'Grupo cobrança atualizado com sucesso' : 'Grupo cobrança cadastrado com sucesso';
        this.props.history.push({
          pathname: '/grupoWebrenave',
          msgBox: { 
            color: 'success',
            display: true,
            msg: [msgSuccess]
          }
        });
      }

      event.preventDefault();
    }
  }
  
  render() {
    // Variáveis
      const {
        cpfCnpjSistemaWebrenave,
        clientes,
        diaVencimento,
        editandoCobrancaList,
        grupoCobrancaID,
        idSistemaWebrenave,
        listCobranca,
        msgBox,
        nomeSistemaWebrenave,
        selectCliente,
        valorSaida,
        valorUsado
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cliente" breadcrumbItem="Cliente" />
            <Row>
              <Col xl={12}>
                <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{grupoCobrancaID != '' ? 'Atualização do' : 'Cadastro de'} faturamento webrenave</CardTitle>
                    <p className="card-title-desc">Tela utilizada para realizar {grupoCobrancaID != '' ? 'a atualização' : 'o cadastro'}  do faturamento webrenave, e quais são as empresas que vão ser vinculadas ao cliente</p>

                    <h4 className="card-title">Filtros de clientes</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="mb-3 select2-container">
                            <Label>Cliente</Label>
                            <Select
                              placeholder="Mínimo 3 caracteres para consulta"
                              classNamePrefix="select2-selection"
                              isClearable={true}
                              onInputChange={this.handleSelectCliente}
                              onChange={this.handleChangeCliente}
                              options={clientes}
                              value={selectCliente}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} xl={2}>
                          <FormGroup>
                            <Label htmlFor="diaVencimento">Dia vencimento</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="diaVencimento"
                              name="diaVencimento"
                              maxLength={2}
                              onChange={this.handleChange}
                              value={diaVencimento}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} xl={2}>
                          <FormGroup>
                            <Label htmlFor="valorSaida">Valor 0km</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="valorSaida"
                              name="valorSaida"
                              onChange={this.handleChange}
                              value={valorSaida}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} xl={2}>
                          <FormGroup>
                            <Label htmlFor="valorUsado">Valor Usado</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="valorUsado"
                              name="valorUsado"
                              onChange={this.handleChange}
                              value={valorUsado}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '15px'}}>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="idSistemaWebrenave">ID sistema webrenave</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="idSistemaWebrenave"
                              name="idSistemaWebrenave"
                              onChange={this.handleChange}
                              value={idSistemaWebrenave}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="nomeSistemaWebrenave">Nome</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="nomeSistemaWebrenave"
                              name="nomeSistemaWebrenave"
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={nomeSistemaWebrenave}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="cpfCnpjSistemaWebrenave">CPF/CNPJ</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="cpfCnpjSistemaWebrenave"
                              name="cpfCnpjSistemaWebrenave"
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={cpfCnpjSistemaWebrenave}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <Link 
                            to={`#/`} 
                            className="btn btn-primary" 
                            style={{marginTop: "27px", display: (editandoCobrancaList ? 'none' : '')}} 
                            onClick={this.addCobrancaList}
                          >
                            Adicionar
                          </Link>
                          <Link 
                            to={`#/`} 
                            className="btn btn-success" 
                            style={{marginTop: "27px", display: (editandoCobrancaList ? '' : 'none')}} 
                            onClick={this.salvaEdicaoCobrancaList}
                          >
                            Salvar edição
                          </Link>
                          <Link 
                            to={`#/`} 
                            className="btn btn-secondary" 
                            style={{marginTop: "27px", marginLeft: "10px", display: (editandoCobrancaList ? '' : 'none')}} 
                            onClick={this.cancelaEdicaoCobrancaList}
                          >
                            Cancelar edição
                          </Link>
                        </Col>
                      </Row>

                      <Row style={{marginTop: "15px"}}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">Listagem dos IDS vinculados ao cliente para geração de boleto</h4>
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>ID sistema webrenave</th>
                                      <th>Nome</th>
                                      <th>CPF/CNPJ</th>
                                      <th>Ações</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      (listCobranca.length > 0 ?
                                        listCobranca.map((item, index) => {
                                          if(item.acao != 'D') {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  {item.webrenave_empresa_id}
                                                </td>
                                                <td>
                                                  {item.webrenave_empresa_nome}
                                                </td>
                                                <td>
                                                  {formatCpfCnpj(item.webrenave_empresa_cnpj)}
                                                </td>
                                                <td>
                                                  <Link 
                                                    to={'#/'} 
                                                    style={{fontSize: "20px"}}
                                                    onClick={() => this.editaCobrancaList(index)}
                                                  >
                                                    <i className="bx bx-edit" data-indice={index}/>
                                                  </Link>

                                                  <Link 
                                                    to={'#/'} 
                                                    style={{fontSize: "20px", color: "rgba(244, 106, 106)", marginLeft: "10px"}} 
                                                    onClick={() => this.excluiCobrancaList(index)}
                                                  >
                                                    <i className="bx bx-trash" data-indice={index}/>
                                                  </Link>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        })
                                      :
                                        <tr style={{textAlign: "center"}}>
                                          <td colSpan={5}>Nenhum vinculo cadastrado!</td>
                                        </tr>
                                      )
                                    }
                                  </tbody>
                                </Table>
                              </div>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <Row>                    
                        <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                          <ButtonPattern 
                            type="submit" 
                            style={{ marginRight: "10px" }}
                            className={'btn btn-success'}
                            action={grupoCobrancaID == '' ? 'cadastra' : 'atualiza'}
                          >
                            Salvar
                          </ButtonPattern>
                          {grupoCobrancaID != '' && 
                            <LinkPattern 
                              to={'#/'}
                              style={{ marginRight: "10px" }}
                              className={'btn btn-danger'}
                              onClick={this.deleteGrupoCobranca}
                              action="exclui"
                            >
                              Excluir
                            </LinkPattern>
                          }
                          <LinkPattern 
                            to={`/grupoWebrenave`} 
                            className="btn btn-secondary"
                            action="cancela"
                          >
                            Cancelar
                          </LinkPattern>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default FaturamentoWebrenaveCreate;
