// React
import React, { Component } from "react";

// Validate

// Elements
import { 
  Alert, 
  Col, 
  Card, 
  CardBody, 
  CardTitle, 
  Container, 
  CardHeader, 
  FormGroup,
  Input,
  Label, 
  Modal, 
  Row
} from "reactstrap";
import { AvField, AvForm} from "availity-reactstrap-validation";
import { Link } from "react-router-dom";


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// CSS
import "../../assets/scss/datatables.scss";

// Services
import { 
  searchBancoContaService,
  getBancoContaByIDService,
  createBancoContaService,
  updateBancoContaService,
  deleteBancoContaService
} from "../../services/BancoContaService";
import { searchBancoService } from "../../services/BancoService";
import { LinkPattern } from "components/LinkPattern";
import { ButtonPattern } from "components/ButtonPattern";

class BancoConta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dados datatable
      page: 1,
      sizePerPage: 10,
      productData: [],

      // Dados bancoConta
      bancoContaID: '',
      bancoID: '',
      agencia: '',
      conta: '',
      
      // Select
      bancos: [],

      // Controle modal
      modalCreateUpdate: false,
      titleModal: '',
      submitType: '',

      // Controle de tela
      msgBox: {
        color: 'success',
        display: false,
        msg: []
      },
      msgBoxModal: {
        color: 'success',
        display: false,
        msg: []
      },
    }
  }

  // Executa após carregar a tela
  componentDidMount() {
    this.listBanco();
    this.listBancoConta();
  }

  // Busca a listagem de Banco
  listBanco = async () => {
    const response = await searchBancoService();
    if(response.errors.length == 0)
      this.setState({bancos: response.bancos});
    else
      this.setState({bancos: []});
  }

  // Busca a listagem de Banco Conta
  listBancoConta = async () => {
    const response = await searchBancoContaService();
    if(response.errors.length == 0)
      this.setState({productData: response.bancoContas});
    else
      this.setState({productData: []});
  }

  // Abre e fecha o modal create/update
  togModalCreateUpdate = async (event) => {
    const btn = event.target.getAttribute("name");
    let errors = [];

    if(btn == 'btn-create' || btn == 'btn-close') {
      this.setState(prevState => ({
        modalCreateUpdate: !prevState.modalCreateUpdate,
        bancoContaID: '',
        bancoID: '',
        agencia: '',
        conta: '',
        titleModal: 'Cadastrar Banco Conta',
        msgBox: {color: 'success', display: false, msg: []},
        msgBoxModal: {color: 'success', display: false, msg: []}
      }));
      this.removeBodyCss();

    } else if(btn == 'btn-edit'){
      const bancoContaID = event.target.getAttribute("data-id");
      const response = await getBancoContaByIDService(bancoContaID);

      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          bancoContaID: bancoContaID,
          bancoID: response.bancoConta.banco_id,
          agencia: response.bancoConta.agencia,
          conta: response.bancoConta.conta,
          modalCreateUpdate: true,
          titleModal: 'Atualizar/Excluir Banco Conta',
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        });
        this.removeBodyCss();
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }}) 
      }
    }
  }

  removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {    
    let field = event.target.name;
    this.setState({[field]: event.target.value});
  }

  // Faz o submit no form 
  handleSubmit = async (event) => { 
    // Atualiza o estado dos select
      this.setState({
        bancoID: document.getElementById("bancoID").value
      });
    //---
    
    // Variávis
      const { 
        agencia,
        bancoID,
        bancoContaID,
        conta,
        submitType
      } = this.state;
      let errors = [];
      let validationsError = [];
      let response;
    //---

    // validations
      if(!bancoID) {
        validationsError.push('Banco é de preenchimento Obrigatório')
      }
    
      if(!agencia) {
        validationsError.push('Agência é de preenchimento Obrigatório')
      }

      if(!conta) {
        validationsError.push('Conta é de preenchimento Obrigatório')
      }
    //---

    if(validationsError.length > 0) {
      this.setState({
        msgBoxModal: {
          color: 'danger', 
          display: true, 
          msg: validationsError 
        }
      });
      event.preventDefault();
    } else {
  
      const bancoConta = {
        banco_id: bancoID,
        agencia: agencia,
        conta: conta
      };
    
      // Cria novo serviço
      if(bancoContaID == '')
        response = await createBancoContaService(bancoConta);
      else if(submitType == 'save')
        response = await updateBancoContaService(bancoConta, bancoContaID);
      else if(submitType == 'delete')
        response = await deleteBancoContaService(bancoContaID);
      else {
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: ['Não foi possível realizar a ação']}})
        event.preventDefault();
      }

        
      // Pega o erro que vem da API
      if(response.errors.length == 0) {
        this.setState({
          bancoContaID: '',
          bancoID: '',
          agencia: '',
          conta: '',
          submitType: '',
          modalCreateUpdate: false,
          msgBox: {
            color: 'success', 
            display: true, 
            msg: [`Banco Conta ${bancoContaID == '' ? 'cadastrado': (submitType == 'delete'? 'excluído': 'atualizado') } com sucesso`]
          },
        });
        this.listBancoConta();
      } else {
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({msgBoxModal: {color: 'danger', display: true, msg: errors }})
        event.preventDefault();
      }
    }
  }

  render() {
    // Variáveis 
      const { 
        agencia, 
        bancos, 
        bancoContaID, 
        bancoID, 
        conta,
        msgBox,
        msgBoxModal,
        modalCreateUpdate,
        productData,
        titleModal
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({
          msgBox: {color: 'success', display: false, msg: []},
          msgBoxModal: {color: 'success', display: false, msg: []}
        })
      }
    //---

    // Configurações datatables
      const columns = [{
        dataField: 'id',
        text: '#',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        isDummyField: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <LinkPattern 
            to={"#/"}
            style={{fontSize: "20px"}} 
            onClick={this.togModalCreateUpdate}
            name={"btn-edit"}
            data-id={row.id}
            action="atualiza"
          >
            <i 
              className="bx bx-edit" 
              name={"btn-edit"}
              data-id={row.id}
            />
          </LinkPattern>
        )
      }, {
        dataField: 'banco.descricao',
        text: 'Banco',
        sort: true
      }, {
        dataField: 'agencia',
        text: 'Agência',
        sort: true
      }, {
        dataField: 'conta',
        text: 'Conta',
        sort: true
      }, {
        dataField: 'created_at',
        text: 'Data Cadastro',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (data) => {
          let dataArray = data.split('T')
          let dia = dataArray[0].split('-').reverse().join('/');
          let hora = dataArray[1].split('.')[0];
          return `${dia} ${hora}`;
        }
      }];

      const defaultSorted = [{
        dataField: 'created_at',
        order: 'asc'
      }];

      const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '30', value: 30 },
        { text: 'All', value: productData.length }
      ];

      const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
        sizePerPageList
      };

      const { SearchBar } = Search;
    //---

    return(
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Banco Conta" breadcrumbItem="List" />

            <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardHeader style={{backgroundColor: "#fff"}}>
                <ButtonPattern
                  style={{float: 'right'}} 
                  type="button"
                  className="btn btn-primary"
                  name={"btn-create"}
                  onClick={this.togModalCreateUpdate}
                  action="cadastra"
                >
                  Cadastrar
                </ButtonPattern>
              </CardHeader>
              <CardBody>
                <CardTitle className="h4">Listagem de Banco/ Conta</CardTitle>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={productData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <Row className="mb-2" style={{marginTop: "25px"}}>
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />

                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps }
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }
                </PaginationProvider>
              </CardBody>    
            </Card>

            <Modal
              size="lg"
              isOpen={modalCreateUpdate}
              toggle={this.togModalCreateUpdate}
            >
              <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    {titleModal}
                  </h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ modalCreateUpdate: false })
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                    <input type="hidden" name="bancoContaID" value={bancoContaID}/>
                    <Alert color={msgBoxModal.color} isOpen={msgBoxModal.display} toggle={msgBoxDismiss} fade={false}>
                      <ul>
                        { msgBoxModal.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                      </ul>
                    </Alert>

                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="bancoID">Banco<span className="text-danger" >*</span></Label>
                          <select 
                            type="select"
                            className="form-select" 
                            id="bancoID" 
                            name="bancoID" 
                            onChange={this.handleChange}
                            value={bancoID}
                          >
                            {bancos.map(banco => { 
                              return(
                                <option key={banco.id} value={banco.id}>{banco.descricao}</option>
                              )
                            })}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="agencia">Agência<span className="text-danger" >*</span></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="agencia"
                            name="agencia"
                            onChange={this.handleChange}
                            value={agencia}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup>
                          <Label htmlFor="conta">Conta<span className="text-danger" >*</span></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="conta"
                            name="conta"
                            onChange={this.handleChange}
                            value={conta}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                </div>
                <div className="modal-footer">
                  <ButtonPattern
                    type="submit"
                    className="btn btn-success"
                    onClick={() => this.setState({submitType: 'save'})}
                    action={bancoContaID == '' ? 'cadastra': 'atualiza'}
                  >
                    {bancoContaID == '' ? 'Salvar': 'Atualizar'}
                  </ButtonPattern>
                  <ButtonPattern 
                    style={{ display: (bancoContaID == '' ? 'none': '') }}
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => this.setState({submitType: 'delete'})}
                    action="exclui"
                  >
                    Deletar
                  </ButtonPattern>

                  <ButtonPattern
                    type="button"
                    onClick={this.togModalCreateUpdate}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    name={"btn-close"}
                    action="cancela"
                  >
                    Cancelar
                  </ButtonPattern>
                </div>
              </AvForm>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default BancoConta;