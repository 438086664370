// React
import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import classnames from "classnames";

// Validate
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

// Elements
import { Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Label, Row, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, Table } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Services
import { 
  listPermissoesNewGrupoService, 
  getGrupoPermissaoByIDService, 
  createGrupoPermissaoService 
} from "../../../services/GrupoPermissoesService";
import { getGrupoByIDService, createGrupoService, updateGrupoService } from "../../../services/GrupoService";
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";



class ClienteCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Variável para verificar se é create ou update
      grupoID: this.props.match.params.grupoID || '',

      // Variáveis do cliente
      grupo: '',
      grupoAtivo: true,
      permissoes: [],

      // Controle de tela
      activeTab: "grupo",
      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    const fullPermission = await this.listPemissoesNewGrupo();
  
    
    // Se for update, carregar os dados
    if(this.state.grupoID != '') {
      const responseGrupo = await getGrupoByIDService(this.state.grupoID);
      const responseGrupoPermissao = await this.listPemissoesByGrupo(this.state.grupoID);

      if(responseGrupo.errors.length == 0 && responseGrupoPermissao.errors.length == 0 ) {
        const { grupo } = responseGrupo;
        const grupoPermissoes = responseGrupoPermissao.permissoes;

        // Percorre o array de permissões do grupo e passa para o array de fullPermission
        for (let i in grupoPermissoes) {
          for (let j in fullPermission) {
            if(grupoPermissoes[i].menu_id == fullPermission[j].menu_id) {
              fullPermission[j] = grupoPermissoes[i];
            }
          }
        }

        this.setState({
          grupo: grupo.descricao,
          grupoAtivo: grupo.ativo
        });
      } else {
        this.props.history.push({
          pathname: '/grupoPermissao',
          msgBox: { 
            color: 'danger',
            display: true,
            msg: ['Não foi possível carregar os dados do grupo']
          }
        });
      }
      
    }

    // Ordena as permissões
    if(fullPermission.length > 0) {
      const buildTree = this.buildTree(0, fullPermission); 
      this.setState({permissoes: buildTree})
    }
  }

  // Busca as permissoes para um novo grupo
  async listPemissoesNewGrupo() {
    const response = await listPermissoesNewGrupoService();

    if(response.errors.length == 0)
      return response.permissoes;
    else
      return [];
  }

  // Busca as permissoes do grupo
  async listPemissoesByGrupo(grupoID) {
    const response = await getGrupoPermissaoByIDService(grupoID);

    if(response.errors.length == 0)
      return response;
    else
      return [];
  }

  buildTree = (indice = null, permissoes = [], arrNew = [], arrFilhoDoFilho = []) => {
    if (typeof permissoes[indice] == 'undefined') {
      return arrNew;
    }
  
    // Verifica se a permissão é pai
    if (!permissoes[indice].menu_pai) {
      arrNew.push({
        ...permissoes[indice],
        pai: true,
        nivel: 0,
        principal: true
      });
    } else {
    
      // Procura o menu pai e insere logo após
      for (let i in arrNew) {
        // Verifica se o menu tem um pai
        if (permissoes[indice].menu_pai == arrNew[i].menu_id) {
          let posicao = null;
  
          // Atualizao menu para pai
          arrNew[i].pai = true;
          
          // Verificar se o pai tem mais algum filho
          for (let j in arrNew) {
            // Se o pai é o mesmo / posição do proximo filho
            if (arrNew[j].menu_pai == permissoes[indice].menu_pai && permissoes[indice].ordem_filho > arrNew[j].ordem_filho) {
            // if (arrNew[j].menu_id == permissoes[indice].menu_pai) {
              posicao = parseInt(j)+1;
            }
          }
  
          // Se não encontrar a posição é porque ainda não tem nenhum filho
          if (!posicao) {
            posicao = parseInt(i)+1;
          }
        
          // primeiro parâmetro é a posição / segundo a quantidade que será retirado / terceira o que será inserido
          arrNew.splice(posicao, 0, {
            ...permissoes[indice],
            pai: false,
            nivel: parseInt(arrNew[i].nivel)+1,
            principal: false
          });
        } 
  
        // Verifica se tem algum registro arrFilhoDoFilho/ se houver, procurar no arrNew se já tem pai para algum deles e incrementar
        else if(arrFilhoDoFilho.length > 0) {
          // console.log(arrFilhoDoFilho);
          let posicao = null;
  
          // Verificar se esse filho já tem o pai adicionado 
          for (let i in arrFilhoDoFilho) {
  
            for (let j in arrNew) {
  
              // Encontrou o array pai
              if(arrFilhoDoFilho[i].menu_pai == arrNew[j].menu_id) {
  
                // Atualizao menu para pai
                arrNew[j].pai = true;
  
                // Verifica a posição do ultimo filho desse pai e insere posteriormente
                for(let x in arrNew) {
                  if (arrNew[x].menu_pai == arrFilhoDoFilho[i].menu_pai && arrFilhoDoFilho[i].ordem_filho > arrNew[x].ordem_filho)
                    posicao = parseInt(x)+1;
                }
  
                // Se não encontrar a posição é porque ainda não tem nenhum filho
                if (!posicao) {
                  posicao = parseInt(j)+1;
                }
  
                // primeiro parâmetro é a posição / segundo a quantidade que será retirado / terceira o que será inserido
                arrNew.splice(posicao, 0, {
                  ...arrFilhoDoFilho[i],
                  pai: false,
                  nivel: parseInt(arrNew[j].nivel)+1,
                  principal: false
                });
  
                // Exclui o filho adicionado do array de filhos
                arrFilhoDoFilho.splice(i, 1);
              }  
            }
          }
        }
      }
  
      // Verifica se o menu autal foi adicionado ao arrNew / se não foi adicionar no arrFihoDoFilho
      let achouMenu = false;
      for (let i in arrNew) {
        if(permissoes[indice].menu_id == arrNew[i].menu_id)
          achouMenu = true;
      }
  
      if(!achouMenu) {
        arrFilhoDoFilho.push(permissoes[indice]);
      }
  
    }
  
    // Chama a função novamente
    return this.buildTree(indice + 1, permissoes, arrNew, arrFilhoDoFilho);
  }

  // ALtera estado da navtabs
  toggleIconActive(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  checkActiveParentMenu = () => {

  }

  // Altera o estado das permissões
  toglePermissionActive = (check) => {
    let [typePermission, menu, menuPai, isPai] = check.target.name.split('_') ;
    let desabilitarMenu = [];
    let isChecked = check.target.checked;
    let { permissoes } = this.state;

    // Adiciona no array que ira habilitar/desabilitar os menus
    if(menuPai == 'null')
      desabilitarMenu.push(parseInt(menu))
    else if(menuPai != null && isPai == 'true')
      desabilitarMenu.push(parseInt(menu))

    // Se menu pai habilitar/desabilitar o acesso dos filhos
    if (typePermission == 'acessa') {
      
      // Verificar se o pai do menu clicado está ativo
      for (let i in permissoes) {
        if (permissoes[i].menu_id == menuPai) {
          if(permissoes[i].acessa == 'N')
            isChecked = false;
        }
      }

      for (let i in permissoes) {
        // Se constar no menu desabilitar o menu acessa
        if(desabilitarMenu.indexOf(permissoes[i].menu_pai) > -1) {
          // Verifica se o menu é um menu pai, para adicionar na listagem para habilitar/desabilitar
          if(permissoes[i].pai)
            desabilitarMenu.push(permissoes[i].menu_id);
          
          permissoes[i].acessa = isChecked ? 'S' : 'N';
        }
      }
    }


    // Habilita/desabilita os menus
    for (let i in permissoes) {
      if(permissoes[i].menu_id == menu) {
        switch(typePermission) {
          case 'acessa':
            permissoes[i].acessa = isChecked ? 'S' : 'N';
            break;
          case 'cadastra':
            permissoes[i].cadastra = isChecked ? 'S' : 'N';
            break;
          case 'atualiza':
            permissoes[i].atualiza = isChecked ? 'S' : 'N';
            break;
          case 'exclui':
            permissoes[i].exclui = isChecked ? 'S' : 'N';
            break;
          case 'pesquisa':
            permissoes[i].pesquisa = isChecked ? 'S' : 'N';
            break;
        }
      }
    }
    
    this.setState({permissoes});
  }

  // Altera o estado do grupo ativo
  togleGrupoActive = (check) => {
    const isChecked = check.target.checked;
    this.setState({grupoAtivo: isChecked});
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {  
    let field = event.target.name

    // Atualiza Valores do state
    this.setState({[field]: event.target.value});
  }

  // Faz o submit no form 
  handleSubmit = async (event, errors, values) => {
    const grupoID = this.state.grupoID;

    // Pega o erro que vem do form
    if (errors.length == 0) {   

      // Variáveis de retorno
      let errors = [];
      let responseGrupo;
      let responseGrupoPermissoes;

      // Dados do grupo
      const grupoParams = {
        descricao: this.state.grupo,
	      ativo: this.state.grupoAtivo
      }

      // Verifica se é create ou update do cliente
      if(grupoID != '')
        responseGrupo = await updateGrupoService(grupoID, grupoParams);
      else
        responseGrupo = await createGrupoService(grupoParams);

      // Verifica se ocorreu erro no cadastro do responseGrupo
      if (responseGrupo.errors.length == 0) { 
        const grupoIDCurrent = grupoID != '' ? grupoID : responseGrupo.grupo.id;

        const permissoesParams = {
          grupo_id : grupoIDCurrent,
          permissoes: this.state.permissoes
        }

        // Cadastra/atualiza as permissões
        responseGrupoPermissoes = await createGrupoPermissaoService(permissoesParams);
        if(responseGrupoPermissoes.errors.length > 0) {
          responseGrupoPermissoes.errors.forEach(msg => {
            errors.push(msg.message)
          });
        }

      } else {
        responseGrupo.errors.forEach(msg => {
          errors.push(msg.message)
        });
      }

      //Verifica se ocorreu algum erro
      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }})
      } else {
        const msgSuccess = grupoID != '' ? 'Grupo e permissões atualizados com sucesso' : 'Grupo e permissões cadastrados com sucesso';
        this.props.history.push({
          pathname: '/grupoPermissao',
          msgBox: { 
            color: 'success',
            display: true,
            msg: [msgSuccess]
          }
        })  
      }
    }
  
    return true;
  }


  render() {
    const { permissoes, grupo, grupoID } = this.state;
    const space = '\u00A0\u00A0\u00A0\u00A0'; // 4 espaços

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Cliente" breadcrumbItem={grupoID != '' ? 'Update' : 'Create'} />

            <Alert color={this.state.msgBox.color} isOpen={this.state.msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { this.state.msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardBody>
                <CardTitle className="h4">{grupoID != '' ? 'Atualização do' : 'Cadastro do'} Grupo Permissão</CardTitle>
                <p className="card-title-desc">Tela utilizada para realizar {grupoID != '' ? 'a atualização' : 'o cadastro'}  do grupo permissão</p>

                <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "grupo",
                        })}
                        onClick={() => {
                          this.toggleIconActive("grupo")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-users"></i> Grupo</span>
                        <span className="d-block d-sm-none"><i className="fas fa-users"></i></span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "permissoes",
                        })}
                        onClick={() => {
                          this.toggleIconActive("permissoes")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-user-lock"></i> Permissões</span>
                        <span className="d-block d-sm-none"><i className="fas fa-user-lock"></i></span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    <TabPane tabId="grupo">
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <AvGroup>
                                <Label htmlFor="grupo">Grupo <span className="text-danger" >*</span></Label>
                                <AvField
                                  value={grupo}
                                  type="text"
                                  className="form-control"
                                  name={"grupo"}
                                  id="grupo"
                                  onChange={this.handleChange}
                                  validate={{
                                    required: {value: true, errorMessage: 'Preenchimento Obrigatório' },
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row style={{marginTop: '15px'}}>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <div className="form-check form-check-primary mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor1"
                                  checked={this.state.grupoAtivo}
                                  onChange={this.togleGrupoActive}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheckcolor1"
                                >
                                  Ativo
                                </label>
                              </div>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="permissoes">
                      <Row>
                        <Col sm="12">
                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>Lista de Menus</th>
                                  <th style={{textAlign: 'center'}}>Acessa</th>
                                  <th style={{textAlign: 'center'}}>Cadastra</th>
                                  <th style={{textAlign: 'center'}}>Atualiza</th>
                                  <th style={{textAlign: 'center'}}>Exclui</th>
                                  <th style={{textAlign: 'center'}}>Pesquisa</th>
                                </tr>
                              </thead>
                              <tbody>
                                {permissoes.map(menu => {
                                  if(menu.mostra_menu == 'S') {
                                    return (
                                      <tr key={menu.menu_id}>
                                        <td style={{fontWeight: menu.pai && 'bold'}}>
                                          <span>{space.repeat(menu.nivel)}</span> {menu.descricao}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name={`acessa_${menu.menu_id}_${menu.menu_pai}_${menu.pai}`}
                                            checked={menu.acessa == 'S' ? true: false}
                                            onChange={this.toglePermissionActive}
                                          />
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                          {!menu.pai &&
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              name={`cadastra_${menu.menu_id}_${menu.menu_pai}_${menu.pai}`}
                                              checked={menu.cadastra == 'S' ? true: false}
                                              onChange={this.toglePermissionActive}
                                            />
                                          }
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                          {!menu.pai &&
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              name={`atualiza_${menu.menu_id}_${menu.menu_pai}_${menu.pai}`}
                                              checked={menu.atualiza == 'S' ? true: false}
                                              onChange={this.toglePermissionActive}
                                            />
                                          }
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                          {!menu.pai &&
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              name={`exclui_${menu.menu_id}_${menu.menu_pai}_${menu.pai}`}
                                              checked={menu.exclui == 'S' ? true: false}
                                              onChange={this.toglePermissionActive}
                                            />
                                          }
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                          {!menu.pai &&
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              name={`pesquisa_${menu.menu_id}_${menu.menu_pai}_${menu.pai}`}
                                              checked={menu.pesquisa == 'S' ? true: false}
                                              onChange={this.toglePermissionActive}
                                            />
                                          }
                                        </td>
                                      </tr>
                                    ) 
                                  }
                                })}
                              </tbody>
                             
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>

                  <Row>                    
                    <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                      <ButtonPattern 
                        type="submit" 
                        style={{ marginRight: "10px" }}
                        className="btn btn-success"
                        action={grupoID == '' ? 'cadastra' : 'atualiza'}
                      >
                        Salvar
                      </ButtonPattern>
                      <LinkPattern 
                        to={`/grupoPermissao`} 
                        className="btn btn-secondary"
                        action="cancela"
                      >
                        Cancelar
                      </LinkPattern>
                    </Col>
                  </Row>

                </AvForm>
              </CardBody>
            </Card>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteCreate;