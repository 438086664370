import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissoes: JSON.parse(localStorage.getItem("userPermission")) != null ? JSON.parse(localStorage.getItem("userPermission")) : []
    }
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  buildTree = (permissoes = [], menuPai = null, indiceStop = 0) => {
   
    if(indiceStop == permissoes.length)
      return;

    if(menuPai != null) {
      return permissoes.map(menu => {
        // Percorre apenas os menus que são filhos 
        if (menu.menu_pai == menuPai) {

          // Menu pai
          if (menu.pai) {
            return (
              <div key={menu.menu_id} className="dropdown">
                <Link
                  to="/#"
                  className="dropdown-item dropdown-toggle arrow-none"
                  onClick={e => {e.preventDefault()}}
                >
                  {this.props.t(menu.descricao)} <div className="arrow-down" />
                </Link>
                <div
                  className={classname("dropdown-menu")}
                >
                  {this.buildTree(permissoes, menu.menu_id, indiceStop + 1)}
                </div>
              </div>
            )
          } else {
            return(
              <Link key={menu.menu_id} to={`/${menu.path}`} className="dropdown-item">
                {this.props.t(menu.descricao)}
              </Link>
            )
          }

        } 
      })

    } else {
      return permissoes.map(menu => {
        // Menu pai e principal
        if(menu.menu_pai == null && menu.principal && menu.mostra_menu == 'S'  && menu.acessa == 'S') {
          return(
            <li key={menu.menu_id} className="nav-item dropdown">
              <Link
                to="/#"
                onClick={e => {e.preventDefault()}}
                className="nav-link dropdown-toggle arrow-none"
              >
                <i className={`${menu.icone} me-2`} />
                {this.props.t(menu.descricao)} <div className="arrow-down" />
              </Link>
              <div
                className={classname("dropdown-menu")}
              >
                {permissoes.map(menuFilho => {
                  // Verifica se o menu percorrido é filho
                  if (menu.menu_id == menuFilho.menu_pai && menuFilho.mostra_menu == 'S'  && menuFilho.acessa == 'S') {

                    // Menu filho tambem é pai
                    if (menuFilho.pai) {

                      return(
                        <div key={menuFilho.menu_id} className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {e.preventDefault()}}
                          >
                            {this.props.t(menuFilho.descricao)} <div className="arrow-down" />
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.emailState,
                            })}
                          >
                            {this.buildTree(permissoes, menuFilho.menu_id, indiceStop + 1)}
                          </div>
                        </div>
                      )
                    } 

                    // Menu é filho
                    else {
                      return(
                        <Link key={menuFilho.menu_id} to={`/${menuFilho.path}`} className="dropdown-item">
                          {this.props.t(menuFilho.descricao)}
                        </Link>
                      )
                    }
                  }
                })}

              </div>
            </li>
          )
        }
      })
    }
  }

  render() {
    const { permissoes } = this.state;

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {this.buildTree(permissoes)}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
