// React
import React, { Component } from "react";

// Elements
import { 
  Alert, 
  Card, 
  CardBody, 
  CardHeader, 
  Col, 
  Container, 
  FormGroup,
  Input, 
  Label, 
  Row 
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";

// Components
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";
import { Loading } from "components/Loading";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Helpers
import { formatMoney } from "../../../helpers/Functions";

// Services
import { searchClienteService } from '../../../services/ClienteService';
import { searchProdutoServicoService } from "../../../services/ProdutoServicoService";
import { 
  createClienteRecorrenciaService, 
  getClienteRecorrenciaByIDService, 
  updateClienteRecorrenciaService 
} from '../../../services/ClienteRecorrenciaService';



class ClienteRecorrenciaForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Variável para verificar se é create ou update
      clienteRecorrenciaID: this.props.match.params.clienteRecorrenciaID || '',
            
      cliente: '',
      produtoServico: '',
      diaVencimento: '',
      valorMensalidade: '',
      valorTransacao: '',
      transacaoProcessa: 'S',
      clientes: [],
      produtoServicos: [],
      consultaRealizada: false,

      // Select2 selecionado
      selectCliente: '',

      msgBox: {
        color:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.color : 'sucess'),
        display: (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.display : false),
        msg:  (typeof props.location.msgBox != 'undefined' ? props.location.msgBox.msg : [])
      },
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    const { clienteRecorrenciaID } = this.state;

    await this.listProdutoServicos();

    // Se for update, carregar os dados dos clientes
    if(clienteRecorrenciaID != '') {
      const response = await getClienteRecorrenciaByIDService(clienteRecorrenciaID);

      if(response.errors.length == 0) {
        const { 
          clienteRecorrencia : {
            cliente_id,
            dia_vencimento,
            produto_servico_id,
            transacao_processa,
            transacao_tarifa,
            valor_mensalidade,
            valor_transacao,
            cliente
        }
        } = response;

        this.setState({
          cliente: cliente_id,
          produtoServico: produto_servico_id,
          diaVencimento: dia_vencimento,
          valorMensalidade: formatMoney(valor_mensalidade),
          valorTransacao: formatMoney(valor_transacao),
          transacaoProcessa: transacao_processa ? 'S' : 'N',
          selectCliente: {
            label: cliente.nome, 
            value: cliente.id 
          }
        });
      } else {
        let errors = [];
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });

        this.props.history.push({
          pathname: '/clienteRecorrencia',
          msgBox: { 
            color: 'danger',
            display: true,
            msg: errors
          }
        });
      }
    }
  }

  // Busca a listagem de produto / serviço
  listProdutoServicos = async () => {
    const response = await searchProdutoServicoService();
    if(response.errors.length == 0)
      this.setState({produtoServicos: response.produtoServicos});
    else
      this.setState({produtoServicos: []});
  }

  // Faz a consulta do cliente pela descricao
  searchClientes = async (cliente) => {
    const clientes = await searchClienteService({ftr_nome: cliente});
    return clientes;
  }

  // Faz o filtro da consulta no select 2 do clinte
  handleSelectCliente = (cliente) => {
    if(cliente.length > 2) {

      this.searchClientes(cliente).then(response => {
        const clientes = response.clientes.map(cliente => {
          return {
            label: `${cliente.nome}`, value: cliente.id 
          }
        })
        this.setState({clientes})
      })
    }
  }

  // Altera o estados das variáveis para o select do cliente
  handleChangeCliente = (event) => {
    this.setState({
      cliente: event.value,
      selectCliente: event // Mostra o select pressionado
    });
  }
  
  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {
    let field = event.target.name;

    switch(field) {
      case 'valorMensalidade':
      case 'valorTransacao':
        this.setState({[field]: formatMoney(event.target.value)});
        break;
      case 'produtoServico':
        const index = event.target.selectedIndex;
        const optionElement = event.target.childNodes[index]
        const valorPadrao =  optionElement.getAttribute('data-valor-padrao');
  
        this.setState({
          [field]: event.target.value,
          valorMensalidade: valorPadrao
        });
        break;
      default:
        this.setState({[field]: event.target.value});
        break;
    }   
  }
   
  // Faz o submit no form 
  handleSubmit = async (event) => { 
    this.setState({
      produtoServico: document.getElementById("produtoServico").value,
      isLoading: true
    });

    // Variáveis
      const { 
        cliente,
        clienteRecorrenciaID,
        diaVencimento,
        produtoServico,
        transacaoProcessa,
        valorMensalidade,
        valorTransacao
      } = this.state;
      let errors = [];
      let validationsError = [];
      let responseClienteRecorrencia;
    //---

    // validations
      if(!cliente) {
        validationsError.push('Cliente é de preenchimento Obrigatório')
      }

      if(!produtoServico) {
        validationsError.push('Produto/Serviço é de preenchimento Obrigatório')
      }

      if(!valorMensalidade) {
        validationsError.push('Valor da mensalidade é de preenchimento Obrigatório')
      }

      if(!valorTransacao) {
        validationsError.push('Valor da transação é de preenchimento Obrigatório')
      }

      if(!diaVencimento) {
        validationsError.push('Dia vencimento é de preenchimento Obrigatório')
      }

      if(!transacaoProcessa) {
        validationsError.push('Processa transação é de preenchimento Obrigatório')
      }
    //---

    if(validationsError.length > 0) {
      this.setState({
        msgBox: {
          color: 'danger', 
          display: true, 
          msg: validationsError 
        },
        isLoading: false
      });
      event.preventDefault();
    } else {

      // Dados cliente 
      const clienteRecorrenciaParams = {
        cliente_id: cliente,
        produto_servico_id: produtoServico,
        dia_vencimento: diaVencimento,
        transacao_processa: (transacaoProcessa == 'S' ? true : false),
        valor_mensalidade: valorMensalidade.replace('.', '').replace(',','.'),
        valor_transacao: valorTransacao.replace('.', '').replace(',','.')
      };

      // Verifica se é create ou update do cliente recorrência
      if(clienteRecorrenciaID != '')
        responseClienteRecorrencia = await updateClienteRecorrenciaService(clienteRecorrenciaID, clienteRecorrenciaParams);
      else
        responseClienteRecorrencia = await createClienteRecorrenciaService(clienteRecorrenciaParams);

      // Verifica se ocorreu erro no cadastro do responseCliente
      if(responseClienteRecorrencia.errors.length == 0) {

        const msgSuccess = clienteRecorrenciaID != '' ? 'Cliente recorrência atualizado com sucesso' : 'Cliente recorrência cadastrado com sucesso';
        this.props.history.push({
          pathname: '/clienteRecorrencia',
          msgBox: { 
            color: 'success',
            display: true,
            msg: [msgSuccess]
          }
        });
        
      } else {
        responseClienteRecorrencia.errors.forEach(msg => {
          errors.push(msg.message)
        });
        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: errors 
          },
          isLoading: false
        });
        event.preventDefault();
      }
    }
  }
  
  render() {
    // Variáveis
      const {
        clientes,
        clienteRecorrenciaID, 
        diaVencimento,
        isLoading,
        msgBox,
        produtoServico,
        produtoServicos, 
        selectCliente,
        valorMensalidade,
        valorTransacao,
      } = this.state;
    //---

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <Loading isShow={isLoading} />
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cliente Recorrência" breadcrumbItem="Create" />
            <Row>
              <Col xl={12}>
                <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
                  <ul>
                    { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
                  </ul>
                </Alert>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Cadastro clientes recorrência</h4>
                    <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div className="mb-3 select2-container">
                            <Label>Clientes<span className="text-danger" >*</span></Label>
                            <Select
                              placeholder="Mínimo 3 caracteres para consulta"
                              classNamePrefix="select2-selection"
                              onInputChange={this.handleSelectCliente}
                              onChange={this.handleChangeCliente}
                              options={clientes}
                              value={selectCliente}
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="produtoServico">Produto / Serviço<span className="text-danger" >*</span></Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="produtoServico" 
                              id="produtoServico" 
                              onChange={this.handleChange}
                              value={produtoServico}
                            >
                              {produtoServicos.map(produtoServico => {
                                return (
                                  <option 
                                    key={produtoServico.id}
                                    value={produtoServico.id} 
                                    data-valor-padrao={formatMoney(produtoServico.valor_padrao)}
                                  >
                                    {produtoServico.nome}
                                  </option>
                                )
                              })}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="valorMensalidade">Valor Mensalidade<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="valorMensalidade"
                              name="valorMensalidade"
                              onChange={this.handleChange}
                              value={valorMensalidade}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormGroup>
                            <Label htmlFor="valorTransacao">Valor Transação<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="valorTransacao"
                              name="valorTransacao"
                              onChange={this.handleChange}
                              value={valorTransacao}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                          <FormGroup>
                            <Label htmlFor="diaVencimento">Dia vencimento<span className="text-danger" >*</span></Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="diaVencimento"
                              name="diaVencimento"
                              maxLength="2"
                              onChange={this.handleChange}
                              value={diaVencimento}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                          <FormGroup>
                            <Label htmlFor="transacaoProcessa">Processa transação<span className="text-danger" >*</span></Label>
                            <select
                              type="select"
                              className="form-select" 
                              name="transacaoProcessa" 
                              id="transacaoProcessa" 
                              onChange={this.handleChange}
                              value={this.state.transacaoProcessa}
                              validate={{
                                required: {value: true, errorMessage: 'Preenchimento Obrigatório' }
                              }}
                            >
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                          <ButtonPattern 
                            type="submit" 
                            style={{ marginRight: "10px" }}
                            className={'btn btn-success'}
                            action={clienteRecorrenciaID == '' ? 'cadastra' : 'atualiza'}
                          >
                            Salvar
                          </ButtonPattern>
                          <LinkPattern 
                            to={`/clienteRecorrencia`} 
                            className="btn btn-secondary"
                            action="cancela"
                          >
                            Cancelar
                          </LinkPattern>
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteRecorrenciaForm;
