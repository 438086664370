// react
import React, { Component } from "react";
import classnames from "classnames";

// elements
import { 
  Alert, 
  Card, 
  CardTitle, 
  CardBody, 
  Col, 
  Container, 
  FormGroup,
  Input, 
  Label, 
  Nav, 
  NavItem, 
  NavLink, 
  Row, 
  TabContent, 
  TabPane, 
  Table 
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Select from "react-select";

// components
import { ButtonPattern } from "components/ButtonPattern";
import { LinkPattern } from "components/LinkPattern";
import { Loading } from "components/Loading";


// import breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// helpers
import { 
  formatCpfCnpj,
  formatPhone,
  formatZipCode,
  validateCpfCnpj,
  validateRegex
} from '../../../helpers/Functions/index';

// services
import { 
  createClienteService, 
  getClienteByIDService, 
  updateClienteService 
} from "../../../services/ClienteService";
import { 
  createClienteEnderecoService, 
  updateClienteEnderecoService 
} from '../../../services/ClienteEnderecoService';
import { 
  createClienteContatoService, 
  updateClienteContatoService 
} from '../../../services/ClienteContatoService';
import { searchCep } from "../../../services/CepService";
import { searchParceiroService } from "../../../services/ParceiroService";
import { 
  searchMunicipioService, 
  searchMunicipioByIDService 
} from "../../../services/MunicipioService";
import { searchProdutoServicoService } from "../../../services/ProdutoServicoService";
import { 
  createClienteProdutoServicoService, 
  deleteClienteProdutoServicoService,
  updateClienteProdutoServicoService 
} from "../../../services/ClienteProdutoServicoService";



class ClienteCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Variável para verificar se é create ou update
      clienteID: this.props.match.params.clienteID || '',

      // Variáveis do cliente
      cli_parceiroID: '',
      cli_ativo: 'A',
      cli_nome: '',
      cli_razaoSocial: '',
      cli_cpfCnpj: '',

      // Variáveis do cliente endereço
      end_cep: '',
      end_logradouro: '',
      end_numero: '',
      end_bairro: '', 
      end_complemento: '',
      end_municipio: '',
      end_status: 'A',
      end_principal: 'S',

      // Variávies do cliente contato
      con_status: 'A',
      con_principal: 'S',
      con_tipo: 'E',
      con_valor: '',

      // Variáveis de cliente produto/serviço
      cli_produtoServico: '',
      cli_sistemaID: '',

      // Controle de Arrays
      clienteEnderecos: [],
      clienteContatos: [],
      clienteProdutoServico: [],

      // Carregamento de Select
      parceiros: [],
      municipios: [],
      produtoServicos:[],


      // Select2 selecionado
      selectMunicipio: '',

      // Indices edição / exclusão
      editandoEndereco: false,
      editandoContato: false,
      editandoProdutoServico: false,
      indiceEdicaoEndereco: null,
      indiceEdicaoContato: null,
      indiceEdicaoProdutoServico: null,

      isLoading: false,

      // Controle de tela
      activeTab: "clienteDadosPessoais",
      msgBox: {
        color: 'success',
        display: false,
        msg: []
      },
    }
  }

  // Executa após carregar a tela
  async componentDidMount() {
    // variáveis
      const { clienteID } = this.state;
    //---

    await this.listParceiros();
    this.listProdutoServicos();

    // Se for update, carregar os dados dos clientes
    if(clienteID != '') {
      const response = await getClienteByIDService(clienteID);

      if(response.errors.length == 0) {
        const { 
          cliente : {
            parceiro_id,
            ativo,
            nome,
            razao_social,
            cpf_cnpj,
            clienteContatos, 
            clienteEnderecos,
            clienteProdutoServicos
         }
        } = response;

        // Formata o array de cliente produto serviço
        const arrClienteProdutoServico = clienteProdutoServicos.map(clienteProdutoServico => {
          return {
            id: clienteProdutoServico.id,
            produto_servico_descricao: clienteProdutoServico.produtoServico.nome,
            produto_servico_id: clienteProdutoServico.produto_servico_id,
            sistema_id: clienteProdutoServico.sistema_id,
            exclui: false
          }
        });
      
        this.setState({
          cli_parceiroID: parceiro_id,
          cli_ativo: ativo ? 'A' : 'I',
          cli_nome: nome,
          cli_razaoSocial: razao_social,
          cli_cpfCnpj: formatCpfCnpj(cpf_cnpj),
          clienteEnderecos: clienteEnderecos,
          clienteContatos: clienteContatos,
          clienteProdutoServico: arrClienteProdutoServico
        });
      } else {
        let errors = [];
        response.errors.forEach(msg => {
          errors.push(msg.message)
        });

        this.props.history.push({
          pathname: '/cliente',
          msgBox: { 
            color: 'danger',
            display: true,
            msg: errors
          }
        });
      }
    }
  }

  // Busca a listagem de produto / serviço
  listProdutoServicos = async () => {
    const response = await searchProdutoServicoService();
    if(response.errors.length == 0)
      this.setState({produtoServicos: response.produtoServicos});
    else
      this.setState({produtoServicos: []});
  }

  // Busca a listagem de parceiros
  listParceiros = async () => {
    const response = await searchParceiroService();
    if(response.errors.length == 0)
      this.setState({parceiros: response.parceiros});
    else
      this.setState({parceiros: []});
  }

  // Faz a consulta do município pelo ID
  searchMunicipioByID = async (municipioID) => {
    const municipio = await searchMunicipioByIDService(municipioID);
    return municipio;
  }

  // Faz a consulta do município pela descricao
  searchMunicipios = async (ftr_municipio) => {
    const municipios = await searchMunicipioService(ftr_municipio);
    return municipios;
  }

  // Preenche o select 2
  fillSelectMunicipio = async (municipioID) => {
    const response = await this.searchMunicipioByID(municipioID);
    
    if(response.errors.length == 0) {
      const municipio = response.municipio;
      const selectMunicipio = {
        label: `${municipio.id} - ${municipio.municipio}/${municipio.uf}`, 
        value: municipio.id 
      }
      this.setState({selectMunicipio});
    }
  }

  // Faz o filtro da consulta no select 2 do município
  handleSelectMunicipio = (ftr_municipio) => {
    if(ftr_municipio.length > 2) {

      this.searchMunicipios(ftr_municipio).then(response => {
        const municipios = response.municipios.map(municipio => {
          return {
            label: `${municipio.id} - ${municipio.municipio}/${municipio.uf}`, value: municipio.id 
          }
        })
        this.setState({municipios})
      })
    }
  }

  // Busca o CEP
  searchCep = async (event) => {
    const cep = event.target.value.replace(/\D/gim, '');
    
    if(cep.length < 8) {
      this.setState({
        end_cep: '',
        msgBox: {color: 'danger', display: true, msg: ['Cep não inválido'] }
      });
      return true;
    }

    this.setState({isLoading: true});
    const endereco = await searchCep(cep);
    
    if(endereco.retorno == 0)
      this.setState({
        end_cep: '',
        msgBox: {color: 'danger', display: true, msg: ['Cep não encontrado']},
        isLoading: false
      })
    else {
      this.setState({
        end_logradouro: endereco.logradouro,
        end_bairro: endereco.bairro,
        end_municipio: endereco.localidade_codsiafi,
        isLoading: false
      });

      this.fillSelectMunicipio(endereco.localidade_codsiafi); // chama para buscar o municipio pelo ID e atualziar o select 2
    }
  }

  // Altera o estados das variáveis para o select do município
  handleChangeMunicipio = (event) => {
    this.setState({
      end_municipio: event.value,
      selectMunicipio: event // Mostra o select pressionado
    });
  }

  // ALtera Estado da troca dos itens
  toggleIconActive(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  // Altera tipo
  toggleSelectTipo = (event) => { 
    this.setState({
      con_valor: '',
      con_tipo: event.target.value
    });
  }

  // Atualiza o estado da variável conforme o usuário vai digitando
  handleChange = (event) => {  
    const field = event.target.name;
    
    switch (field) {
      case 'cli_cpfCnpj':
        this.setState({[field]: formatCpfCnpj(event.target.value)});
        break;
      case 'con_valor':
        const { con_tipo } = this.state;
        if(con_tipo == 'T')
          this.setState({[field]: formatPhone(event.target.value)});
        else
          this.setState({[field]: event.target.value});
        break;
      case 'end_cep':
        this.setState({[field]: formatZipCode(event.target.value)});
        break;
      default:
        this.setState({[field]: event.target.value});
        break;
    }
  }

  handleBlur = (event) => {  
    let field = event.target.name;
    const { 
      cli_nome,
      cli_cpfCnpj 
    } = this.state;
    
    
    if(field == 'cli_cpfCnpj') {
      if(!validateCpfCnpj(cli_cpfCnpj)) {
        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: ['O CPF/CNPJ digitado é inválido']
          },
          cli_cpfCnpj: ''
        });
      }
    }

    if(field == 'cli_nome') { 
      if(!validateRegex(cli_nome, /[a-z]* [a-z]*/)) {
        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: ['O nome deve ser composto. Ex: Caio Costa']
          },
          cli_nome: ''
        });
      }
    }
  }

  // Adiciona novo endereço na listagem
  addEndereco = (event) => {
    // veriáveis
      const {
        clienteEnderecos,
        end_cep, 
        end_logradouro,
        end_numero,
        end_bairro,
        end_municipio,
        end_principal,
        end_complemento,
        end_status,
        selectMunicipio
      } = this.state;
      let errors = [];
    //---


    const validator = {
      cep: end_cep,
      logradouro: end_logradouro,
      numero: end_numero,
      bairro: end_bairro,
      municipio: end_municipio,
    };
    
    // Verifica se todas as variáveis do estado estão preenchidas
      for(let i in validator) {
        if(validator[i] == '' || validator[i] == null) {
          errors.push(`O campo '${i}' deve ser preenchido.`);
        }
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---

    // Adiciona no array de endereços
      let enderecosOld = [ ...clienteEnderecos ];
      let enderecosNew = [];

      // Altera todos os outros endereços para não principal
        for(let i in enderecosOld) {

          if(end_principal == 'S')
            enderecosOld[i].principal = false;
          
          enderecosNew.push(enderecosOld[i]);
        }
      
      // Adiciona o novo endereço
      enderecosNew.push({
        ativo: (end_status == 'A' ? true : false),
        principal: (end_principal == 'S' ? true : false),
        cep: end_cep.replace(/\D/gim, ''),
        logradouro: end_logradouro,
        numero: end_numero,
        bairro: end_bairro,
        complemento: end_complemento,
        municipio_id: end_municipio,
        municipio: {
          municipio: selectMunicipio.label
        }
      });

      // Adiciona os novos estados
      this.setState({
        end_cep: '',
        end_logradouro: '',
        end_numero: '',
        end_bairro: '', 
        end_complemento: '',
        end_municipio: '',
        end_status: 'A',
        end_principal: 'S',
        selectMunicipio: '',
        clienteEnderecos: enderecosNew
      });
    //---
    return true;
  }

  // Exclui o endereço
  excluiEndereco = (event) => {
    const { clienteEnderecos } = this.state;

    const indice = event.target.getAttribute("data-indice");
    let enderecos = [...clienteEnderecos];
    const enderecoExclusao = enderecos[indice];
    
    if(typeof enderecoExclusao != 'undefined') {
      if(confirm(`Deseja excluir o endereço: \n ${enderecoExclusao.cep} - ${enderecoExclusao.logradouro}, nº ${enderecoExclusao.numero} - ${enderecoExclusao.bairro} - ${enderecoExclusao.municipio.municipio} `)) {

        // Verificar se existe ID, apenas mudar status para false
        if(typeof enderecoExclusao.id != 'undefined')
          enderecos[indice].ativo = false;
        else
          enderecos.splice(indice, 1);
        
        const enderecosNew = enderecos.map(endereco => endereco);
        this.setState({clienteEnderecos: enderecosNew})
      }
    }
  }

  // Edita o endereço
  editaEndereco = (indice) => {
    const { clienteEnderecos } = this.state;

    let enderecos = [...clienteEnderecos];
    const enderecoEdicao = enderecos[indice];
    this.setState({
      end_cep: enderecoEdicao?.cep || '',
      end_logradouro: enderecoEdicao?.logradouro || '',
      end_numero: enderecoEdicao?.numero || '',
      end_bairro: enderecoEdicao?.bairro || '', 
      end_complemento: enderecoEdicao?.complemento || '',
      end_municipio: enderecoEdicao?.municipio_id || '',
      end_status: (enderecoEdicao?.ativo ? 'A' : 'I'),
      end_principal: (enderecoEdicao?.principal ? 'S' : 'N'),
      editandoEndereco: true,
      indiceEdicaoEndereco: indice
    });

    if(typeof enderecoEdicao?.municipio_id != 'undefined')
      this.fillSelectMunicipio(enderecoEdicao.municipio_id); // chama para buscar o municipio pelo ID e atualziar o select 2
  }

  // Salva a edição do endereço
  salvaEdicaoEndereco = (event) => {
    // veriáveis
    const {
      clienteEnderecos,
      end_cep, 
      end_logradouro,
      end_numero,
      end_bairro,
      end_municipio,
      end_principal,
      end_complemento,
      end_status,
      indiceEdicaoEndereco,
      selectMunicipio
    } = this.state;
    const indice = indiceEdicaoEndereco;
    let errors = [];
  //---

    const validator = {
      cep: end_cep,
      logradouro: end_logradouro,
      numero: end_numero,
      bairro: end_bairro,
      municipio: end_municipio,
    };

    // Verifica se todas as variáveis do estado estão preenchidas
      for(let i in validator) {
        if(validator[i] == '' || validator[i] == null) {
          errors.push(`O campo '${i}' deve ser preenchido.`);
        }
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---

    // Edita o array no array de endereços
      let enderecos = [ ...clienteEnderecos ];

        // Altera todos os outros endereços para não principal
        for(let i in enderecos) {
          if(end_principal == 'S') {
            enderecos[i].principal = false;
          }
        }
      
      // Altera os dados do endereço
      enderecos[indice].ativo = (end_status == 'A' ? true : false);
      enderecos[indice].principal =  (end_principal == 'S' ? true : false);
      enderecos[indice].cep = end_cep.replace(/\D/gim, '');
      enderecos[indice].logradouro = end_logradouro;
      enderecos[indice].numero = end_numero;
      enderecos[indice].bairro = end_bairro;
      enderecos[indice].complemento = end_complemento;
      enderecos[indice].municipio_id = end_municipio;
      enderecos[indice].municipio = {
        municipio: selectMunicipio.label
      };

      // Adiciona os novos estados
      this.setState({
        end_cep: '',
        end_logradouro: '',
        end_numero: '',
        end_bairro: '', 
        end_complemento: '',
        end_municipio: '',
        end_status: 'A',
        end_principal: 'S',
        selectMunicipio: '',
        clienteEnderecos: enderecos,
        editandoEndereco: false,
        indiceEdicaoEndereco: null
      });
    //---
    return true;
  }

  // Cancela edição endereço
  cancelaEdicaoEndereco = (event) => {
    this.setState({
      end_cep: '',
      end_logradouro: '',
      end_numero: '',
      end_bairro: '', 
      end_complemento: '',
      end_municipio: '',
      end_status: 'A',
      end_principal: 'S',
      editandoEndereco: false,
      selectMunicipio: '',
      indiceEdicaoEndereco: null
    });
  }

  // Adiciona novo contato na listagem
  addContato = (event) => {
    // variáveis
      const { 
        con_valor, 
        con_tipo,
        con_principal,
        con_status,
        clienteContatos
      } = this.state;
      let errors = [];
    //---


    // Verifica se todas as variáveis do estado estão preenchidas
      if(con_valor == '') {
        errors.push(`O campo 'valor' deve ser preenchido.`);
      } 

      if(con_tipo == 'E') {
        if(!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(con_valor))
          errors.push(`O campo 'valor' contém um e-mail inválido.`);
      } else {
        if(con_valor.replace(/\D/gim, '').length < 10)
          errors.push(`O campo 'valor' contém um telefone inválido.`);
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---

    // Adiciona no array de contatos
      let contatosOld = [ ...clienteContatos ];
      let contatosNew = [];

      // Altera todos os outros contatos para não principal
        for(let i in contatosOld) {
          if(con_principal == 'S' && contatosOld[i].tipo == con_tipo)
            contatosOld[i].principal = false;

          contatosNew.push(contatosOld[i]);
        }
      
      // Adiciona o novo endereço
      contatosNew.push({
        ativo: (con_status == 'A' ? true : false),
        principal: (con_principal == 'S' ? true : false),
        tipo: con_tipo,
        valor: (con_tipo == 'E' ? con_valor : con_valor.replace(/\D/gim, ''))
      });

      // Adiciona os novos estados
      this.setState({
        con_status: 'A',
        con_principal: 'S',
        con_tipo: 'E',
        con_valor: '',
        clienteContatos: contatosNew
      });
    //---
    return true;
  }

  // Exclui o contato
  excluiContato = (event) => {
    const { clienteContatos } = this.state;

    const indice = event.target.getAttribute("data-indice");
    let contatos = [...clienteContatos];
    const contatoExclusao = contatos[indice];

    if(typeof contatoExclusao != 'undefined') {
      if(confirm(`Deseja excluir o contato: \n ${contatoExclusao.valor}`)) {

        // Verificar se existe ID, apenas mudar status para false
        if(typeof contatoExclusao.id != 'undefined')
          contatos[indice].ativo = false;
        else
          contatos.splice(indice, 1);

        const contatosNew = contatos.map(contato => contato);
        this.setState({clienteContatos: contatosNew})
      }
    }
  }

  // Edita o contato
  editaContato = (indice) => {
    const { clienteContatos } = this.state;

    let contatos = [...clienteContatos];
    const contatoEdicao = contatos[indice];

    this.setState({
      con_status: (contatoEdicao?.ativo ? 'A' : 'I'),
      con_principal: (contatoEdicao?.principal ? 'S' : 'N'),
      con_tipo: contatoEdicao?.tipo,
      con_valor: contatoEdicao?.valor,
      editandoContato: true,
      indiceEdicaoContato: indice
    });
  }

  // Salva a edição do contato
  salvaEdicaoContato = (event) => {
    // Variáveis
      const { 
        con_valor,
        con_tipo,
        con_principal,
        con_status,
        clienteContatos,
        indiceEdicaoContato 
      } = this.state;
    //---
    
    const indice = indiceEdicaoContato;
    let errors = [];

    // Verifica se todas as variáveis do estado estão preenchidas
      if(con_valor == '') {
        errors.push(`O campo 'valor' deve ser preenchido.`);
      } 

      if(con_tipo == 'E') {
        if(!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(con_valor))
          errors.push(`O campo 'valor' contém um e-mail inválido.`);
      } else {
        if(con_valor.length < 11)
          errors.push(`O campo 'valor' contém um telefone inválido.`);
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---

    // Edita o array de contatos
      let contatos = [ ...clienteContatos ];

      // Altera todos os outros contatos para não principal
      for(let i in contatos) {
        if(con_principal == 'S' && con_tipo == contatos[i].tipo) {
          contatos[i].principal = false;
        }
      }

      // console.log(this.state.clienteContatos);
    
      // Altera os dados do contato
      contatos[indice].ativo = (con_status == 'A' ? true : false);
			contatos[indice].principal = (con_principal == 'S' ? true : false);
			contatos[indice].tipo = con_tipo;
			contatos[indice].valor = (con_tipo == 'E' ? con_valor : con_valor.replace(/\D/gim, ''));


      // Adiciona os novos estados
      this.setState({
        con_status: 'A',
        con_principal: 'S',
        con_tipo: 'E',
        con_valor: '',
        clienteContatos: contatos,
        editandoContato: false,
        indiceEdicaoContato: null
      });
    //---
    return true;
  }

  // Cancela edição contato
  cancelaEdicaoContato = (event) => {
    this.setState({
      con_status: 'A',
      con_principal: 'S',
      con_tipo: 'E',
      con_valor: '',
      editandoContato: false,
      indiceEdicaoContato: null
    });
  }

  // Adiciona novo produto/serviço na listagem
  addProdutoServico = (event) => {
    // Variáveis
      const {
        cli_produtoServico,
        cli_sistemaID,
        clienteProdutoServico
      } = this.state;
      let errors = [];
    //---

    // Verifica se todas as variáveis do estado estão preenchidas
      if(cli_produtoServico == '') {
        errors.push(`O campo 'Produto / Serviço' deve ser preenchido.`);
      } 

      if(cli_sistemaID == '') {
        errors.push(`O campo 'Sistema ID' deve ser preenchido.`);
      } 

      if(cli_produtoServico != '') {
        clienteProdutoServico.forEach(produtoServico => {
          if(produtoServico.produto_servico_id == cli_produtoServico)
            errors.push(`Produto / Serviço já está adicionado a listagem.`);
        });
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---


    // Adiciona no array
      const selectProdutoServico = document.getElementById("cli_produtoServico");
      const produto_servico_descricao = selectProdutoServico.options[selectProdutoServico.selectedIndex].text;

      let produtoServicoNew = [ ...clienteProdutoServico, {
        id: '',
        produto_servico_descricao,
        produto_servico_id: cli_produtoServico,
        sistema_id: cli_sistemaID,
        exclui: false
      }];

  
      // Adiciona os novos estados
      this.setState({
        cli_produtoServico: '',
        cli_sistemaID: '',
        clienteProdutoServico: produtoServicoNew
      });
    //---
    return true;
  }

  // Exclui o produto/serviço
  excluiProdutoServico = (event) => {
    const { clienteProdutoServico } = this.state;

    const indice = event.target.getAttribute("data-indice");
    let produtoServico = [...clienteProdutoServico];
    const produtoServicoExclusao = produtoServico[indice];

    if(typeof produtoServicoExclusao != 'undefined') {
      if(confirm(`Deseja excluir o produto / serviço: \n ${produtoServicoExclusao.produto_servico_descricao}`)) {


        // Verificar se existe ID, apenas mudar status para false
        if(produtoServicoExclusao.id != '')
          produtoServico[indice].exclui = true;
        else
          produtoServico.splice(indice, 1);

        const produtoServicoNew = produtoServico.map(produtoServico => produtoServico);
        this.setState({clienteProdutoServico: produtoServicoNew})
      }
    }
  }

  // Edita o produto/serviço
  editaProdutoServico = (indice) => {
    const { clienteProdutoServico } = this.state;

    let produtoServico = [...clienteProdutoServico];
    const produtoServicoEdicao = produtoServico[indice];

    this.setState({
      cli_produtoServico: produtoServicoEdicao?.produto_servico_id || '',
      cli_sistemaID: produtoServicoEdicao?.sistema_id || '',
      editandoProdutoServico: true,
      indiceEdicaoProdutoServico: indice
    });
  }

  // Salva a edição do produto/serviço
  salvaEdicaoProdutoServico = (event) => {
    // Variáveis
      const {
        cli_produtoServico,
        cli_sistemaID,
        clienteProdutoServico,
        indiceEdicaoProdutoServico
      } = this.state;
      const indice = indiceEdicaoProdutoServico;
      let errors = [];
    //---


    // Verifica se todas as variáveis do estado estão preenchidas
      if(cli_produtoServico == '') {
        errors.push(`O campo 'Produto / Serviço' deve ser preenchido.`);
      } 

      if(cli_sistemaID == '') {
        errors.push(`O campo 'Sistema ID' deve ser preenchido.`);
      } 

      if(cli_produtoServico != '') {
        clienteProdutoServico.forEach((produtoServico, index) => {
          if(produtoServico.produto_servico_id == cli_produtoServico && indice != index)
            errors.push(`Produto / Serviço já está adicionado a listagem.`);
        });
      }

      if(errors.length > 0) {
        this.setState({msgBox: {color: 'danger', display: true, msg: errors }});
        return true;
      }
    //---

    // Edita o array de contatos
      let produtoServicos = [ ...clienteProdutoServico ];

      const selectProdutoServico = document.getElementById("cli_produtoServico");
      const produto_servico_descricao = selectProdutoServico.options[selectProdutoServico.selectedIndex].text;

    
      // Altera os dados do contato
      produtoServicos[indice].produto_servico_descricao = produto_servico_descricao;
      produtoServicos[indice].produto_servico_id = cli_produtoServico;
      produtoServicos[indice].sistema_id = cli_sistemaID;

      // Adiciona os novos estados
      this.setState({
        cli_produtoServico: '',
        cli_sistemaID: '',
        clienteProdutoServico: produtoServicos,
        editandoProdutoServico: false,
        indiceEdicaoProdutoServico: null
      });
    //---
    return true;
  }

  // Cancela edição produto/serviço
  cancelaEdicaoProdutoServico = (event) => {
    this.setState({
      cli_produtoServico: '',
      cli_sistemaID: '',
      editandoProdutoServico: false,
      indiceEdicaoProdutoServico: null
    });
  }
  
  // Faz o submit no form 
  handleSubmit = async (event) => {
    // Atualiza o estado dos select
    this.setState({
      cli_parceiroID: document.getElementById("cli_parceiroID").value,
      isLoading: true
    });

    // variáveis de retorno
    const { 
      clienteID,
      cli_nome,
      cli_cpfCnpj,
      cli_razaoSocial,
      cli_ativo,
      cli_parceiroID,
      clienteEnderecos,
      clienteContatos,
      clienteProdutoServico
    } = this.state;
    let errors = [];
    let validationsError = [];
    let responseCliente;
    let responseCreateEnderecos;
    let responseUpdateEnderecos;
    let responseCreateContatos;
    let responseUpdateContatos;
    let responseCreateProdutoServico;
    let responseUpdateProdutoServico;

    // validations
      if(!cli_nome) {
        validationsError.push('Nome é de preenchimento Obrigatório')
      }

      if(!cli_cpfCnpj) {
        validationsError.push('CPF/CNPJ é de preenchimento Obrigatório')
      } else {
        if(cli_cpfCnpj.replace(/\D/gim, '').length > 11) { 
          if(!cli_razaoSocial) {
            validationsError.push('Razão social é de preenchimento Obrigatório')
          }
        }
      }
    //---

    if(validationsError.length > 0) {
      this.setState({
        msgBox: {
          color: 'danger', 
          display: true, 
          msg: validationsError 
        },
        isLoading: false
      });
      event.preventDefault();
    } else {

      // Dados cliente 
      const clienteParams = {
        nome: cli_nome,
        cpf_cnpj: cli_cpfCnpj.replace(/\D/gim, ''),
        razao_social: cli_razaoSocial,
        ativo: (cli_ativo == 'A' ? true : false),
        parceiro_id: parseInt(cli_parceiroID),
        cliente_tipo_id: (cli_cpfCnpj.replace(/\D/gim, '').length <= 11 ? 1 : 2), // 1 - Pessoa física, 2 - Pessoa Jurídica
      };
      
      // Verifica se é create ou update do cliente
      if(clienteID != '')
        responseCliente = await updateClienteService(clienteID, clienteParams);
      else
        responseCliente = await createClienteService(clienteParams);

      // Verifica se ocorreu erro no cadastro do responseCliente
      if(responseCliente.errors.length == 0) {
        // Verifica os novos endereços/ verifica os endereços para atualizar
        let arrCreateEnderecos        = [];
        let arrUpdateEnderecos        = [];
        let arrCreateContatos         = [];
        let arrUpdateContatos         = [];
        let arrCreateProdutoServicos  = [];
        let arrUpdateProdutoServicos  = [];
        const clienteIDCurrent        = clienteID != '' ? clienteID : responseCliente.cliente.id; // Pega o ID do cliente recem cadastrado ou na atualização
        
        // ENDEREÇOS
          // Separa os endereços que serão incluidos dos que serão atualizados
            const enderecos = clienteEnderecos;
            enderecos.forEach(endereco => {
              if(typeof endereco.id == 'undefined')
                arrCreateEnderecos.push(endereco)
              else
                arrUpdateEnderecos.push(endereco)
            });
          //---

          // Cadastra os endereços
            responseCreateEnderecos = await createClienteEnderecoService(arrCreateEnderecos, clienteIDCurrent);
            if(responseCreateEnderecos.errors.length > 0) {
              responseCreateEnderecos.errors.forEach(msg => {
                errors.push(msg.message)
              });
            }
          //---

          // Atualiza os endereços
            // Cria função async para pegar os erros caso existir
            async function updateEndereco(endereco, enderecoID, clienteIDCurrent){
              responseUpdateEnderecos = await updateClienteEnderecoService(endereco, enderecoID, clienteIDCurrent);          
              return await responseUpdateEnderecos;
            }

            // Espera todas as atualizações ficarem prontas
            const updEndereco = await Promise.all(
              arrUpdateEnderecos.map(endereco => updateEndereco(endereco, endereco.id, clienteIDCurrent))
            );

            // Percorre a atualização dos contatos para verificar se tem errors
            updEndereco.forEach(enderecos => {
              if(enderecos.errors.length > 0) {
                enderecos.errors.forEach(msg => {
                  errors.push(msg.message)
                });
              }
            });
          //---
        //---

        // CONTATOS
          // Separa os contatos que serão incluidos dos que serão atualizados
            const contatos = clienteContatos;
            contatos.forEach(contato => {
              if(typeof contato.id == 'undefined')
                arrCreateContatos.push(contato)
              else
                arrUpdateContatos.push(contato)
            });
          //---

          // Cadastra os contatos
            responseCreateContatos = await createClienteContatoService(arrCreateContatos, clienteIDCurrent);
            if(responseCreateContatos.errors.length > 0) {
              responseCreateContatos.errors.forEach(msg => {
                errors.push(msg.message)
              });
            }
          //---

          // Atualiza os contatos
            // Cria função async para pegar os erros caso existir
            async function updateContato(contato, contatoID, clienteIDCurrent){
              responseUpdateContatos = await updateClienteContatoService(contato, contatoID, clienteIDCurrent);          
              return await responseUpdateContatos;
            }

            // Espera todas as atualizações ficarem prontas
            const updContatos = await Promise.all(
              arrUpdateContatos.map(contato => updateContato(contato, contato.id, clienteIDCurrent))
            );

            // Percorre a atualização dos contatos para verificar se tem errors
            updContatos.forEach(contatos => {
              if(contatos.errors.length > 0) {
                contatos.errors.forEach(msg => {
                  errors.push(msg.message)
                });
              }
            });
          //---
        //---

        // PRODUTO / SERVIÇO
          // Separa os produtos/serviço que serão incluidos dos que serão atualizados
            const produtosServicos = clienteProdutoServico;
            produtosServicos.forEach(produtoServico => {
              if(produtoServico.id == '')
                arrCreateProdutoServicos.push({...produtoServico, cliente_id: clienteIDCurrent});
              else
                arrUpdateProdutoServicos.push({...produtoServico, cliente_id: clienteIDCurrent});
            });
          //---


          // Cadastra cliente produto serviço
            // Cria função async para pegar os erros caso existir
            async function createProdutoServico(produtoServico){
              responseCreateProdutoServico = await createClienteProdutoServicoService(produtoServico);          
              return await responseCreateProdutoServico;
            }

            // Espera todas as atualizações ficarem prontas
            const crtProdutoServico = await Promise.all(
              arrCreateProdutoServicos.map(produtoServico => createProdutoServico(produtoServico))
            );

            // Percorre a atualização dos contatos para verificar se tem errors
            crtProdutoServico.forEach(produtoServico => {
              if(produtoServico.errors.length > 0) {
                produtoServico.errors.forEach(msg => {
                  errors.push(msg.message)
                });
              }
            });
          //---


          // Atualiza os produtos serviços ou exclui

            // Cria função async para pegar os erros caso existir
            async function updateDeleteProdutoServico(produtoServico){
              if(produtoServico.exclui)
                responseUpdateProdutoServico = await deleteClienteProdutoServicoService(produtoServico.id);          
              else
                responseUpdateProdutoServico = await updateClienteProdutoServicoService(produtoServico.id, produtoServico);          

              return await responseUpdateProdutoServico;
            }

            // Espera todas as atualizações ficarem prontas
            const updProdutoServico = await Promise.all(
              arrUpdateProdutoServicos.map(produtoServico => updateDeleteProdutoServico(produtoServico))
            );

            // Percorre a atualização dos contatos para verificar se tem errors
            updProdutoServico.forEach(produtoServico => {
              if(produtoServico.errors.length > 0) {
                produtoServico.errors.forEach(msg => {
                  errors.push(msg.message)
                });
              }
            });
          //---
        //---

      } else {
        responseCliente.errors.forEach(msg => {
          errors.push(msg.message)
        });
      }

      //Verifica se ocorreu algum erro
      if(errors.length > 0) {
        this.setState({
          msgBox: {
            color: 'danger', 
            display: true, 
            msg: errors 
          },
          isLoading: false
        });
        event.preventDefault();
      } else {
        const msgSuccess = clienteID != '' ? 'Cliente atualizado com sucesso' : 'Cliente cadastrado com sucesso';
        this.props.history.push({
          pathname: '/cliente',
          msgBox: { 
            color: 'success',
            display: true,
            msg: [msgSuccess]
          }
        });
      }
    }

  }

  render() {
    const { 
      activeTab,
      clienteID, 
      cli_nome,
      cli_cpfCnpj,
      cli_razaoSocial,
      cli_ativo,
      cli_parceiroID,
      cli_produtoServico,
      cli_sistemaID,
      con_status,
      con_principal,
      con_tipo,
      con_valor,
      end_cep,
      end_logradouro,
      end_numero,
      end_bairro,
      end_complemento,
      end_status,
      end_principal,
      editandoContato,
      editandoEndereco,
      editandoProdutoServico,
      isLoading, 
      msgBox,
      municipios,
      parceiros,
      produtoServicos,
      selectMunicipio
    } = this.state;

    // Esconde a mensagem
      const msgBoxDismiss = () => {
        this.setState({msgBox: {color: 'success', display: false, msg: []}})
      }
    //---

    return (
      <React.Fragment>
        <Loading isShow={isLoading} />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Cliente" breadcrumbItem={clienteID != '' ? 'Update' : 'Create'} />

            <Alert color={msgBox.color} isOpen={msgBox.display} toggle={msgBoxDismiss} fade={false}>
              <ul>
                { msgBox.msg.map( (msg, index) => <li key={index}>{msg}</li>) }
              </ul>
            </Alert>

            <Card>
              <CardBody>
                <CardTitle className="h4">{clienteID != '' ? 'Atualização do' : 'Cadastro de'} Cliente</CardTitle>
                <p className="card-title-desc">Tela utilizada para realizar {clienteID != '' ? 'a atualização' : 'o cadastro'}  do cliente</p>

                <AvForm className="needs-validation" onSubmit={this.handleSubmit}>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "clienteDadosPessoais",
                        })}
                        onClick={() => {
                          this.toggleIconActive("clienteDadosPessoais")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-user"></i> Dados Pessoais</span>
                        <span className="d-block d-sm-none"><i className="fas fa-user"></i></span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "clienteContatos",
                        })}
                        onClick={() => {
                          this.toggleIconActive("clienteContatos")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-phone"></i> Contatos</span>
                        <span className="d-block d-sm-none"><i className="fas fa-phone"></i></span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "clienteEnderecos",
                        })}
                        onClick={() => {
                          this.toggleIconActive("clienteEnderecos")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-map-marker"></i> Endereços</span>
                        <span className="d-block d-sm-none"><i className="fas fa-map-marker"></i></span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "clienteProdutoServico",
                        })}
                        onClick={() => {
                          this.toggleIconActive("clienteProdutoServico")
                        }}
                      >
                        <span className="d-none d-sm-block"><i className="fas fa-truck"></i> Produto / Serviço</span>
                        <span className="d-block d-sm-none"><i className="fas fa-truck"></i></span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="clienteDadosPessoais">
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_nome">Nome<span className="text-danger" >*</span></Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cli_nome"
                                  name="cli_nome"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  value={cli_nome}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_cpfCnpj">CPF/CNPJ<span className="text-danger" >*</span></Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cli_cpfCnpj"
                                  name="cli_cpfCnpj"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                  value={cli_cpfCnpj}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_razaoSocial">Razão social{cli_cpfCnpj.replace(/\D/gim, '').length > 11 ?<span className="text-danger" >*</span> : ''}</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cli_razaoSocial"
                                  name="cli_razaoSocial"
                                  onChange={this.handleChange}
                                  value={cli_razaoSocial}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row style={{marginTop: '15px'}}>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_ativo">Status<span className="text-danger" >*</span></Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="cli_ativo" 
                                  name="cli_ativo" 
                                  onChange={this.handleChange}
                                  value={cli_ativo}
                                >
                                  <option value="A">Ativo</option>
                                  <option value="I">Inativo</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_parceiroID">Parceiro<span className="text-danger" >*</span></Label>
                                <select 
                                  type="select"
                                  className="form-select" 
                                  name="cli_parceiroID" 
                                  id="cli_parceiroID" 
                                  onChange={this.handleChange}
                                  value={cli_parceiroID}
                                >
                                  {parceiros.map(parceiro => { 
                                    return(<option key={parceiro.id} value={parceiro.id}>{parceiro.descricao}</option>)
                                  })}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="clienteContatos">
                      <Row>
                        <Col sm="12">

                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormGroup>
                                <Label htmlFor="con_status">Status</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="con_status" 
                                  name="con_status" 
                                  onChange={this.handleChange}
                                  value={con_status}
                                >
                                  <option value="A">Ativo</option>
                                  <option value="I">Inativo</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormGroup>
                                <Label htmlFor="con_principal">Principal</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="con_principal" 
                                  name="con_principal" 
                                  onChange={this.handleChange}
                                  value={con_principal}
                                >
                                  <option value="S">Sim</option>
                                  <option value="N">Não</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row style={{marginTop: '15px'}}>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="con_tipo">Tipo</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="con_tipo" 
                                  name="con_tipo" 
                                  onChange={this.toggleSelectTipo}
                                  value={con_tipo}
                                >
                                  <option value="E">Email</option>
                                  <option value="T">Telefone</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="con_valor">Valor</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="con_valor"
                                  name="con_valor"
                                  onChange={this.handleChange}
                                  value={con_valor}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Link 
                                to={`#/`} 
                                className="btn btn-primary" 
                                style={{marginTop: "27px", display: (editandoContato ? 'none' : '')}} 
                                onClick={this.addContato}
                              >
                                Adicionar contato
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-success" 
                                style={{marginTop: "27px", display: (editandoContato ? '' : 'none')}} 
                                onClick={this.salvaEdicaoContato}
                              >
                                Salvar edição
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-secondary" 
                                style={{marginTop: "27px", marginLeft: "10px", display: (editandoContato ? '' : 'none')}} 
                                onClick={this.cancelaEdicaoContato}
                              >
                                Cancelar edição
                              </Link>
                            </Col>
                          </Row>

                          <Row style={{marginTop: "15px"}}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Card>
                                <CardBody>
                                  <h4 className="card-title">Listagem de contatos </h4>
                                  <div className="table-responsive">
                                    <Table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th>Ativo</th>
                                          <th>Principal</th>
                                          <th>Tipo</th>
                                          <th>Valor</th>
                                          <th>Ações</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          (this.state.clienteContatos.length > 0 ?
                                            this.state.clienteContatos.map((contato, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <span className={`font-size-12 badge rounded-pill ${contato.ativo ? 'bg-success': 'bg-danger'}`}>
                                                      {contato.ativo ? 'SIM': 'NÃO'}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className={`font-size-12 badge rounded-pill ${contato.principal ? 'bg-success': 'bg-danger'}`}>
                                                      {contato.principal ? 'SIM': 'NÃO'}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {contato.tipo == 'E' ? 'EMAIL': 'TELEFONE'}
                                                  </td>
                                                  <td>
                                                    {contato.tipo == 'E' ? contato.valor : formatPhone(contato.valor)}
                                                  </td>
                                                  <td>
                                                    <Link 
                                                      to={'#/'} 
                                                      style={{fontSize: "20px"}}
                                                      onClick={() => this.editaContato(index)}
                                                    >
                                                      <i className="bx bx-edit" data-indice={index}/>
                                                    </Link>

                                                    <Link 
                                                      to={'#/'} 
                                                      style={{fontSize: "20px", color: "rgba(244, 106, 106)", marginLeft: "10px"}} 
                                                      onClick={this.excluiContato}
                                                    >
                                                      <i className="bx bx-trash" data-indice={index}/>
                                                    </Link>
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          :
                                            <tr style={{textAlign: "center"}}>
                                              <td colSpan={5}>Nenhum contato!</td>
                                            </tr>
                                          )
                                        }
                                      </tbody>
                                    </Table>
                                  </div>

                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="clienteEnderecos">
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                              <FormGroup>
                                <Label htmlFor="end_cep">Cep</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="end_cep"
                                  name="end_cep"
                                  onChange={this.handleChange}
                                  onBlur={this.searchCep}
                                  value={end_cep}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormGroup>
                                <Label htmlFor="end_logradouro">Logradouro</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="end_logradouro"
                                  name="end_logradouro"
                                  onChange={this.handleChange}
                                  value={end_logradouro}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                              <FormGroup>
                                <Label htmlFor="end_numero">Número</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="end_numero"
                                  name="end_numero"
                                  onChange={this.handleChange}
                                  value={end_numero}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="end_bairro">Bairro</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="end_bairro"
                                  name="end_bairro"
                                  onChange={this.handleChange}
                                  value={end_bairro}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="end_complemento">Complemento</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="end_complemento"
                                  name="end_complemento"
                                  onChange={this.handleChange}
                                  value={end_complemento}
                                />
                              </FormGroup>
                            </Col>
                          
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <div className="mb-3 select2-container">
                                <Label>Município</Label>
                                <Select
                                  placeholder="Mínimo 3 caracteres para consulta"
                                  classNamePrefix="select2-selection"
                                  onInputChange={this.handleSelectMunicipio}
                                  onChange={this.handleChangeMunicipio}
                                  options={municipios}
                                  value={selectMunicipio}
                                />
                              </div>
                            </Col>
                          </Row>
                         
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="end_status">Status</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="end_status" 
                                  name="end_status" 
                                  onChange={this.handleChange}
                                  value={end_status}
                                >
                                  <option value="A">Ativo</option>
                                  <option value="I">Inativo</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="end_principal">Principal</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="end_principal" 
                                  name="end_principal" 
                                  onChange={this.handleChange}
                                  value={end_principal}
                                >
                                  <option value="S">Sim</option>
                                  <option value="N">Não</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Link 
                                to={`#/`} 
                                className="btn btn-primary" 
                                style={{marginTop: "27px", display: (editandoEndereco ? 'none' : '')}} 
                                onClick={this.addEndereco}
                              >
                                Adicionar endereço
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-success" 
                                style={{marginTop: "27px", display: (editandoEndereco ? '' : 'none')}} 
                                onClick={this.salvaEdicaoEndereco}
                              >
                                Salvar edição
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-secondary" 
                                style={{marginTop: "27px", marginLeft: "10px", display: (editandoEndereco ? '' : 'none')}} 
                                onClick={this.cancelaEdicaoEndereco}
                              >
                                Cancelar edição
                              </Link>
                            </Col>
                          </Row>

                          <Row style={{marginTop: "15px"}}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Card>
                                <CardBody>
                                  <h4 className="card-title">Listagem de endereços </h4>
                                  <div className="table-responsive">
                                    <Table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th>Ativo</th>
                                          <th>Principal</th>
                                          <th>Endereço</th>
                                          <th>Ações</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          (this.state.clienteEnderecos.length > 0 ?
                                            this.state.clienteEnderecos.map((endereco, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <span className={`font-size-12 badge rounded-pill ${endereco.ativo ? 'bg-success': 'bg-danger'}`}>
                                                      {endereco.ativo ? 'SIM': 'NÃO'}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className={`font-size-12 badge rounded-pill ${endereco.principal ? 'bg-success': 'bg-danger'}`}>
                                                      {endereco.principal ? 'SIM': 'NÃO'}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {`${formatZipCode(endereco.cep)} - ${endereco.logradouro}, nº ${endereco.numero} - ${endereco.bairro} - ${endereco.municipio.municipio}`}
                                                  </td>
                                                  <td>
                                                    <Link 
                                                      to={'#/'} 
                                                      style={{fontSize: "20px"}}
                                                      onClick={() => this.editaEndereco(index)}
                                                    >
                                                      <i className="bx bx-edit" data-indice={index}/>
                                                    </Link>

                                                    <Link 
                                                      to={'#/'} 
                                                      style={{fontSize: "20px", color: "rgba(244, 106, 106)", marginLeft: "10px"}} 
                                                      onClick={this.excluiEndereco}
                                                    >
                                                      <i className="bx bx-trash" data-indice={index}/>
                                                    </Link>
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          :
                                            <tr style={{textAlign: "center"}}>
                                              <td colSpan={4}>Nenhum endereço!</td>
                                            </tr>
                                          )
                                        }
                                      </tbody>
                                    </Table>
                                  </div>

                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="clienteProdutoServico">
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_produtoServico">Produto / Serviço</Label>
                                <select
                                  type="select"
                                  className="form-select" 
                                  id="cli_produtoServico" 
                                  name="cli_produtoServico" 
                                  onChange={this.handleChange}
                                  value={cli_produtoServico}
                                >
                                  <option value="">--- Selecione ---</option>
                                  {produtoServicos.map(produtoServico => {
                                    return <option value={produtoServico.id} key={produtoServico.id}>{produtoServico.nome}</option>
                                  })}
                                </select>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <FormGroup>
                                <Label htmlFor="cli_sistemaID">Sistema ID</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cli_sistemaID"
                                  name="cli_sistemaID"
                                  onChange={this.handleChange}
                                  value={cli_sistemaID}
                                />
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              <Link 
                                to={`#/`} 
                                className="btn btn-primary" 
                                style={{marginTop: "27px", display: (editandoProdutoServico ? 'none' : '')}} 
                                onClick={this.addProdutoServico}
                              >
                                Adicionar produto/serviço
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-success" 
                                style={{marginTop: "27px", display: (editandoProdutoServico ? '' : 'none')}} 
                                onClick={this.salvaEdicaoProdutoServico}
                              >
                                Salvar edição
                              </Link>
                              <Link 
                                to={`#/`} 
                                className="btn btn-secondary" 
                                style={{marginTop: "27px", marginLeft: "10px", display: (editandoProdutoServico ? '' : 'none')}} 
                                onClick={this.cancelaEdicaoProdutoServico}
                              >
                                Cancelar edição
                              </Link>
                            </Col>
                          </Row>

                          <Row style={{marginTop: "15px"}}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Card>
                                <CardBody>
                                  <h4 className="card-title">Listagem de produto / serviço </h4>
                                  <div className="table-responsive">
                                    <Table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Produto / Serviço</th>
                                          <th style={{textAlign: "center"}} >Sistema ID</th>
                                          <th>Ações</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          (this.state.clienteProdutoServico.length > 0 ?
                                            this.state.clienteProdutoServico.map((produtoServico, index) => {
                                              if (!produtoServico.exclui) {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      {produtoServico.produto_servico_id} 
                                                    </td>
                                                    <td>
                                                      {produtoServico.produto_servico_descricao} 
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                      {produtoServico.sistema_id} 
                                                    </td>
                                                    <td>
                                                      <Link 
                                                        to={'#/'} 
                                                        style={{fontSize: "20px"}}
                                                        onClick={() => this.editaProdutoServico(index)}
                                                      >
                                                        <i className="bx bx-edit" data-indice={index}/>
                                                      </Link>

                                                      <Link 
                                                        to={'#/'} 
                                                        style={{fontSize: "20px", color: "rgba(244, 106, 106)", marginLeft: "10px"}} 
                                                        onClick={this.excluiProdutoServico}
                                                      >
                                                        <i className="bx bx-trash" data-indice={index}/>
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            })
                                          :
                                            <tr style={{textAlign: "center"}}>
                                              <td colSpan={4}>Nenhum produto / serviço!</td>
                                            </tr>
                                          )
                                        }
                                      </tbody>
                                    </Table>
                                  </div>

                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                        </Row>
                    </TabPane>
                  </TabContent>
                  <Row>                    
                    <Col md={12} style={{marginTop: "15px", paddingTop: "10px", textAlign: "center", borderTop: "1px solid #e3e3e3"}}>
                      <ButtonPattern 
                        type="submit" 
                        style={{ marginRight: "10px" }}
                        className={'btn btn-success'}
                        action={clienteID == '' ? 'cadastra' : 'atualiza'}
                      >
                        Salvar
                      </ButtonPattern>
                      <LinkPattern 
                        to={`/cliente`} 
                        className="btn btn-secondary"
                        action="cancela"
                      >
                        Cancelar
                      </LinkPattern>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ClienteCreate;