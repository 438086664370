import accessToken from '../helpers/JWT/accessToken';
const _token = accessToken();

// Função Responsável por cadastrar os endereços
const createClienteEnderecoService = async (enderecos, clienteID) => {
  const _token = accessToken();

  // O formato do argumento passado (endereços deve ser) um array de objetos
  /**
   * Ex:
   * 	"enderecos": [
        {
          "ativo": true,
          "principal": true,
          "cep": "17500020",
          "logradouro": "Avenida Sampaio Vidal",
          "numero": "329 C",
          "bairro": "Centro",
          "complemento": null,
          "municipio_id": 6681
        },
        {
          "ativo": true,
          "principal": false,
          "cep": "17500020",
          "logradouro": "Avenida Sampaio Vidal",
          "numero": "329 D",
          "bairro": "Centro",
          "complemento": null,
          "municipio_id": 6681
        }
      ]
   */

  // Faz o cadastro do cliente Endereco
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}/enderecos`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify({enderecos}),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

// Função Responsável por atualiza o endereço do cliente
const updateClienteEnderecoService = async (endereco, enderecoID ,clienteID) => {
  const _token = accessToken();

  // O formato do argumento passado (endereço deve ser) um objeto
  /**
   * Ex:
   * 	{
        "ativo": true,
        "principal": true,
        "cep": "17500020",
        "logradouro": "Avenida Sampaio Vidal",
        "numero": "329 C",
        "bairro": "Centro",
        "complemento": null,
        "municipio_id": 6681
      }
   */

  // Faz o cadastro do cliente Endereco
    return fetch(`${process.env.REACT_APP_URL_BASE_API}/cliente/${clienteID}/enderecos/${enderecoID}`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+_token,
      }),
      body: JSON.stringify(endereco),
    })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      return err
    })
  //---
}

export {
  createClienteEnderecoService,
  updateClienteEnderecoService
}